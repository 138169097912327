import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ValidateTokenService from '../../services/ValidateTokenService';
import Tabs from '../../components/Tabs';
import DomainDashboard from '../DomainDashboard';
import MerchantsDashboard from '../MerchantsDashboard';
import UsersDashboard from '../UsersDashboard';
import TerminalsDashboard from '../TerminalsDashboard';
import {
  PERMISSION_VIEW_DOMAINS as VIEW_DOMAINS,
  PERMISSION_VIEW_MERCHANT_IDS as VIEW_MERCHANT_IDS,
  PERMISSION_VIEW_TERMINALS as VIEW_TERMINALS,
} from '../App/constants';

const TABS = {
  DOMAINS: 'Domains',
  MERCHANT_IDS: 'Merchant IDs',
  // USERS: 'Users',
  TERMINALS: 'Terminals',
};

const LIST_OF_TABS_IN_ORDER = [
  TABS.DOMAINS,
  TABS.MERCHANT_IDS,
  // TABS.USERS,
  TABS.TERMINALS,
];

class Admin extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const validateTokenService = new ValidateTokenService();
    if (!validateTokenService.refreshTokenIsValid()) {
      history.push('/login');
      this.state = {};
      return null;
    }
    const { tabsToShow, defaultTab } = this.getTabsToShow();
    this.state = {
      view: defaultTab,
      tabsToShow,
    };
  }

  getTabsToShow() {
    const permissions = window.localStorage.getItem('permissions');
    const shouldShowTab = {};
    LIST_OF_TABS_IN_ORDER.forEach(tab => shouldShowTab[tab] = true); /* eslint-disable-line no-return-assign */ // Intentional assignemnt
    shouldShowTab[TABS.DOMAINS] = permissions.includes(VIEW_DOMAINS);
    shouldShowTab[TABS.MERCHANT_IDS] = permissions.includes(VIEW_MERCHANT_IDS);
    shouldShowTab[TABS.TERMINALS] = permissions.includes(VIEW_TERMINALS);

    const viewDefs = [];
    LIST_OF_TABS_IN_ORDER.forEach(tab => shouldShowTab[tab] && viewDefs.push({ key: tab }));
    viewDefs[0].isDefault = true;

    return { tabsToShow: viewDefs, defaultTab: viewDefs[0].key };
  }

  changeTab = viewItem => this.setState({ view: viewItem.key });

  showCurrentView = () => {
    const { view } = this.state;
    switch (view) {
      case TABS.DOMAINS:
        return <DomainDashboard />;
      case TABS.MERCHANT_IDS:
        return <MerchantsDashboard />;
      case TABS.USERS:
        return <UsersDashboard />;
      case TABS.TERMINALS:
        return <TerminalsDashboard />;
      default:
        return null;
    }
  }

  render() {
    const { tabsToShow } = this.state;
    if (!tabsToShow) { return null; }
    return (
      <div>
        <Tabs
          items={tabsToShow}
          itemDef={{ isButton: true }}
          onChange={this.changeTab}
        />
        {this.showCurrentView()}
      </div>
    );
  }
}

Admin.propTypes = {
  history: PropTypes.any,
};

export default withRouter(Admin);
