import React, { Component } from 'react';
import faker from 'faker';
import { Table, Form, Input, Button } from 'semantic-ui-react';
import ModalDialog from '../../components/Modal';
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';

const mockData = [
  { transactionDate: faker.date.past().toLocaleDateString(), clearingDate: faker.date.past().toLocaleDateString(), acquirerMid: faker.random.number(), acquirerTid: faker.random.number(), machineRef: faker.random.words(), status: 'cleared', cleared: `$${faker.finance.amount()}`, card: faker.random.number(), type: 'visa' },
  { transactionDate: faker.date.past().toLocaleDateString(), clearingDate: faker.date.past().toLocaleDateString(), acquirerMid: faker.random.number(), acquirerTid: faker.random.number(), machineRef: faker.random.words(), status: 'cleared', cleared: `$${faker.finance.amount()}`, card: faker.random.number(), type: 'visa' },
  { transactionDate: faker.date.past().toLocaleDateString(), clearingDate: faker.date.past().toLocaleDateString(), acquirerMid: faker.random.number(), acquirerTid: faker.random.number(), machineRef: faker.random.words(), status: 'cleared', cleared: `$${faker.finance.amount()}`, card: faker.random.number(), type: 'visa' },
  { transactionDate: faker.date.past().toLocaleDateString(), clearingDate: faker.date.past().toLocaleDateString(), acquirerMid: faker.random.number(), acquirerTid: faker.random.number(), machineRef: faker.random.words(), status: 'cleared', cleared: `$${faker.finance.amount()}`, card: faker.random.number(), type: 'visa' },
  { transactionDate: faker.date.past().toLocaleDateString(), clearingDate: faker.date.past().toLocaleDateString(), acquirerMid: faker.random.number(), acquirerTid: faker.random.number(), machineRef: faker.random.words(), status: 'cleared', cleared: `$${faker.finance.amount()}`, card: faker.random.number(), type: 'visa' },
  { transactionDate: faker.date.past().toLocaleDateString(), clearingDate: faker.date.past().toLocaleDateString(), acquirerMid: faker.random.number(), acquirerTid: faker.random.number(), machineRef: faker.random.words(), status: 'cleared', cleared: `$${faker.finance.amount()}`, card: faker.random.number(), type: 'visa' },
];

class ClearingDashboard extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
  }

  openModal = (data) => {
    ModalDialog.show({
      title: `Details for transaction id ${data.acquirerMid}`,
      closeOnClick: false,
      content: Object.keys(data).map(item => (
        <div key={item} className="item">
          {item}
          <span>{data[item]}</span>
        </div>
      )),
    });
  }

  render() {
    return (
      <DashboardWrapper>
        <ModalDialog />
        <Sidebar>
          <Form className="block">
            <Form.Field>
              <label>Clearing Date</label>
              <Input type="date" placeholder="clearing date" icon="calendar alternate outline" />
              <Input type="date" placeholder="clearing date" icon="calendar alternate outline" />
            </Form.Field>
            <Form.Field>
              <label>Acquirer</label>
              <Input placeholder="Acquirer" />
            </Form.Field>
            <Form.Field>
              <label>Region</label>
              <Input placeholder="Region" />
            </Form.Field>
            <Form.Field>
              <label>Acquirer TID</label>
              <Input placeholder="Acquirer TID" />
            </Form.Field>
            <Form.Field>
              <label>Machine Reference</label>
              <Input placeholder="Machine Reference" />
            </Form.Field>
            <Form.Field>
              <label>Cleared Amount</label>
              <Input placeholder="Cleared Amount" />
            </Form.Field>
            <Form.Field>
              <label>Card Number</label>
              <Input placeholder="Card Number" />
            </Form.Field>
            <Form.Field>
              <label>Card Type</label>
              <Input placeholder="Card Type" />
            </Form.Field>
            <Form.Field>
              <label>Status</label>
              <Input placeholder="Status" />
            </Form.Field>
            <Form.Field>
              <label>Transaction Id</label>
              <Input placeholder="Transaction id" />
            </Form.Field>
          </Form>
          <div className="actions">
            <Button.Group>
              <Button>Export</Button>
              <Button.Or />
              <Button positive>Refresh</Button>
            </Button.Group>
          </div>
        </Sidebar>
        <Table striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Transaction Date</Table.HeaderCell>
              <Table.HeaderCell>Clearing Date</Table.HeaderCell>
              <Table.HeaderCell>Acquirer Mid</Table.HeaderCell>
              <Table.HeaderCell>Acquired Tid</Table.HeaderCell>
              <Table.HeaderCell>Machine Reference</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Cleared</Table.HeaderCell>
              <Table.HeaderCell>Card</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {mockData.map((item, index) => (
              <Table.Row data={`${index}`} key={`${item.acquirerMid}`} onClick={() => this.openModal(item)}>
                {Object.keys(item).map(i => (
                  <React.Fragment key={item[i]}>
                    {typeof (item[i]) !== 'object' ? <Table.Cell key={item[i]}>{item[i]}</Table.Cell> : <Table.Cell key={item[i]}>{item[i].join(', ')}</Table.Cell>}
                  </React.Fragment>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </DashboardWrapper>
    );
  }
}

export default ClearingDashboard;
