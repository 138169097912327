import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode'; //eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Form, Button } from 'semantic-ui-react';
import { createApolloFetch } from 'apollo-fetch';
import ReactPasswordStrength from 'react-password-strength';
import { Wrapper } from './styles';
import { BASE_API_URL } from '../App/constants';
import FeedbackMessage from '../../components/FeedbackMessage';

const MIN_PWD_SCORE = 2;
const REDIRECT_DELAY = 2000; // the delay in milliseconds before the user is being redirected to dashboard after resetting his password
const RESET_PASSWORD_PATH = '/reset-password';

const request = new createApolloFetch({ //eslint-disable-line
  uri: `${BASE_API_URL}/reset-forgotten-password`,
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: 0,
      password: '',
      confirmPassword: '',
      errorMessage: '',
      weakPassword: '',
      message: '',
      token: null,
      isLoading: false,
      resetSuccess: false,
      status: false,
    };
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount = () => {
    const { history } = this.props;
    const tokenParam = new URLSearchParams(window.location.search);
    const token = tokenParam.get('token');
    try {
      jwtDecode(token);
      this.setState({ token });
      fetch(`${BASE_API_URL}/verify-reset-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (res.status === 401) {
          this.setState({ message: 'Invalid url' });
          setTimeout(() => {
            history.push('/forgot');
          }, 3500);
        }
      });
    } catch {
      this.setState({ message: 'Invalid url' });
      setTimeout(() => {
        history.push('/forgot');
      }, 3500);
    }
  }

  checkConfirmPassword = (data) => {
    const { password, score } = this.state;
    this.setState({
      confirmPassword: data.target.value,
      errorMessage: data.target.value !== password ? 'Passwords does not match' : '',
      weakPassword: score < MIN_PWD_SCORE ? 'Password too weak' : '',
    });
  };

  submitHandler = () => {
    this.setState({ message: '', errorMessage: '' });
    const { history } = this.props; //eslint-disable-line
    const { password, token } = this.state;
    if (!token) this.setState({ message: 'missing or expired token' });

    this.setState({ isLoading: true });
    request.use(({ request, options }, next) => { //eslint-disable-line
      if (!options.headers) {
        options.headers = {}; //eslint-disable-line
      }
      options.headers['Content-Type'] = 'application/json'; //eslint-disable-line
      options.headers['Authorization'] = `Bearer ${token}`; //eslint-disable-line
      next();
    });
    request({
      password,
    }).then((res) => {
      this.setState({ isLoading: false });
      if (res.status === 'success') {
        this.setState({ message: res.message, status: true });
        this.setState({ resetSuccess: true });
        setTimeout(() => {
          const location = history.location.pathname;
          if (location === RESET_PASSWORD_PATH) {
            history.push('/');
          }
        }, REDIRECT_DELAY);
      } else if (res.error) {
        this.setState({ message: res.message, status: false });
      }
    }).catch((err) => {
      console.error('err', err);
      this.setState({ message: err, status: false });
    });
  }

  getScoreWords = () => {
    const defaultScoreWords = ['weak', 'okay', 'good', 'strong', 'very strong'];

    return [...Array(5)].map((_, idx) => {
      if (idx < MIN_PWD_SCORE) {
        return 'too weak';
      }
      return defaultScoreWords[idx];
    });
  }

  render() {
    const { status, score, password, confirmPassword, errorMessage, weakPassword, message, resetSuccess, isLoading } = this.state;
    return (
      <Wrapper>
        <Grid textAlign="center" style={{ width: 350 }} verticalAlign="middle" className="app">
          <Grid.Column style={{ width: 350 }}>
            <img className="logo" src="/assets/till-logo-black.png" alt="Till Payments Logo" />
            <Form className="login" onSubmit={this.submitHandler} size="large">
              {message && <FeedbackMessage message={message} status={status} /> }
              <ReactPasswordStrength
                minLength={8}
                minScore={MIN_PWD_SCORE}
                style={{ marginTop: '15px' }}
                scoreWords={this.getScoreWords()}
                changeCallback={e => this.setState({ score: e.score, password: e.password })}
                inputProps={{ name: 'password_input', autoComplete: 'off', placeholder: 'Type your new password' }}
              />
              <Form.Input
                type="password"
                style={{ height: '47px', fontSize: '18px', marginTop: '12px' }}
                placeholder="Confirm your password"
                onChange={e => this.checkConfirmPassword(e)}
              />
              <small style={{ color: 'red' }}>
                {errorMessage}
              </small>
              <br />
              {!resetSuccess && (
                <React.Fragment>
                  <small style={{ color: 'red' }}>{weakPassword}</small>
                  {isLoading ? (
                    <Button style={{ color: 'white', background: '#f9581f' }} fluid size="large" loading>Please wait</Button>
                  ) : (
                    <Button
                      disabled={score < MIN_PWD_SCORE || password !== confirmPassword}
                      style={{ color: 'white', background: '#f9581f' }}
                      fluid
                      size="large"
                      type="submit"
                    >
                      Reset
                    </Button>
                  ) }
                </React.Fragment>
              )}
              <Link to="/" className="link-form">
                &lt; Login
              </Link>
            </Form>
          </Grid.Column>
        </Grid>
      </Wrapper>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.any,
};

export default withRouter(connect()(ResetPassword));
