import React, { Component } from 'react';
import _ from 'lodash';
import { Table, Form, Input, Button, Tab, Modal, Pagination, Message, Loader } from 'semantic-ui-react';
import { getDomainsGraphqlQuery } from './getDomainsGraphqlQuery';
import { ADD_DOMAIN_MUTATION, UPDATE_DOMAIN_MUTATION } from './DomainMutations';
import AddDomainModalDomainTab from './AddDomainModalDomainTab';
import ManageMerchantTable from '../../components/Merchants/ManageMerchantTable';
// import ManageUsersTable from '../../components/Users/ManageUsersTable';
import UpdateDomainModalDomainTab from './UpdateDomainModalDomainTab';
import APIService2 from '../../services/APIService2';
import { TableWrapper } from '../../components/TableWrapper';
import { MainContentWrapper } from '../../components/MainContentWrapper';
import DashboardTitle from '../../components/DashboardTitle';
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';

// Import constants
import {
  DEFAULT_NR_OF_TABLE_ROWS,
  GRAPHQL,
  PERMISSION_MANAGE_DOMAINS as MANAGE_DOMAINS,
  PERMISSION_VIEW_MERCHANT_IDS,
} from '../App/constants';

const uri = GRAPHQL;
const apiService = new APIService2();
let newDomain = {};
export default class DomainDashboard extends Component {
  constructor(props) {
    super(props);
    this.openUpdateModal = this.openUpdateModal.bind(this);
    this.newDomainModal = this.newDomainModal.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.fetchDomains = this.fetchDomains.bind(this);
    this.displayDomains = this.displayDomains.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      addDomainModal: false,
      updateDomainModal: false,
      // updatedDomainName: '',
      domains: [],
      domain: '',
      organisationName: '',
      assignableMerchants: '',
      status: '',
      creationDate: '',
      createdBy: '',
      updateDomainData: {},
      successMessage: '',
      showTabs: false,
      currentPage: 1,
      selectedDomain: {},
      loading: false,
      loadingDomainList: true,
      loadingUpdateDomainModalDomainTab: false,
    };
    this.permissions = window.localStorage.getItem('permissions');
  }

  componentDidMount() {
    this.fetchDomains();
  }

  fetchDomains = async () => {
    this.setState({ loadingDomainList: true, currentPage: 1 });
    const { history } = this.props;
    const { domain, organisationName, assignableMerchants, status, creationDate, createdBy } = this.state;
    const paging = { first: 1000 };
    const sorting = {
      sortOrder: 'ASCENDING',
      sortField: 'DOMAIN',
    };
    const filtering = {
      domain,
      organisationName,
      assignableMerchants,
      status,
      creationDate,
      createdBy,
    };
    const query = getDomainsGraphqlQuery(paging, sorting, filtering);
    try {
      const result = await apiService.request({ payload: { query }, history, window, uri });
      const domainsEdges = (result && result.data && result.data.domains && result.data.domains.edges) || [];
      let domainListChunked = [];
      domainListChunked = _.chunk(domainsEdges, DEFAULT_NR_OF_TABLE_ROWS);
      this.setState({ domains: domainListChunked, loadingDomainList: false });
    } catch (err) {
      this.setState({ domains: [], loadingDomainList: false });
    }
  }

  handlePaginationChange = (event) => {
    const value = event.target.getAttribute('value');
    this.setState({ currentPage: Number(value) });
  }

  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  modalClose = () => {
    this.setState({ addDomainModal: false, updateDomainModal: false, updateDomainData: {}, successMessage: '', errorMessage: '', loading: false });
  }

  submitNewDomain = async (newDomainData) => {
    this.setState({ loading: true, successMessage: '', errorMessage: '' });
    try {
      const { history } = this.props;
      const { country, domainName, organisationName, postcode, state, street, town } = newDomainData;
      newDomain = {
        domain: domainName,
        organisationName,
        address: {
          address1: street,
          address2: '',
          locality: town,
          postcode,
          state,
          country,
        },
        assignableActions: [],
        assignableMerchants: [],
        status: 'active',
        creationDate: new Date(),
        createdBy: `${window.localStorage.getItem('username')}@${window.localStorage.getItem('domain')}`,
      };
      const mutation = ADD_DOMAIN_MUTATION;
      const params = { newDomain };
      const result = await apiService.request({ payload: { mutation, params }, history, window, uri });
      const domainData = (result && result.data && result.data.addDomain && result.data.addDomain) || {};
      this.setState({
        showTabs: true,
        updateDomainData: domainData,
        successMessage: 'You created a domain successfully. You should now be able to add merchants and users to this domain.',
      });
      this.fetchDomains();
    } catch (err) {
      this.setState({ errorMessage: err.message });
    }
    this.setState({ loading: false });
  }

  newDomainModal = () => {
    const { updateDomainData, addDomainModal, successMessage, errorMessage } = this.state;
    return (
      <Modal size="large" open={addDomainModal} onClose={this.modalClose}>
        <Modal.Header>{updateDomainData.domain ? `Update Domain - ${updateDomainData.domain}` : 'Add Domain'}</Modal.Header>
        <Modal.Content>
          {successMessage && (
            <Message
              icon="checkmark"
              header="Good to go!"
              content={successMessage}
              positive
            />
          )}
          {errorMessage && (
            <Message
              content={errorMessage}
              error
            />
          )}
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={this.newDomainPanes()}
            onTabChange={() => this.setState({ successMessage: '', errorMessage: '' })}
          />
        </Modal.Content>
      </Modal>
    );
  }

  newDomainPanes = () => {
    const {
      showTabs,
      loading,
    } = this.state;
    return (
      [
        {
          menuItem: 'Domain',
          render: () => <AddDomainModalDomainTab loading={loading} onCancel={() => this.modalClose()} onSubmit={this.submitNewDomain} />,
        },
        {
          menuItem: showTabs ? 'Merchant IDs' : null,
          render: () => (
            <Tab.Pane style={{ float: 'left', width: '100%', marginBottom: 20 }}>
              <ManageMerchantTable DomainData={newDomain} onCancel={() => this.modalClose()} />
            </Tab.Pane>
          ),
        },
        // {
        //   menuItem: showTabs ? 'Users' : null,
        //   render: () => (
        //     <Tab.Pane attached={false}>
        //       <ManageUsersTable />
        //     </Tab.Pane>
        //   ),
        // },
      ]
    );
  }

  openUpdateModal = (data) => {
    this.setState({
      updateDomainModal: true,
      updateDomainData: data,
      // updatedDomainName: data.domain,
      selectedDomain: data,
    });
  }

  submitUpdateDomain = async (data) => {
    this.setState({ errorMessage: '', loadingUpdateDomainModalDomainTab: true });
    try {
      const { history } = this.props;
      const { city, country, domainName, postcode, state, status, street, organisationName } = data;
      const updateDomain = {
        domain: domainName,
        organisationName,
        status,
        address: {
          address1: street,
          address2: '',
          locality: city,
          postcode,
          state,
          country,
        },
      };
      const mutation = UPDATE_DOMAIN_MUTATION();
      const params = { updateDomain };
      await apiService.request({ payload: { mutation, params }, history, window, uri });
      this.modalClose();
      this.fetchDomains();
    } catch (err) {
      this.setState({ errorMessage: err.message });
    }
    this.setState({ loadingUpdateDomainModalDomainTab: false });
  }

  updateDomainModal = () => {
    const { updateDomainData, updateDomainModal, errorMessage } = this.state;
    return (
      <Modal size="large" onClose={() => this.modalClose()} open={updateDomainModal}>
        <Modal.Header> {/* eslint-disable-line react/jsx-one-expression-per-line */ /* Bug: https://github.com/yannickcr/eslint-plugin-react/issues/2152 */}
          {`Update Domain - ${updateDomainData.domain}` /* eslint-disable-line react/jsx-one-expression-per-line */}
          {/* <Popup
            trigger={<Button style={{ float: 'right' }} size="mini">Remove Domain</Button>}
            content={<Button onClick={() => this.submitRemoveDomain(updatedDomainName)} content={`Are you sure you want to remove the domain ${updatedDomainName} ?`} color="red" />}
            on="click"
            position="left center"
          /> */}
        </Modal.Header>
        <Modal.Content>
          {errorMessage && (
            <Message
              content={errorMessage}
              error
            />
          )}
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={this.updateDomainPanes()}
          />
        </Modal.Content>
      </Modal>
    );
  }

  updateDomainPanes = () => {
    const { updateDomainData, selectedDomain, loadingUpdateDomainModalDomainTab } = this.state;
    return (
      [
        {
          menuItem: 'Domain',
          render: () => <UpdateDomainModalDomainTab loading={loadingUpdateDomainModalDomainTab} onSubmit={this.submitUpdateDomain} domainData={updateDomainData} onCancel={() => this.modalClose()} />,
        },
        ...(this.permissions.includes(PERMISSION_VIEW_MERCHANT_IDS)
          ? [{
            menuItem: 'Merchant IDs',
            render: () => (
              <Tab.Pane style={{ float: 'left', width: '100%', marginBottom: 20 }}>
                <ManageMerchantTable DomainData={selectedDomain} onCancel={() => this.modalClose()} />
              </Tab.Pane>
            ),
          }]
          : []
        ),
        // {
        //   menuItem: 'Users',
        //   render: () => (
        //     <Tab.Pane style={{ float: 'left', width: '100%', marginBottom: 20 }}>
        //       <ManageUsersTable domainData={selectedDomain} />
        //     </Tab.Pane>
        //   ),
        // },
      ]
    );
  }

  createSideBar = () => {
    const { loadingDomainList } = this.state;
    return (
      <Sidebar>
        <Form className="block">
          <Form.Field>
            <label>Domain</label>
            <Input name="domain" onChange={e => this.inputChange(e)} placeholder="Domain name" />
          </Form.Field>
          <Form.Field>
            <label>Organisation Name</label>
            <Input name="organisationName" onChange={e => this.inputChange(e)} placeholder="Organisation Name" />
          </Form.Field>
        </Form>
        <div className="actions">
          <Button.Group>
            <Button loading={loadingDomainList} disabled={loadingDomainList} onClick={() => this.fetchDomains()}>Filter</Button>
          </Button.Group>
        </div>
      </Sidebar>
    );
  }

  displayDomains = () => {
    const { domains, currentPage, loadingDomainList } = this.state;
    if (loadingDomainList || domains.length < 1) { return null; }
    // if (domains.length < 1) return <Table.Row><Table.Cell><p>No results for your request</p></Table.Cell></Table.Row>; //! FIXME: This is actually really good but we should do it consistently across the site.
    const clickable = this.permissions.includes(MANAGE_DOMAINS);
    return (
      <React.Fragment>
        {domains[currentPage - 1].map(item => (
          <Table.Row
            onClick={clickable ? () => this.openUpdateModal(item.node) : null}
            key={`${item.cursor}`}
            style={clickable ? { cursor: 'pointer' } : null}
          >
            <Table.Cell>{item.node.domain}</Table.Cell>
            <Table.Cell>{item.node.organisationName}</Table.Cell>
            <Table.Cell>{item.node.assignableMerchants.map(i => `${i}${item.node.assignableMerchants.length > 1 ? ', ' : ''}`)}</Table.Cell>
            <Table.Cell>{item.node.address.country}</Table.Cell>
          </Table.Row>
        ))}
      </React.Fragment>
    );
  }

  render() {
    const { currentPage, domains, loadingDomainList } = this.state;
    return (

      <DashboardWrapper>
        {this.createSideBar()}
        <MainContentWrapper>
          {this.newDomainModal()}
          {this.updateDomainModal()}
          <div style={{ flexGrow: '1', position: 'relative' }}>
            {this.permissions.includes(MANAGE_DOMAINS) ? <Button style={{ position: 'absolute', right: '0', background: '#f9581f', color: 'white' }} onClick={() => this.setState({ addDomainModal: true })}>New Domain</Button> : null }
            <DashboardTitle>
              <Pagination
                size="mini"
                activePage={currentPage}
                totalPages={domains.length}
                onPageChange={e => this.handlePaginationChange(e)}
                ellipsisItem={null}
                lastItem={null}
                firstItem={null}
                boundaryRange={0}
                siblingRange={0}
              />
            </DashboardTitle>
            <TableWrapper>
              <Table striped selectable style={{ width: '100%' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Domain</Table.HeaderCell>
                    <Table.HeaderCell>Organisation</Table.HeaderCell>
                    <Table.HeaderCell>Merchant IDs</Table.HeaderCell>
                    <Table.HeaderCell>Country</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.displayDomains()}
                </Table.Body>
              </Table>
            </TableWrapper>
          </div>
          {loadingDomainList && <Loader size="medium" active>Loading</Loader>}
        </MainContentWrapper>
      </DashboardWrapper>
    );
  }
}
