import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { createApolloFetch } from 'apollo-fetch';
import ValidateTokenService from '../../services/ValidateTokenService';
import { Wrapper } from './styles';
import LoginForm from '../../components/LoginForm';
import { BASE_API_URL, EXPORT_DEFAULT_LIMIT } from '../App/constants';

const request = createApolloFetch({
  uri: `${BASE_API_URL}/login`,
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      disabled: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const validateTokenService = new ValidateTokenService();
    if (validateTokenService.refreshTokenIsValid()) {
      history.push('/dashboard');
    }
  }

  submitHandler = (data) => {
    this.setState({ error: '', disabled: true });
    const { history } = this.props;
    const { username: user, password } = data;
    const username = user.split('@')[0];
    const domain = user.split('@')[1];
    request({
      username,
      domain,
      password,
    }).then((res) => {
      if (res.auth) {
        const serverConfig = res.serverConfig || {};
        console.debug('auth OK, server config', serverConfig);
        window.localStorage.setItem('accessToken', res.accessToken);
        window.localStorage.setItem('refreshToken', res.refreshToken);
        window.localStorage.setItem('permissions', res.permissions);
        window.localStorage.setItem('exportLimit', serverConfig.exportLimit || EXPORT_DEFAULT_LIMIT);
        window.localStorage.setItem('username', username);
        window.localStorage.setItem('domain', domain.split('.')[0]);
        history.push('/');
      } else {
        this.setState({ error: 'Invalid username or password', disabled: false });
      }
    }).catch((err) => {
      console.error('err', err);
      this.setState({ error: 'Internal server error, try again', disabled: false });
    });
  }

  isFormValid = ({ username, password }) => username && password;

  render() {
    const { error, disabled } = this.state;
    return (
      <Wrapper style={{ flexDirection: 'column' }}>
        <Grid>
          <Grid.Row only="computer tablet">
            <Grid textAlign="center" style={{ marginTop: '187px', marginBottom: '30px' }}>
              <Grid.Column style={{ maxWidth: 350, padding: 0 }}>
                <img className="logo" src="/assets/till-logo-black.png" alt="Till Payments Logo" />
                <LoginForm disabled={disabled} onSubmit={this.submitHandler} error={error} />
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row only="mobile">
            <Grid textAlign="center" style={{ marginTop: 'auto', marginBottom: '30px' }}>
              <Grid.Column style={{ maxWidth: 350, padding: 0 }}>
                <img className="logo" src="/assets/till-logo-black.png" alt="Till Payments Logo" />
                <LoginForm disabled={disabled} onSubmit={this.submitHandler} error={error} />
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid>
        <p style={{ marginTop: 'auto', marginBottom: '20px', fontFamily: '"Helvetica, Arial",sans-serif', fontSize: 16, fontWeight: 300 }}>© 2019-2024 Till Payments</p>
      </Wrapper>
    );
  }
}

Login.propTypes = {
  history: PropTypes.any,
};

const mapStateFromProps = state => ({
  isLoading: state.user.isLoading,
});

export default withRouter(connect(mapStateFromProps)(Login));
