export const getMerchantsGraphqlQuery = (paging, sorting, filtering) => {
  const pagingString = paging && `
    {
      ${Object.keys(paging).map(pagingKey => `${pagingKey}: ${paging[pagingKey]}`)}
    }
  `;

  const sortingString = !sorting ? null : `
    {
      ${Object.keys(sorting).map(sortingKey => `${sortingKey}: ${sorting[sortingKey]}`)}
    }
  `;

  const filteringString = !filtering ? null : `
    {
      ${Object.keys(filtering).reduce((result, filteringKey) => { /* eslint-disable indent */ // Indent is off because of the string template
        const filteringValue = filtering[filteringKey];
        if (filteringValue) {
          if (Array.isArray(filteringValue)) {
            result.push(`${filteringKey}: ${JSON.stringify(filteringValue)}`);
          } else {
            result.push(`${filteringKey}: "${filteringValue}"`);
          }
        }
        return result;
      }, [])}
    }`; /* eslint-enable */

  return `
    {
      merchants(
      ${paging ? `paging: ${pagingString}` : ''}
      ${sorting ? `sorting: ${sortingString}` : ''}
      ${filtering ? `filtering: ${filteringString}` : ''}
      ) {
        edges {
          cursor
          node {
            merchantId
            organisationName
            merchantAccount
            conduit
            region
            acquirer
            processingTimezone
          }
        }
      }
    }
  `;
};
