import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ValidateTokenService from '../../services/ValidateTokenService';
import Tabs from '../../components/Tabs';
import ClearingDashboard from '../ClearingDashboard';
import SettlementDashboard from '../SettlementDashboard';
import TransactionsDashboard from '../TransactionsDashboard';
import TerminalsDashboard from '../TerminalsDashboard';
import UsersDashboard from '../UsersDashboard';
import RolesDashboard from '../RolesDashboard';

const VIEWS = {
  TRANSACTIONS: 'Transactions',
};

const viewDefs = [
  { key: VIEWS.TRANSACTIONS, isDefault: true },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: VIEWS.TRANSACTIONS,
    };
  }

  onViewChange = viewItem => this.setState({ view: viewItem.key });

  buildSettlementDashboard = () => <SettlementDashboard />;

  buildTransactionsDashboard = () => <TransactionsDashboard />;

  buildTerminalsDashboard = () => <TerminalsDashboard />;

  buildUsersDashboard = () => <UsersDashboard />;

  buildRolesDashboard = () => <RolesDashboard />;

  buildClearingDashboard = () => <ClearingDashboard />

  showCurrentView = () => {
    const { view } = this.state;
    switch (view) {
      case VIEWS.SETTLEMENT:
        return this.buildSettlementDashboard();
      case VIEWS.TRANSACTIONS:
        return this.buildTransactionsDashboard();
      case VIEWS.TERMINALS:
        return this.buildTerminalsDashboard();
      case VIEWS.USERS:
        return this.buildUsersDashboard();
      case VIEWS.ROLES:
        return this.buildRolesDashboard();
      case VIEWS.CLEARING:
        return this.buildClearingDashboard();
      default:
        return null;
    }
  }

  render() {
    const { history } = this.props;
    const validateTokenService = new ValidateTokenService();
    if (!validateTokenService.refreshTokenIsValid()) {
      history.push('/login');
      return null;
    }
    return (
      <div>
        <Tabs
          items={viewDefs}
          itemDef={{ isButton: true }}
          onChange={this.onViewChange}
        />
        {this.showCurrentView()}
      </div>
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.any,
};

export default withRouter(Dashboard);
