import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Table, Button, Input, Modal, Loader, Pagination, Message } from 'semantic-ui-react';
import { createTerminalsGraphqlQuery } from './getTerminalsGraphqlQuery';
import { ADD_TERMINAL_MUTATION, UPDATE_TERMINAL_MUTATION, REMOVE_TERMINAL_MUTATION } from './TerminalMutations';
import APIService2 from '../../services/APIService2';
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';
import DashboardTitle from '../../components/DashboardTitle';
import ModalDialog from '../../components/Modal';
import ConfirmActionPopupButton from '../../components/ConfirmActionPopupButton';

// Import constants
import {
  DEFAULT_NR_OF_TABLE_ROWS,
  GRAPHQL,
  PERMISSION_MANAGE_TERMINALS,
} from '../App/constants';

const uri = GRAPHQL;
const apiService = new APIService2();
const accessToken = window.localStorage.getItem('accessToken');

class TerminalsDashboard extends Component {
  constructor(props) {
    super(props);
    this.openAddTerminal = this.openAddTerminal.bind(this);
    this.displayUpdateModal = this.displayUpdateModal.bind(this);
    this.displayAddModal = this.displayAddModal.bind(this);
    this.buildSideBar = this.buildSideBar.bind(this);
    this.fetchTerminals = this.fetchTerminals.bind(this);
    this.displayTerminals = this.displayTerminals.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.createTerminal = this.createTerminal.bind(this);
    this.updateTerminal = this.updateTerminal.bind(this);
    this.removeTerminal = this.removeTerminal.bind(this);
    this.filterTerminals = this.filterTerminals.bind(this);
    this.state = {
      addTerminalModal: false,
      deletingTerminalInProgress: false,
      updateTerminalModal: false,
      terminals: [],
      merchantId: '',
      terminalDesignator: '',
      terminalId: '',
      dataToBeUpdated: null,
      filterMerchantId: '',
      filterTerminalId: '',
      filterTerminalDesignator: '',
      currentPage: 1,
      loading: false,
      errorMessage: '',
      waitForCreateTerminal: false,
      waitForUpdateTerminal: false,
    };
    const permissions = window.localStorage.getItem('permissions');
    this.manageTerminalsPermission = permissions.includes(PERMISSION_MANAGE_TERMINALS);
  }

  componentDidMount() {
    this.fetchTerminals();
  }

  filterTerminals = () => {
    const { filterMerchantId, filterTerminalId, filterTerminalDesignator } = this.state;
    this.setState({ merchantId: filterMerchantId, terminalId: filterTerminalId, terminalDesignator: filterTerminalDesignator, currentPage: 1 }, () => {
      this.fetchTerminals('filter');
    });
  }

  fetchTerminals = async () => {
    this.setState({ terminals: [], loading: true });
    const { history } = this.props;
    const { terminalId, terminalDesignator, merchantId } = this.state;
    const paging = { first: 1000 };
    const sorting = {
      sortOrder: 'ASCENDING',
      sortField: 'MERCHANT_ID',
    };
    const filtering = {
      terminalId,
      terminalDesignator,
      merchantId,
    };
    try {
      const query = createTerminalsGraphqlQuery(paging, sorting, filtering);
      const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
      const terminalEdges = result.data.terminals.edges;
      const terminalsListChunked = _.chunk(terminalEdges, DEFAULT_NR_OF_TABLE_ROWS);
      this.setState({ loading: false, terminals: terminalsListChunked });
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  inputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  createTerminal = async () => {
    this.setState({ errorMessage: '', waitForCreateTerminal: true });
    const { history } = this.props;
    const { terminalId, terminalDesignator, merchantId } = this.state;
    const newTerminal = {
      terminalId,
      terminalDesignator,
      merchantId,
    };
    const mutation = ADD_TERMINAL_MUTATION();
    const params = { newTerminal };
    try {
      await apiService.request({ payload: { mutation, params }, accessToken, history, window, uri });
      this.modalClose();
      this.fetchTerminals();
      this.displayTerminals();
      this.setState({ terminalDesignator: null, terminalId: null, merchantId: null, waitForCreateTerminal: false });
    } catch (err) {
      this.setState({ errorMessage: err.message, waitForCreateTerminal: false });
    }
  }

  displayTerminals = () => {
    const { terminals, currentPage } = this.state;
    if (terminals.length < 1) return null;
    return (
      <React.Fragment>
        {terminals[currentPage - 1].map(i => ( // currentPage is 1 because of the pagination component;
          <Table.Row
            onClick={this.manageTerminalsPermission ? () => this.openUpdateTerminal(i.node) : null}
            data={i}
            key={Math.floor(Math.random() * (999999 - 0 + 1))}
            style={this.manageTerminalsPermission ? { cursor: 'pointer' } : null}
          >
            <Table.Cell>{i.node.merchantId}</Table.Cell>
            <Table.Cell>{i.node.terminalId}</Table.Cell>
            <Table.Cell>{i.node.terminalDesignator}</Table.Cell>
          </Table.Row>
        ))}
      </React.Fragment>
    );
  }

  openAddTerminal = () => this.setState({ addTerminalModal: true });

  openUpdateTerminal = data => this.setState({
    updateTerminalModal: true,
    dataToBeUpdated: data,
    merchantId: data.merchantId.trim(),
    terminalId: data.terminalId,
    terminalDesignator: data.terminalDesignator,
  });

  modalClose = () => this.setState({
    addTerminalModal: false,
    updateTerminalModal: false,
    merchantId: '',
    terminalDesignator: '',
    terminalId: '',
    errorMessage: '',
  });

  removeTerminal = async (terminalId) => {
    this.setState({ errorMessage: '', deletingTerminalInProgress: true });
    try {
      const { history } = this.props;
      const removeTerminal = terminalId;
      const mutation = REMOVE_TERMINAL_MUTATION();
      const params = { removeTerminal };
      await apiService.request({ payload: { mutation, params }, accessToken, history, window, uri });
      this.modalClose();
      this.fetchTerminals();
      this.displayTerminals();
      this.setState({ terminalDesignator: null, terminalId: null, merchantId: null });
    } catch (err) {
      this.setState({ errorMessage: err.message });
      throw err;
    } finally {
      this.setState({ deletingTerminalInProgress: false });
    }
  }

  updateTerminal = async () => {
    this.setState({ errorMessage: '', waitForUpdateTerminal: true });
    try {
      const { history } = this.props;
      const { terminalId, terminalDesignator, merchantId } = this.state;
      const updateTerminal = {
        terminalId,
        merchantId,
        terminalDesignator,
      };
      const mutation = UPDATE_TERMINAL_MUTATION();
      const params = { updateTerminal };
      await apiService.request({ payload: { mutation, params }, accessToken, history, window, uri });
      this.modalClose();
      this.fetchTerminals();
      this.displayTerminals();
      this.setState({ terminalDesignator: null, terminalId: null, merchantId: null, waitForUpdateTerminal: false });
    } catch (err) {
      this.setState({ errorMessage: err.message, waitForUpdateTerminal: false });
    }
  }

  handlePaginationChange = (event) => {
    const value = event.target.getAttribute('value');
    this.setState({ currentPage: Number(value) });
  }

  displayUpdateModal = () => {
    const { dataToBeUpdated, updateTerminalModal, waitForUpdateTerminal, terminalDesignator, errorMessage, deletingTerminalInProgress } = this.state;
    if (!dataToBeUpdated) { return <Loader />; }
    return (
      <Modal size="large" open={updateTerminalModal} onClose={() => this.modalClose()}>
        <Modal.Header> {/* eslint-disable-line react/jsx-one-expression-per-line */ /* Bug: https://github.com/yannickcr/eslint-plugin-react/issues/2152 */}
          Update Terminal
          <ConfirmActionPopupButton
            triggerText="Remove Terminal"
            contentText="Are you sure you want to delete this Terminal?"
            submit={() => this.removeTerminal(dataToBeUpdated.terminalId)}
            loading={deletingTerminalInProgress}
          />
        </Modal.Header>
        <Modal.Content>
          {errorMessage && <Message error content={errorMessage} />}
          <Form style={{ marginTop: '20px' }}>
            <Form.Input width={16} label="Bank MID" className="input-disabled" placeholder="Bank MID" value={(dataToBeUpdated.merchantId).trim()} />
            <Form.Input
              width={7}
              label="TID"
              name="terminalId"
              placeholder="Bank TID"
              className="input-disabled"
              value={dataToBeUpdated.terminalId}
            />
            <Form.Input
              width={16}
              label="Machine Reference"
              onChange={event => this.inputChange(event)}
              name="terminalDesignator"
              placeholder="Machine Reference"
              defaultValue={dataToBeUpdated.terminalDesignator}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button style={{ margin: 0 }} onClick={() => this.modalClose()}>Cancel</Button>
          <Button style={{ color: 'white', background: '#f9581f' }} onClick={() => this.updateTerminal()} loading={waitForUpdateTerminal} disabled={!terminalDesignator}>Update</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  displayAddModal = () => {
    const { addTerminalModal, merchantId, terminalId, errorMessage, waitForCreateTerminal, terminalDesignator } = this.state;
    return (
      <Modal size="large" open={addTerminalModal} onClose={() => this.modalClose()}>
        <Modal.Header>Add new terminal</Modal.Header>
        <Modal.Content>
          {errorMessage && <Message error content={errorMessage} />}
          <Form style={{ marginTop: '20px' }}>
            <Form.Input label="Bank MID" name="merchantId" onChange={e => this.inputChange(e)} placeholder="Bank MID" />
            <Form.Input label="Bank TID" name="terminalId" placeholder="Bank TID" onChange={e => this.inputChange(e)} />
            <Form.Input label="Machine Reference" name="terminalDesignator" placeholder="Machine Reference" onChange={e => this.inputChange(e)} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.modalClose()}>Cancel</Button>
          <Button style={{ background: '#f9581f', color: 'black' }} onClick={() => this.createTerminal()} loading={waitForCreateTerminal} disabled={!merchantId || !terminalId || !terminalDesignator}>Add Terminal</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  buildSideBar = () => {
    const { filterMerchantId, filterTerminalId, filterTerminalDesignator, loading } = this.state;
    return (
      <Sidebar>
        <Form className="block">
          <Form.Field>
            <label>Bank MID</label>
            <Input name="filterMerchantId" defaultValue={filterMerchantId} onChange={event => this.inputChange(event)} placeholder="Bank MID" />
          </Form.Field>
          <Form.Field>
            <label>Bank TID</label>
            <Input name="filterTerminalId" defaultValue={filterTerminalId} onChange={event => this.inputChange(event)} placeholder="Bank TID" />
          </Form.Field>
          <Form.Field>
            <label>Machine Reference</label>
            <Input name="filterTerminalDesignator" defaultValue={filterTerminalDesignator} onChange={event => this.inputChange(event)} placeholder="Machine Reference" />
          </Form.Field>
        </Form>
        <div className="actions">
          <Button.Group>
            <Button loading={loading} disabled={loading} onClick={() => this.filterTerminals()}>Filter</Button>
          </Button.Group>
        </div>
      </Sidebar>
    );
  };

  render() {
    const { currentPage, terminals, loading } = this.state;
    return (
      <DashboardWrapper>
        <ModalDialog />
        {this.buildSideBar()}
        {this.displayAddModal()}
        {this.displayUpdateModal()}
        <div style={{ flexGrow: '1', position: 'relative' }}>
          {this.manageTerminalsPermission ? <Button style={{ position: 'absolute', right: '0', background: '#f9581f', color: 'black' }} onClick={() => this.openAddTerminal()}>New Terminal</Button> : null }
          <DashboardTitle>
            <Pagination
              size="mini"
              activePage={currentPage}
              totalPages={terminals.length}
              onPageChange={e => this.handlePaginationChange(e)}
              ellipsisItem={null}
              lastItem={null}
              firstItem={null}
              boundaryRange={0}
              siblingRange={0}
            />
          </DashboardTitle>
          <Table striped selectable style={{ width: '100%' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Bank MID</Table.HeaderCell>
                <Table.HeaderCell>Bank TID</Table.HeaderCell>
                <Table.HeaderCell>Machine Reference</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {/* FIX ME, this is working, however its not present on the other tables. */}
            {/* {!loading && terminals.length < 1 && (
              <Table.Body>
                <Table.Row><Table.Cell><p>No results for your request</p></Table.Cell></Table.Row>
              </Table.Body>
            )} */}
            <Table.Body>
              {this.displayTerminals()}
            </Table.Body>
          </Table>
          {loading && (
            <Loader size="medium" active>Loading</Loader>
          )}
        </div>
      </DashboardWrapper>
    );
  }
}

export default TerminalsDashboard;
