import { combineReducers } from 'redux';
import { SET_USER, CLEAR_USER } from '../actions/types';

const initialState = {
  isLogged: false,
  jwt: null,
  isLoading: true,
  username: '',
  domain: '',
};

const user_reducer = (state = initialState, action) => { //eslint-disable-line
  switch (action.type) {
    case SET_USER:
      window.localStorage.setItem('username', action.payload.user.split('@')[0]);
      window.localStorage.setItem('domain', action.payload.user.replace(/.*@/, '').split('.')[0]);
      return {
        isLoading: false,
        isLogged: true,
        username: action.payload.user.split('@')[0],
        domain: action.payload.user.replace(/.*@/, '').split('.')[0],
      };
    case CLEAR_USER:
      window.localStorage.clear();
      return {
        ...state,
        isLoading: false,
        isLogged: false,
        username: '',
      };
    default: return state;
  }
};

const rootReducer = combineReducers({
  user: user_reducer,
});

export default rootReducer;
