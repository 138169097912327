import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import './App.css';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import ValidateTokenService from '../services/ValidateTokenService';

class App extends Component {
  state = {};

  render() {
    const { history } = this.props;
    const validateTokenService = new ValidateTokenService();
    if (validateTokenService.refreshTokenIsValid()) {
      history.push('/dashboard');
    } else {
      history.push('/login');
    }

    return (
      <div style={{ width: '100%', float: 'left' }}>
        <Dimmer active inverted>
          <Loader size="medium">Loading</Loader>
        </Dimmer>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.any,
};

export default withRouter(App);
