import styled from 'styled-components';

export const Tab = styled.ul`
  display:flex;
  margin-bottom:35px;
  background:#dadada;
  height:55px;
  align-items:center;
  position:relative;
  float:left;
  width:100%;
  list-style:none;
  li {
    height:100%;
    min-width: 150px;
    text-align: center;
    cursor:pointer;
    span,
    a {
      width:100%;
      font-size:16px;
      padding:0 20px;
      color:#363636;
      letter-spacing: 3px;
      text-transform:capitalize;
      position:relative;
      text-decoration:none;
      height:100%;
      line-height:55px;
      float:left;
      &:hover {
        background:#f9581f;
        color:white;
      }
      &.active {
        background:#f9581f;
        color:black;
        font-weight:300;
        &:hover {
          opacity: .65;
        }
      }
    }
  }
`;
