import styled from 'styled-components';

export const Sidebar = styled.div`
  width: 255px;
  min-height:calc(100vh - 145px);
  padding: 0 10px;
  padding-right:30px;
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  border-right: 1px solid lightgray;
  margin-right:30px;
  .field:first-of-type { margin-top: 20px; }
  ul {
    width:100%;
    li {
      width:100%;
      display:flex;
      justify-content:space-between;
      height: 30px;
      margin-bottom: 10px;
      input {
        border-radius:3px;
        padding-left: 5px;
        border:1px solid lightgray;
      }
    }
  }
  .actions {
    margin: 15px 0;
    width:100%;
    .buttons {
      width:100%;
      > button { background: #f9581f!important; color: white; }
      .or {
        z-index:0;
      }
    }
  }
  .tabs {
    width: 100%;
    margin-bottom: 10px;
    .buttons {
      font-weight: bold;
      width:100%;
    }
  }
`;
