import { createApolloFetch } from 'apollo-fetch';
import getHistory from 'react-router-global-history';
import { BASE_API_URL, RETRY_TIME, INITIATED, PENDING } from '../containers/App/constants';

const autoTokenRefreshBlackList = [200, 204, 400, 504];

class APIService {
  async request({ payload, uri, hasAlreadyTried = false }) {
    const apolloFetch = createApolloFetch({
      uri: `${BASE_API_URL}/${uri}`,
    });
    let shouldTryAgain = false;
    const { refresh } = this;
    let result;
    const accessToken = window.localStorage.getItem('accessToken');
    apolloFetch
      .use(({ _request, options }, next) => {
        if (!options.headers) {
          options.headers = {}; /* eslint-disable-line no-param-reassign */
        }
        options.headers['Content-Type'] = 'application/json'; /* eslint-disable-line no-param-reassign */
        options.headers['Authorization'] = `Bearer ${accessToken}`; /* eslint-disable-line no-param-reassign */ /* eslint-disable-line dot-notation */
        next();
      })
      .useAfter(async ({ response }, next) => {
        if (autoTokenRefreshBlackList.indexOf(response.status) !== -1) { next(); return; }

        const refreshToken = window.localStorage.getItem('refreshToken');

        const refreshResult = await refresh({ refreshToken });

        if (!refreshResult || !refreshResult.accessToken) {
          shouldTryAgain = false;
          next(); return;
        }
        shouldTryAgain = !hasAlreadyTried;

        window.localStorage.setItem('accessToken', refreshResult.accessToken);
        window.localStorage.setItem('refreshToken', refreshResult.refreshToken);
        next();
      });
    // Case 1: result fetched
    result = await apolloFetch(payload);
    if (!shouldTryAgain) return result;

    // Case 2: needs to re-fetch again due to renewed accessToken and refreshToken
    result = await this.request({ payload, uri, hasAlreadyTried: shouldTryAgain });
    return result;
  }

  async checkExportTransactionStatus({ payload, accessToken, uri }) {
    let result = {
      status: INITIATED,
    };
    while (result.status === INITIATED || result.status === PENDING) {
      await new Promise((r) => { setTimeout(() => { r(); }, RETRY_TIME); }); /* eslint-disable-line no-await-in-loop */
      result = await this.request({ payload, accessToken, uri }); /* eslint-disable-line no-await-in-loop */
    }
    return result;
  }

  async refresh({ refreshToken }) {
    const apolloFetch = createApolloFetch({
      uri: `${BASE_API_URL}/refresh-token`,
    });
    apolloFetch.use(({ _request, options }, next) => {
      if (!options.headers) {
        options.headers = {}; /* eslint-disable-line no-param-reassign */
      }
      options.headers['Content-Type'] = 'application/json'; /* eslint-disable-line no-param-reassign */
      options.headers['Authorization'] = `Bearer ${refreshToken}`; /* eslint-disable-line no-param-reassign */ /* eslint-disable-line dot-notation */
      next();
    }).useAfter(({ response }, next) => {
      // case: refresh token failed - logout
      if (response.status !== 200) {
        window.localStorage.clear();
        const history = getHistory();
        history.push('/');
      }
      next();
    });
    const result = await apolloFetch({});
    return result;
  }
}

export default APIService;
