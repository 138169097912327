import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Loader, Pagination, Modal } from 'semantic-ui-react';
import _ from 'lodash';

import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';
import { getMerchantsGraphqlQuery } from '../../components/Merchants/getMerchantsQuery';
import APIService from '../../services/APIService2';
import SimplepayTable from '../../components/SimplepayTable';
import { MainContentWrapper } from '../TransactionsDashboard/styles';
import ManageMerchantForm from '../../components/Merchants/ManageMerchantForm';

// Import constants
import {
  DEFAULT_NR_OF_TABLE_ROWS,
  GRAPHQL as GRAPHQL_ENDPOINT,
  PERMISSION_MANAGE_MERCHANT_IDS as MANAGE_MERCHANT_IDS,
  PERMISSION_VIEW_DOMAINS as VIEW_DOMAINS,
} from '../App/constants';

// Define constants
const DOMAIN = 'Domain';
const MERCHANT_ID = 'Merchant ID';
const MERCHANT_NAME = 'Merchant Name';
const REGION = 'Region';

const GRAPHQL_FIELDS = { //! FIXME: Should get this from the server instead.
  MERCHANT_ID: 'merchantId',
  ORGANISATION_NAME: 'organisationName',
  MERCHANT_ACCOUNT: 'merchantAccount',
  CONDUIT: 'conduit',
  REGION: 'region',
  ACQUIRER: 'acquirer',
  TIMEZONE: 'processingTimezone',
};
const TABLE_COLUMNS = [
  { dataKey: GRAPHQL_FIELDS.MERCHANT_ID, headerOverride: 'Merchant ID' },
  { dataKey: GRAPHQL_FIELDS.MERCHANT_ACCOUNT, headerOverride: 'Merchant Name' },
  { dataKey: GRAPHQL_FIELDS.CONDUIT },
  { dataKey: GRAPHQL_FIELDS.REGION },
  { dataKey: GRAPHQL_FIELDS.ACQUIRER },
  { dataKey: GRAPHQL_FIELDS.TIMEZONE },
];

// Instantiate objects
const apiService = new APIService();

class MerchantsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      merchantIds: [],
      loading: true,
    };
    this.permissions = window.localStorage.getItem('permissions');
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  componentDidMount() {
    this.fetchMerchantIds();
    this.searchFields = this.getSearchFields();
  }

  async fetchMerchantIds() {
    this.setState({ merchantIds: [], loading: true, activePage: 0, totalPages: 0 });
    const { history } = this.props;
    const {
      filter: {
        [DOMAIN]: domain,
        [MERCHANT_ID]: merchantId,
        [MERCHANT_NAME]: merchantAccount,
        [REGION]: region,
      },
    } = this.state;
    const filter = {
      domain: domain && domain.trim(),
      merchantId: merchantId && [merchantId.trim()], // Backend expects array
      merchantAccount: merchantAccount && merchantAccount.trim(),
      region: region && region.trim(),
    };
    const query = getMerchantsGraphqlQuery(null, null, filter);
    const accessToken = window.localStorage.getItem('accessToken');
    try {
      const result = await apiService.request({ payload: { query }, accessToken, history, window, uri: GRAPHQL_ENDPOINT });
      const edges = result && result.data && result.data.merchants && result.data.merchants.edges;
      const merchantIds = (edges && _.chunk(edges, DEFAULT_NR_OF_TABLE_ROWS)) || [];
      const totalPages = merchantIds.length;
      this.setState({ merchantIds, loading: false, totalPages, activePage: 1 });
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  inputChange(event) {
    const { value, name } = event.target;
    const { filter } = this.state;
    this.setState({ filter: Object.assign(filter, { [name]: value }) });
  }

  handlePaginationChange(_event, { activePage }) {
    this.setState({ activePage });
  }

  getSearchFields() {
    const POSSIBLE_SEARCH_FIELDS = [
      DOMAIN,
      MERCHANT_ID,
      MERCHANT_NAME,
      REGION,
    ];

    const unallowedSearchFields = new Set();
    if (!this.permissions.includes(VIEW_DOMAINS)) {
      unallowedSearchFields.add(DOMAIN);
      unallowedSearchFields.add(REGION);
    }

    const formFields = POSSIBLE_SEARCH_FIELDS.map((fieldName) => {
      if (unallowedSearchFields.has(fieldName)) { return null; }
      return (
        <Form.Field key={fieldName}>
          <label>{fieldName}</label>
          <Input name={fieldName} placeholder={fieldName} onChange={event => this.inputChange(event)} />
        </Form.Field>
      );
    });

    return (
      <Form className="block">
        {formFields}
      </Form>
    );
  }

  render() {
    const { searchFields } = this;
    const { activePage, totalPages, merchantIds, loading, modalOpen } = this.state;
    // const addMerchantIdButton = ( // FIXME: Currently not functional because of the ManageMerchantForm in the modal. Once that is fixed we can enable this.
    //   <Button
    //     color="orange"
    //     style={{ position: 'absolute', right: '0' }}
    //     onClick={() => this.setState({ modalOpen: true })}
    //   >
    //     New Merchant ID
    //   </Button>
    // );
    return (
      <DashboardWrapper>
        <Sidebar>
          {searchFields}
          <div className="actions">
            <Button.Group>
              <Button loading={loading} disabled={loading} onClick={() => this.fetchMerchantIds()}>Filter</Button>
            </Button.Group>
          </div>
        </Sidebar>
        <MainContentWrapper>
          <Modal
            size="large"
            open={modalOpen}
            onClose={() => this.setState({ modalOpen: false })}
            closeOnDimmerClick
          >
            <Modal.Content>
              <ManageMerchantForm />
            </Modal.Content>
          </Modal>
          {this.permissions.includes(MANAGE_MERCHANT_IDS) /* addMerchantIdButton // FIXME: The form is not working. Hiding this until it does */}
          <aside className="pag-wrapper">
            <Pagination
              activePage={activePage || 0}
              totalPages={totalPages || 0}
              ellipsisItem={null}
              lastItem={null}
              firstItem={null}
              boundaryRange={0}
              siblingRange={0}
              size="mini"
              onPageChange={this.handlePaginationChange}
              style={{ marginBottom: '10px' }}
            />
          </aside>
          <SimplepayTable
            tableColumns={TABLE_COLUMNS}
            relayData={merchantIds[activePage - 1]}
            loading={loading}
          />
          {loading && <Loader size="medium" active> Loading </Loader>}
        </MainContentWrapper>
      </DashboardWrapper>
    );
  }
}

export default withRouter(MerchantsDashboard);
