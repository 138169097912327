import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { TableWrapper } from '../containers/TransactionsDashboard/styles';

class SimplepayTable extends PureComponent {
  generateTableHeaders(tableColumns) {
    return tableColumns.map(({ dataKey, headerOverride }) => {
      const header = headerOverride || this.splitAndCapitalise(dataKey);
      return <Table.HeaderCell key={header}>{header}</Table.HeaderCell>;
    });
  }

  generateTableBody(relayData, tableColumns) {
    return relayData && relayData.map((edge, index) => {
      const item = edge.node;
      return (
        <Table.Row data={`${index}`} key={`${item.merchantId}`}>
          {tableColumns.map(({ dataKey }) => (<Table.Cell key={`${dataKey}-${item[dataKey]}`}>{item[dataKey]}</Table.Cell>))}
        </Table.Row>
      );
    });
  }

  splitAndCapitalise(inputString) {
    return inputString[0].toUpperCase() + inputString.slice(1).replace(/([A-Z])/g, ' $1').trim();
  }

  render() {
    const { tableColumns, relayData, loading } = this.props;
    const tableHeaders = this.generateTableHeaders(tableColumns);
    const tableBody = this.generateTableBody(relayData, tableColumns);
    return (
      <TableWrapper>
        <Table textAlign="center" singleLine striped selectable style={{ width: '100%' }}>
          <Table.Header>
            <Table.Row>
              {tableHeaders}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!loading && tableBody}
          </Table.Body>
        </Table>
      </TableWrapper>
    );
  }
}

SimplepayTable.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  relayData: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

export default SimplepayTable;
