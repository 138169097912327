import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  .cardType {
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;
    > div {
      width:45px;
      height:45px;
      background-size:100%!important;
    }
    &.visa > div {background:url('/assets/visa-logo.svg') center center no-repeat;}
    &.mastercard > div {background:url('/assets/mastercard-logo.svg') center center no-repeat;}
    &.american_express > div {background:url('/assets/amex-logo.svg') center center no-repeat;}
    &.discover_card > {background:url('/assets/discover-logo.svg') center center no-repeat;}
  }
`;