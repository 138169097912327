import React, { Component } from 'react';
import faker from 'faker';
import { FaTimesCircle, FaEdit } from 'react-icons/fa';
import { Form, Table, Button, Input, Popup } from 'semantic-ui-react';
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';

const mockData = [
  { id: faker.random.number(), domain: 'simple pay', role: 'manager', actions: ['export_clearing', 'manage_clearing', 'export_settlement', 'manage_settlement'] },
  { id: faker.random.number(), domain: 'simple pay', role: 'viewer', actions: ['export_clearing', 'manage_clearing', 'export_settlement'] },
  { id: faker.random.number(), domain: 'simple pay', role: 'admin', actions: ['export_clearing', 'manage_clearing', 'manage_settlement'] },
  { id: faker.random.number(), domain: 'simple pay', role: 'user', actions: ['manage_clearing', 'export_settlement', 'manage_settlement', 'manage_transactions'] },
  { id: faker.random.number(), domain: 'simple pay', role: 'super user', actions: ['export_clearing', 'manage_clearing', 'export_settlement', 'manage_settlement'] },
];

class RolesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      initialList: mockData,
    };
  }

  filterList = (event) => {
    const { initialList } = this.state;
    const updateList = initialList;
    const filtered = [];
    updateList.filter((item) => { //eslint-disable-line
      if (item.role.toLowerCase().search(event.target.value) !== -1) {
        filtered.push(item);
      }
    });
    this.setState({ items: filtered });
  }

  buildTable = () => {
    const { items, initialList } = this.state;
    return (
      <Table striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Domain</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.length < 1 ? (
            initialList.map(item => (
              <Table.Row key={`${item.id}`}>
                {Object.keys(item).map(i => (
                  <React.Fragment key={item[i]}>
                    {typeof (item[i]) !== 'object' ? <Table.Cell key={item[i]}>{item[i]}</Table.Cell> : <Table.Cell key={item[i]}>{item[i].join(', ')}</Table.Cell>}
                  </React.Fragment>
                ))}
                <Table.Cell className="last-td">
                  <FaEdit onClick={this.openEditModal} size={20} />
                  <Popup
                    trigger={<FaTimesCircle size={20} color="red" />}
                    content={<Button color="red" content="Confirm remove item" />}
                    on="click"
                    position="top center"
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            items.map(item => (
              <Table.Row key={`${item.id}`}>
                {Object.keys(item).map(i => (
                  <React.Fragment key={item[i]}>
                    {typeof (item[i]) !== 'object' ? <Table.Cell key={item[i]}>{item[i]}</Table.Cell> : <Table.Cell key={item[i]}>{item[i].join(', ')}</Table.Cell>}
                  </React.Fragment>
                ))}
                <Table.Cell className="last-td">
                  <Popup
                    trigger={<FaTimesCircle size={20} color="red" />}
                    content={<Button color="red" content="Confirm remove item" />}
                    on="click"
                    position="top center"
                  />
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    );
  }

  render() {
    return (
      <DashboardWrapper>
        <Sidebar>
          <Form className="block">
            <Form.Field>
              <label>Role</label>
              <Input onChange={e => this.filterList(e)} placeholder="Role" />
            </Form.Field>
            <Form.Field>
              <label>Action</label>
              <div>
                <Input list="actions" placeholder="Choose an action..." />
                <datalist id="actions">
                  <option value="English" />
                  <option value="Chinese" />
                  <option value="Dutch" />
                </datalist>
              </div>
            </Form.Field>
          </Form>
          <div className="actions">
            <Button.Group>
              <Button>Export</Button>
              <Button.Or />
              <Button positive>Refresh</Button>
            </Button.Group>
          </div>
        </Sidebar>
        {this.buildTable()}
      </DashboardWrapper>
    );
  }
}

export default RolesDashboard;
