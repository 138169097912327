import styled from 'styled-components';

export const DateFilterWrapper = styled.div`
  border-radius: 5px;
  padding-bottom: 6px;
  margin-bottom: 14px;
  .field {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FilterGroupHeader = styled.h1`
  font-size: 1.5em;
`;

export const MainContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  .pag-wrapper {
    display:flex;
    justify-content:center;
  }
  .ui.text.loader {
    position: relative;
    top: 25%;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  .cardType {
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;
    > div {
      width:45px;
      height:45px;
      background-size:100%!important;
    }
    &.visa > div {background:url('/assets/visa-logo.svg') center center no-repeat;}
    &.mastercard > div {background:url('/assets/mastercard-logo.svg') center center no-repeat;}
    &.american_express > div {background:url('/assets/amex-logo.svg') center center no-repeat;}
    &.discover_card > div {background:url('/assets/discover-logo.svg') center center no-repeat;}
  }
`;

export const RadioWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  margin-bottom: 20px;
`;
