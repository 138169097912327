import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StepWrapper from './style';

export default class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { steps, validated } = this.props;
    return (
      <StepWrapper steps={steps}>
        {Array.from(Array(steps), (_, i) => (
          <div className={`unit  ${validated > i + 1 ? 'validated' : ''}`} key={i}>
            <div className="stepBullet">
              {i + 1}
            </div>
            <div className="line"></div>
          </div>
        ))}
      </StepWrapper>
    );
  }
}

Steps.propTypes = {
  steps: PropTypes.number,
  validated: PropTypes.number,
};
