import styled from 'styled-components';

const stepWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  .unit {
    flex-grow:1;
    position:relative;
    overflow:hidden;
    .stepBullet {
      width:40px;
      height:40px;
      border-radius:50%;
      display:flex;
      justify-content:center;
      align-items:center;
      position:relative;
      border:3px solid #ddd;
      &.active {
        background:#ddd;
        border:0;
      }
    }
    .line {
      position:absolute;
      width:100%;
      height:5px;
      top:50%;
      left:40px;
      background:#ddd;
    };
    &.validated {
      .stepBullet {
        border-color:#f15a22;
      }
      .line {
        background:#f15a22;
      }
    }
    &:last-of-type {
      flex-grow:0;
    }
  }
`;

export default stepWrapper;
