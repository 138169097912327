import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  background: #333;
  border-bottom: 7px solid #f9581f;
`;

export const Logo = styled.div`
  width: 60px;
  height: 55px;
  background: url('/assets/till-logo-inverse.png') center center no-repeat;
  background-size: 100%;
`;

export const IconsWrapper = styled.ul`
  list-style: none;
  display: flex;
  align-items:center;
  li {
    display:flex;
    align-items:center;
    margin-left:20px;
    padding:5px;
    cursor:pointer;
    border-radius:5px;
    &:hover {
      background: rgba(0, 0, 0, .07);
    }
    a {
      color: black;
    }
  }
  span.info { color: #f9581f; letter-spacing: 0.05em }
  p {
    margin:0;
    margin-right:15px;
    color:white;
  }
  
`;

export const NotifyItem = styled.span`
  .notification-message { margin-right:15px; }
`;
