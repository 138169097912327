import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactRouterGlobalHistory } from 'react-router-global-history'; // HACK: This is to make history available outside components. react-router-dom@5.1.0 introduces useHistory that is better.
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider, connect } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { createStore } from 'redux';
import App from './containers/App';
import rootReducer from './reducers';
import { clearUser, setUser } from './actions';

import Header from './components/Header';
import Login from './containers/Auth/Login';
import Dashboard from './containers/Dashboard';
import Admin from './containers/Admin';
// import ProfileDashboard from './containers/ProfileDashboard';
import ForgotPassword from './containers/Auth/Forgot';
import ResetPassword from './containers/Auth/Reset';

const store = createStore(rootReducer, composeWithDevTools());

class Root extends Component {
  state = {}

  render() {
    return (
      <Router>
        <div>
          <ReactRouterGlobalHistory />
          <Header />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/admin" component={Admin} />
            <Route path="/forgot" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            {/* <Route path="/profile" component={ProfileDashboard} /> */}
            <Route path="/" component={App} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateFromProps = state => ({
  isLoading: state.user.isLoading,
  username: state.user.username,
  isLogged: state.user.isLogged,
});

const RootWithAuth = withRouter(connect(mapStateFromProps, { setUser, clearUser })(Root));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>, document.getElementById('root'),
);
