const parseValue = (value, key) => {
  if (key === 'status') { return `${value}`; }
  if (key === 'amount') { return `${value}`; }
  if (typeof value === 'string') return `"${value}"`;
  return value;
};

export const createTransactionsGraphqlQuery = (filtering, paging, getSummary = false) => {
  const filteringQueryString = `
    {
      ${Object.entries(filtering).map(([key, value]) => `${key}: ${parseValue(value, key)}\n`)}
    }
  `;

  const pagingString = `
    {
      ${Object.keys(paging).map(pagingKey => `${pagingKey}: ${parseValue(paging[pagingKey])}`)}
    }
  `;

  const summaryString = getSummary ? `
    summary {
      valueOfTransactions
      numberOfTransactions
    }
  ` : '';

  return `
    {
      transactions(
        paging: ${pagingString},
        filtering: ${filteringQueryString}
      ) {
        edges {
          cursor
          node {
            transactionId
            transactionDate
            clearingDate
            settlementDate
            transactionSequenceNumber
            acquirerMerchantId
            acquirerTerminalId
            machineReference
            status
            amount
            currency
            cardNumber
            cardType
            refundStatus
            refundRequestDate
            refundTxSeqNo
            refundTxNo
            refundDate
            refundRequestedBy
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        ${summaryString}
      }
    }
  `;
};

export const createTransactionsSummaryGraphqlQuery = (filtering) => {
  const filteringQueryString = `
    {
      ${Object.entries(filtering).map(([key, value]) => `${key}: ${parseValue(value, key)}\n`)}
    }
  `;

  return `
    {
      transactionSummary(
        filtering: ${filteringQueryString}
      ) {
        valueOfTransactions
        numberOfTransactions
      }
    }
  `;
};

export const createOrphanedSettlementsSummaryGraphqlQuery = (filtering) => {
  const filteringQueryString = `
    {
      ${Object.entries(filtering).map(([key, value]) => `${key}: ${parseValue(value, key)}\n`)}
    }
  `;

  return `
    {
      orphanedSettlementsSummary(
        filtering: ${filteringQueryString}
      ) {
        valueOfTransactions
        numberOfTransactions
      }
    }
  `;
};

export const createExportTransactionsGraphqlQuery = (filtering, format, considerClearedAsSettled = false) => {
  const filteringQueryString = `
    {
      ${Object.entries(filtering).map(([key, value]) => `${key}: ${parseValue(value, key)}\n`)}
    }
  `;
  return `
    {
      exportTransactions(
        filtering: ${filteringQueryString}
        options: {
          format: "${format}",
          considerClearedAsSettled: ${considerClearedAsSettled}
        }
      ) {
        url
        filename
      }
    }
  `;
};
