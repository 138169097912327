import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const ConfirmActionPrompt = ({ text, submit, cancel, loading }) => (
  <div>
    <h3>
      {text || 'Are you sure?'}
    </h3>
    <div>
      <Button onClick={() => submit()} loading={loading} color="red">Yes</Button>
      <Button onClick={() => cancel()}>No</Button>
    </div>
  </div>
);

ConfirmActionPrompt.propTypes = {
  text: PropTypes.string,
  submit: PropTypes.func,
  cancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConfirmActionPrompt;
