import React, { Component } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import Modal from './styles';

export default class ModalDialog extends Component {
  constructor(props) {
    super(props);
    this.modalHandler = (e) => {
      this.setState({
        data: e.detail.data,
        visible: true,
      });
    };
    this.state = {
      data: {
        title: '',
        hideClose: false,
        closeOnClick: false,
        additionalButton: false,
        additionalButtonText: '',
        additionalButtonConfirm: '',
        content: '',
        status: '',
      },
    };
    this.close = this.close.bind(this);
    this.modalClick = this.modalClick.bind(this);
  }

  render() {
    const { visible, data } = this.state;

    return !visible ? null : (
      <Modal className="modal" onClick={this.modalClick}>
        <div className="dialog">
          <div className="dialog-title">
            {data.title}
            {!data.hideCloseBtn && (
              <span className="dialog-close" onClick={this.close}> {/* eslint-disable-line */}
                +
              </span>
            )}
            {data.additionalButton && (
              <Popup
                trigger={<Button size="mini">{data.additionalButtonText}</Button>}
                content={<Button content={data.additionalButtonConfirm} color="red" />}
                on="click"
                position="left center"
              />
            )}
          </div>
          <div className="dialog-content">
            {data.content}
          </div>
        </div>
      </Modal>
    );
  }

  componentDidMount() {
    document.addEventListener('modal', this.modalHandler);
    document.addEventListener('closeModal', this.close);
  }

  componentWillMount() {
    document.removeEventListener('modal', this.modalHandler);
  }

  close() {
    this.setState({
      visible: false,
      data: {
        title: '',
        closeOnClick: false,
        content: '',
      },
    });
  }

  static hide() {
    document.dispatchEvent(new CustomEvent('closeModal', {
      detail: {
        visible: false,
        data: {
          title: '',
          hideCloseBtn: false,
          closeOnClick: false,
          content: '',
        },
      },
    }));
  }

  static show(data) {
    document.dispatchEvent(new CustomEvent('modal', {
      detail: {
        data,
      },
    }));
  }

  modalClick() {
    const { data } = this.state;
    if (data.closeOnClick) this.close();
  }
}
