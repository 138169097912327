import styled from 'styled-components';

const FeedbackMessageWrapper = styled.div`
  p {
    padding: 10px;
    border-radius: 4px;
    &.negative {
      color: #9f3a38;
      border: 1px solid #ebccd1;
      background: #f2dede;
    }
    &.positive {
      border: 1px solid #a3c293;
      color: #2c662d;
      background: #fcfff5;
    }
  }
`;

export default FeedbackMessageWrapper;
