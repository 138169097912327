import {
  DEFAULT,
  DEPLOYMENT_REGION,
  DEPLOYMENT_REGION_APAC_EU as APAC_EU,
  DEPLOYMENT_REGION_US as US,
  TRANSACTION_STATUS_KEY_AUTHORISED as AUTHORISED,
  TRANSACTION_STATUS_KEY_CLEARED as CLEARED,
  TRANSACTION_STATUS_KEY_SETTLED as SETTLED,
  TRANSACTION_STATUS_KEY_SETTLED_EU as SETTLED_EU,
  TRANSACTION_STATUS_KEY_SETTLED_APAC as SETTLED_APAC,
  TRANSACTION_STATUS_KEY_REFUND as REFUND,
  TRANSACTION_STATUS_KEY_UNKNOWN as UNKNOWN,
  TRANSACTION_STATUS_KEY_DECLINED as DECLINED,
  TRANSACTION_STATUS_KEY_FAILED_CLEARING as FAILED_CLEARING,
  TRANSACTION_STATUS_KEY_FAILED_SETTLEMENT as FAILED_SETTLEMENT,
} from '../../App/constants';

// This is used to set which statuses and the order of statuses that should be in the status filter dropdown based on region.
const STATUS_DROPDOWN_LISTS_BY_REGION = {
  [DEFAULT]: [
    AUTHORISED,
    CLEARED,
    SETTLED,
    UNKNOWN,
    DECLINED,
    FAILED_CLEARING,
    FAILED_SETTLEMENT,
    REFUND,
  ],
  [APAC_EU]: [
    AUTHORISED,
    SETTLED_EU,
    SETTLED_APAC,
    UNKNOWN,
    DECLINED,
    FAILED_CLEARING,
    FAILED_SETTLEMENT,
    REFUND,
  ],
  [US]: [
    AUTHORISED,
    CLEARED,
    UNKNOWN,
    DECLINED,
    FAILED_CLEARING,
    REFUND,
  ],
};

export function getStatusFilterDropdownOptionsArray(STATUS_MAP) {
  const region = STATUS_DROPDOWN_LISTS_BY_REGION[DEPLOYMENT_REGION] ? DEPLOYMENT_REGION : DEFAULT;
  return STATUS_DROPDOWN_LISTS_BY_REGION[region].map((status) => {
    const { key, value, text } = STATUS_MAP[status];
    return { key, value, text };
  });
}
