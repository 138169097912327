import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createApolloFetch } from 'apollo-fetch';
import { Table, Form, Input, Button, Dimmer, Loader } from 'semantic-ui-react';//eslint-disable-line
import DatePicker from 'react-datepicker';
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';
import ModalDialog from '../../components/Modal';
import { BASE_API_URL } from '../App/constants';

const request = new createApolloFetch({ //eslint-disable-line
  uri: `${BASE_API_URL}/graphql`,
});

const refresh = new createApolloFetch({ //eslint-disable-line
  uri: `${BASE_API_URL}/refresh-token`,
});

class SettlementDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: null,
      startDate: null,
      settlements: null,
      isoStartDate: '',
      isoEndDate: '',
    };
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { history } = this.props;
    const { isoStartDate, isoEndDate } = this.state;
    const accessToken = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');

    request.use(({ request, options }, next) => { //eslint-disable-line
      if (!options.headers) {
        options.headers = {}; //eslint-disable-line
      }
      options.headers['Content-Type'] = 'application/json'; //eslint-disable-line
      options.headers['Authorization'] = `Bearer ${accessToken}`; //eslint-disable-line
      next();
    });

    refresh.use(({ request, options }, next) => { //eslint-disable-line
      if (!options.headers) {
        options.headers = {}; //eslint-disable-line
      }
      options.headers['Content-Type'] = 'application/json'; //eslint-disable-line
      options.headers['Authorization'] = `Bearer ${refreshToken}`; //eslint-disable-line
      next();
    });

    request({
      query: `
      {
        settlements(
          filtering: {
            endTransactionDate: "${isoEndDate}",
            startTransactionDate: "${isoStartDate}",
          },
          sorting: {
            sortField: TRANSACTION_DATE,
            sortOrder: DESCENDING
          }) {
          edges {
            cursor
            node {
              acquirerMid
              acquirerTid
              authorisedAmount
              cardNumber
              cardType
              clearedAmount
              clearingDate
              currency
              machineReference
              settledAmount
              settlementDate
              settlementStatus
              status
              transactionDate
              transactionId
              transactionSequenceNumber
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      `,
    }).then((res) => {
      if (res.statusCode === 401) {
        refresh({}).then((response) => {
          if (response.statusCode === 401) {
            window.localStorage.clear();
            history.push('/');
          } else if (response.data) {
            this.fetchData();
          } else if (response.auth) {
            window.localStorage.setItem('accessToken', response.accessToken);
            window.localStorage.setItem('refreshToken', response.refreshToken);
            this.fetchData();
          }
        }).catch(err => console.log(err));
      } else if (res.data) {
        const result = [];
        res.data.settlements.edges.map(item => (
          result.push(item.node)
        ));
        this.setState({
          settlements: result,
        });
      } else {
        // FIXME: I don't think this should happen but I added this as I was fixing the infite load on 500 for authorization error
        const result = [];
        this.setState({
          settlements: result,
        });
      }
    }).catch((err) => {
      // FIXME: Probably want to handle errors in another way but this is tmp fix for infinite load on 500 for authorization error
      console.log(err);
      refresh({}).then((response) => {
        if (response.statusCode === 401) {
          window.localStorage.clear();
          history.push('/');
        } else if (response.data) {
          this.fetchData();
        } else if (response.auth) {
          window.localStorage.setItem('accessToken', response.accessToken);
          window.localStorage.setItem('refreshToken', response.refreshToken);
          this.fetchData();
        }
      }).catch((innerErr) => {
        console.log(innerErr);
        window.localStorage.clear();
        history.push('/');
      });
    });
  }

  openModal = (data) => {
    ModalDialog.show({
      title: `Details for transaction id ${data.transactionId}`,
      closeOnClick: false,
      content: Object.keys(data).map(item => (
        <div key={item} className="item">
          {item}
          <span>{data[item]}</span>
        </div>
      )),
    });
  }

  handleStartDate = (date) => {
    if (date) {
      this.setState({ startDate: date, isoStartDate: date.toISOString() });
    } else {
      this.setState({ startDate: '', isoStartDate: '' });
    }
  }

  handleEndDate = (date) => {
    if (date) {
      this.setState({ endDate: date, isoEndDate: date.toISOString() });
    } else {
      this.setState({ endDate: '', isoEndDate: '' });
    }
  }

  render() {
    const { startDate, endDate, settlements } = this.state;
    if (!settlements) return <Dimmer active inverted> <Loader size="medium"> Loading </Loader> </Dimmer>; //eslint-disable-line
    console.log(settlements.length);
    return (
      <DashboardWrapper>
        <ModalDialog />
        <Sidebar>
          <Form className="block">
            <Form.Field>
              <label>Start Date</label>
              <DatePicker
                selected={startDate}
                endDate={endDate}
                onChange={e => this.handleStartDate(e)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy h:mm aa"
                timeCaption="time"
              />

              <label>End Date</label>
              <DatePicker
                selected={endDate}
                endDate={endDate}
                onChange={e => this.handleEndDate(e)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy h:mm aa"
                timeCaption="time"
              />
            </Form.Field>
            <div className="actions">
              <Button.Group>
                <Button positive onClick={() => this.fetchData()}>Refresh</Button>
              </Button.Group>
            </div>
          </Form>
        </Sidebar>
        <div className="tableWrapper" style={{ width: '100%' }}>
          <Table striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Settlement Date</Table.HeaderCell>
                <Table.HeaderCell>Transaction Id</Table.HeaderCell>
                <Table.HeaderCell>Settlement Status</Table.HeaderCell>
                <Table.HeaderCell>Acquirer Mid</Table.HeaderCell>
                <Table.HeaderCell>Acquired Tid</Table.HeaderCell>
                <Table.HeaderCell>Card Type</Table.HeaderCell>
                <Table.HeaderCell>Card Number</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {settlements.map((item, index) => (
                <Table.Row onClick={() => this.openModal(item)} data={index} key={item.transactionId}>
                  <Table.Cell>{new Date(item.transactionDate).toLocaleString()}</Table.Cell>
                  <Table.Cell>{item.transactionId}</Table.Cell>
                  <Table.Cell>{item.settlementStatus}</Table.Cell>
                  <Table.Cell>{item.acquirerMid}</Table.Cell>
                  <Table.Cell>{item.acquirerTid}</Table.Cell>
                  <Table.Cell>{item.cardType}</Table.Cell>
                  <Table.Cell>{item.cardNumber}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {settlements.length < 1 && <p>0 results found.</p>}
        </div>
      </DashboardWrapper>
    );
  }
}

SettlementDashboard.propTypes = {
  history: PropTypes.any,
};


export default withRouter(SettlementDashboard);
