import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Modal, Message, Loader, Dimmer } from 'semantic-ui-react';
import APIService from '../../services/APIService2';
import { getDomainsGraphqlQuery } from '../../containers/DomainDashboard/getDomainsGraphqlQuery';
import ManageMerchantForm from './ManageMerchantForm';
import { getMerchantsGraphqlQuery } from './getMerchantsQuery';
import { UPDATE_DOMAIN_MUTATION } from '../../containers/DomainDashboard/DomainMutations';
import ConfirmActionPopupButton from '../ConfirmActionPopupButton';

// Import constants
import {
  GRAPHQL,
  PERMISSION_MANAGE_MERCHANT_IDS as MANAGE_MERCHANT,
} from '../../containers/App/constants';

const uri = GRAPHQL;
const apiService = new APIService();

class ManageMerchantTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: '',
      merchantAccount: '',
      conduit: '',
      region: '',
      acquirer: '',
      processingTimezone: '',
      secondModalOpen: false,
      assignableMerchants: [],
      merchantList: [],
      existingMerchant: false,
      isLoading: false,
      errorMessage: '',
    };
    const permissions = window.localStorage.getItem('permissions');
    this.manageMerchantPermission = permissions.includes(MANAGE_MERCHANT);
  }

  componentDidMount() {
    this.fetchUpdatedDomainData();
  }

  closeSecondModal = (cancel) => {
    this.setState({
      secondModalOpen: false,
      merchantId: '',
      merchantAccount: '',
      conduit: '',
      region: '',
      acquirer: '',
      processingTimezone: '',
      existingMerchant: false,
      errorMessage: '',
    });
    if (cancel) return;
    this.fetchUpdatedDomainData();
  }

  fetchUpdatedDomainData = async () => {
    this.setState({ isLoading: true });
    const accessToken = window.localStorage.getItem('accessToken');
    const { DomainData: { domain } } = this.props;
    const { history } = this.props;
    const paging = { first: 1000 };
    const sorting = {
      sortOrder: 'ASCENDING',
      sortField: 'DOMAIN',
    };
    const filtering = {
      domain,
    };
    const query = getDomainsGraphqlQuery(paging, sorting, filtering);
    try {
      const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
      const { assignableMerchants } = result.data.domains.edges[0].node;
      this.setState({ secondModalOpen: false, assignableMerchants });
    } catch (err) {
      // FIXME: Should handle this better, prob give error message, but right now we just render the old information.
    } finally {
      this.fetchMerchantsFromSpecificDomain();
    }
  }

  fetchMerchantsFromSpecificDomain = async () => {
    this.setState({ isLoading: true });
    const accessToken = window.localStorage.getItem('accessToken');
    const { assignableMerchants } = this.state;
    const { history } = this.props;
    const paging = { first: 1000 };
    const sorting = {
      sortOrder: 'ASCENDING',
      sortField: 'MERCHANT_ID',
    };
    const filtering = {
      merchantId: assignableMerchants,
    };
    const query = getMerchantsGraphqlQuery(paging, sorting, filtering);
    try {
      const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
      this.setState({ merchantList: result.data.merchants.edges });
    } catch (err) {
      // FIXME: Should handle this better, prob give error message, but right now we just render the old information.
    } finally {
      this.setState({ isLoading: false });
    }
  }

  updateDomainWithMerchants = async (data, removeMerchant) => {
    this.setState({ updateMerchantInProgress: true });
    const accessToken = window.localStorage.getItem('accessToken');
    const { merchantId } = data;
    const { history, DomainData } = this.props;
    const { assignableMerchants } = DomainData;

    if (removeMerchant) {
      assignableMerchants.forEach((i, index) => {
        if (i === merchantId) assignableMerchants.splice(index, 1);
      });
    } else if (assignableMerchants.indexOf(merchantId) < 0) {
      assignableMerchants.push(merchantId);
    }

    const mutation = UPDATE_DOMAIN_MUTATION();
    const params = { updateDomain: DomainData };
    try {
      await apiService.request({ payload: { mutation, params }, accessToken, history, window, uri });
      this.closeSecondModal();
    } catch (err) {
      throw new Error(`Merchant ID was created but not added to domain:\n${err.message}`);
    } finally {
      this.setState({ updateMerchantInProgress: false });
    }
  }

  removeMerchant = async (merchantId) => {
    /*
    As agreed, for now we are soft removing it, in other words, we are only
    removing the merchant Id from the Domain collection (assignableMerchants field)
    */
    this.setState({ errorMessage: '' });

    try {
      await this.updateDomainWithMerchants({ merchantId }, 'removeMerchant');
    } catch (err) {
      this.setState({ errorMessage: `The Merchant ID has not been removed from the domain:\n${err.message}` });
      throw err;
    }
  }

  openSecondModalToUpdateMerchant = (data) => {
    const { merchantId, merchantAccount, conduit, region, acquirer, processingTimezone } = data;
    this.setState({
      secondModalOpen: true,
      merchantId,
      merchantAccount,
      conduit,
      region,
      acquirer,
      processingTimezone,
      existingMerchant: true,
    });
  }

  buildSecondModalForm = () => {
    const {
      merchantId,
      secondModalOpen,
      existingMerchant,
      merchantAccount,
      conduit,
      region,
      acquirer,
      processingTimezone,
      updateMerchantInProgress,
      errorMessage,
    } = this.state;
    const { DomainData } = this.props;
    const merchantData = {
      merchantId,
      merchantAccount,
      conduit,
      region,
      acquirer,
      processingTimezone,
    };

    return (
      <Modal
        open={secondModalOpen}
        onOpen={() => this.setState({ secondModalOpen: true, merchantAccount: '', acquirer: '', region: '', processingTimezone: '', conduit: '' })}
        onClose={e => this.closeSecondModal(e)}
        size="large"
        trigger={this.manageMerchantPermission ? <Button style={{ background: '#f9581f', color: 'white' }} floated="right">Add</Button> : null}
        header={(
          <Modal.Header>
            {`${existingMerchant ? 'Update' : 'Add'} Merchant ID`}
            {existingMerchant && (
              <ConfirmActionPopupButton
                triggerText="Disassociate Merchant ID"
                contentText="Are you sure you want to disassociate this Merchant ID?"
                submit={() => this.removeMerchant(merchantId)}
                loading={updateMerchantInProgress}
              />
            )}
          </Modal.Header>
        )}
        content={(
          <Modal.Content>
            <Message
              header={`${existingMerchant ? 'Update Merchant ID for' : 'Add Merchant ID to'} '${DomainData.domain}${DomainData.organisationName ? ` - ${DomainData.organisationName}` : ''}'.`}
              content={!existingMerchant ? 'You can add an existing Merchant ID or create a new one.' : null}
            />
            <ManageMerchantForm
              errorMessage={errorMessage}
              domainData={DomainData}
              merchantData={merchantData}
              onCancel={this.closeSecondModal}
              afterSubmitingNewMerchant={data => this.updateDomainWithMerchants(data)}
              existingMerchant={existingMerchant}
            />
          </Modal.Content>
        )}
      />
    );
  }

  render() {
    const { merchantList, isLoading, assignableMerchants } = this.state;
    const { onCancel } = this.props;
    return (
      <>
        <Table striped style={{ width: '100%', marginBottom: 20 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Merchant ID</Table.HeaderCell>
              <Table.HeaderCell>Merchant Name</Table.HeaderCell>
              <Table.HeaderCell>Conduit</Table.HeaderCell>
              <Table.HeaderCell>Region</Table.HeaderCell>
              <Table.HeaderCell>Acquirer</Table.HeaderCell>
              <Table.HeaderCell>Processing Timezone</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!isLoading && assignableMerchants.map((merchantId) => {
              const i = merchantList.find(elem => elem.node.merchantId === merchantId);
              if (i) {
                return (
                  <Table.Row
                    onClick={this.manageMerchantPermission ? () => this.openSecondModalToUpdateMerchant(i.node, 'existingMerchant') : null}
                    key={i.cursor}
                    style={this.manageMerchantPermission ? { cursor: 'pointer' } : null}
                  >
                    <Table.Cell>{i.node.merchantId}</Table.Cell>
                    <Table.Cell>{i.node.merchantAccount}</Table.Cell>
                    <Table.Cell>{i.node.conduit}</Table.Cell>
                    <Table.Cell>{i.node.region}</Table.Cell>
                    <Table.Cell>{i.node.acquirer}</Table.Cell>
                    <Table.Cell>{i.node.processingTimezone}</Table.Cell>
                  </Table.Row>
                );
              }
              return (
                <Table.Row key={merchantId + Math.floor(Math.random() * 1000) /* There shouldn't be duplicates at all but just incase */}>
                  <Table.Cell>{merchantId}</Table.Cell>
                  <Table.Cell colSpan="6" style={{ color: 'red', textAlign: 'center' }}> You don't have access to this Merchant ID</Table.Cell>  {/* eslint-disable-line react/no-unescaped-entities */ /* eslint-disable-line react/jsx-one-expression-per-line */}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {isLoading && (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        )}
        {this.buildSecondModalForm()}
        <Button floated="right" onClick={() => onCancel()}>Cancel</Button>
      </>
    );
  }
}

ManageMerchantTable.propTypes = {
  DomainData: PropTypes.object,
  onCancel: PropTypes.func,
};

export default ManageMerchantTable;
