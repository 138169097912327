export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const DEFAULT_NR_OF_TABLE_ROWS = 20;
export const DEPLOYMENT_REGION = process.env.REACT_APP_DEPLOYMENT_REGION;
export const RETRY_TIME = 8000;
export const PENDING = 'pending';
export const COMPLETED = 'completed';
export const INITIATED = 'initiated';
export const CHECKEXPORTTRANSACTIONSTATUS = 'checkExportTransactionStatus';
export const GRAPHQL = 'graphql';
export const INITIATEEXPORTTRANSACTION = 'initiateExportTransaction';
export const DEFAULT = 'default';

// Deployment versions flags
export const DEPLOYMENT_REGION_APAC_EU = 'apac-eu';
export const DEPLOYMENT_REGION_US = 'us';

// Permissions
export const PERMISSION_VIEW_DOMAINS = 'view_domains';
export const PERMISSION_MANAGE_DOMAINS = 'manage_domains';
export const PERMISSION_VIEW_MERCHANT_IDS = 'view_merchant_ids';
export const PERMISSION_MANAGE_MERCHANT_IDS = 'manage_merchant_ids';
export const PERMISSION_VIEW_TERMINALS = 'view_terminals';
export const PERMISSION_MANAGE_TERMINALS = 'manage_terminals';

// Transaction status
export const TRANSACTION_STATUS_KEY_AUTHORISED = 'authorised';
export const TRANSACTION_STATUS_KEY_CLEARED = 'cleared';
export const TRANSACTION_STATUS_KEY_SETTLED = 'settled';
export const TRANSACTION_STATUS_KEY_SETTLED_EU = 'settledEu';
export const TRANSACTION_STATUS_KEY_SETTLED_APAC = 'settledApac';
export const TRANSACTION_STATUS_KEY_REFUND = 'refund';
export const TRANSACTION_STATUS_KEY_REFUNDED = 'refunded';
export const TRANSACTION_STATUS_KEY_REFUND_REQUESTED = 'refundRequested';
export const TRANSACTION_STATUS_KEY_REFUND_REQUESTED_ERROR = 'refundRequestedError';
export const TRANSACTION_STATUS_KEY_UNKNOWN = 'unknown';
export const TRANSACTION_STATUS_KEY_DECLINED = 'declined';
export const TRANSACTION_STATUS_KEY_FAILED_CLEARING = 'failedClearing';
export const TRANSACTION_STATUS_KEY_FAILED_SETTLEMENT = 'failedSettlement';
export const TX_STATUS_REFUND = 'REFUND';
export const REFUNDED = 'REFUNDED';
export const REFUND_FAILED = 'REFUND_FAILED';
export const REFUND_REQUESTED_ERROR = 'REFUND_REQUESTED_ERROR';
export const REFUND_REQUESTED = 'REFUND_REQUESTED';
export const REFUND_PERMISSION = 'manage_refund';

export const EXPORT_STATUS_MAX_REACH = 'MAX_REACH';
export const EXPORT_DEFAULT_LIMIT = 50000;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
export const USERGUIDE_FILE_URL = process.env.REACT_APP_USER_GUIDE_FILE_URL;

export const COUNTRIES_LIST = [
  {
    country_id: 'AD',
    country_name: 'ANDORRA',
  },
  {
    country_id: 'AE',
    country_name: 'UNITED ARAB EMIRATES',
  },
  {
    country_id: 'AF',
    country_name: 'AFGHANISTAN',
  },
  {
    country_id: 'AG',
    country_name: 'ANTIGUA AND BARBUDA',
  },
  {
    country_id: 'AI',
    country_name: 'ANGUILLA',
  },
  {
    country_id: 'AL',
    country_name: 'ALBANIA',
  },
  {
    country_id: 'AM',
    country_name: 'ARMENIA',
  },
  {
    country_id: 'AN',
    country_name: 'NETHERLANDS ANTILLES',
  },
  {
    country_id: 'AO',
    country_name: 'ANGOLA',
  },
  {
    country_id: 'AQ',
    country_name: 'ANTARCTICA',
  },
  {
    country_id: 'AR',
    country_name: 'ARGENTINA',
  },
  {
    country_id: 'AS',
    country_name: 'AMERICAN SAMOA',
  },
  {
    country_id: 'AT',
    country_name: 'AUSTRIA',
  },
  {
    country_id: 'AU',
    country_name: 'AUSTRALIA',
  },
  {
    country_id: 'AW',
    country_name: 'ARUBA',
  },
  {
    country_id: 'AX',
    country_name: 'ÅLAND ISLANDS',
  },
  {
    country_id: 'AZ',
    country_name: 'AZERBAIJAN',
  },
  {
    country_id: 'BA',
    country_name: 'BOSNIA AND HERZEGOVINA',
  },
  {
    country_id: 'BB',
    country_name: 'BARBADOS',
  },
  {
    country_id: 'BD',
    country_name: 'BANGLADESH',
  },
  {
    country_id: 'BE',
    country_name: 'BELGIUM',
  },
  {
    country_id: 'BF',
    country_name: 'BURKINA FASO',
  },
  {
    country_id: 'BG',
    country_name: 'BULGARIA',
  },
  {
    country_id: 'BH',
    country_name: 'BAHRAIN',
  },
  {
    country_id: 'BI',
    country_name: 'BURUNDI',
  },
  {
    country_id: 'BJ',
    country_name: 'BENIN',
  },
  {
    country_id: 'BL',
    country_name: 'SAINT BARTHÉLEMY',
  },
  {
    country_id: 'BM',
    country_name: 'BERMUDA',
  },
  {
    country_id: 'BN',
    country_name: 'BRUNEI DARUSSALAM',
  },
  {
    country_id: 'BO',
    country_name: 'BOLIVIA',
  },
  {
    country_id: 'BR',
    country_name: 'BRAZIL',
  },
  {
    country_id: 'BS',
    country_name: 'BAHAMAS',
  },
  {
    country_id: 'BT',
    country_name: 'BHUTAN',
  },
  {
    country_id: 'BV',
    country_name: 'BOUVET ISLAND',
  },
  {
    country_id: 'BW',
    country_name: 'BOTSWANA',
  },
  {
    country_id: 'BY',
    country_name: 'BELARUS',
  },
  {
    country_id: 'BZ',
    country_name: 'BELIZE',
  },
  {
    country_id: 'CA',
    country_name: 'CANADA',
  },
  {
    country_id: 'CC',
    country_name: 'COCOS (KEELING) ISLANDS',
  },
  {
    country_id: 'CD',
    country_name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  },
  {
    country_id: 'CF',
    country_name: 'CENTRAL AFRICAN REPUBLIC',
  },
  {
    country_id: 'CG',
    country_name: 'CONGO',
  },
  {
    country_id: 'CH',
    country_name: 'SWITZERLAND',
  },
  {
    country_id: 'CI',
    country_name: 'CÔTE D IVOIRE',
  },
  {
    country_id: 'CK',
    country_name: 'COOK ISLANDS',
  },
  {
    country_id: 'CL',
    country_name: 'CHILE',
  },
  {
    country_id: 'CM',
    country_name: 'CAMEROON',
  },
  {
    country_id: 'CN',
    country_name: 'CHINA',
  },
  {
    country_id: 'CO',
    country_name: 'COLOMBIA',
  },
  {
    country_id: 'CR',
    country_name: 'COSTA RICA',
  },
  {
    country_id: 'CU',
    country_name: 'CUBA',
  },
  {
    country_id: 'CV',
    country_name: 'CAPE VERDE',
  },
  {
    country_id: 'CX',
    country_name: 'CHRISTMAS ISLAND',
  },
  {
    country_id: 'CY',
    country_name: 'CYPRUS',
  },
  {
    country_id: 'CZ',
    country_name: 'CZECH REPUBLIC',
  },
  {
    country_id: 'DE',
    country_name: 'GERMANY',
  },
  {
    country_id: 'DJ',
    country_name: 'DJIBOUTI',
  },
  {
    country_id: 'DK',
    country_name: 'DENMARK',
  },
  {
    country_id: 'DM',
    country_name: 'DOMINICA',
  },
  {
    country_id: 'DO',
    country_name: 'DOMINICAN REPUBLIC',
  },
  {
    country_id: 'DZ',
    country_name: 'ALGERIA',
  },
  {
    country_id: 'EC',
    country_name: 'ECUADOR',
  },
  {
    country_id: 'EE',
    country_name: 'ESTONIA',
  },
  {
    country_id: 'EG',
    country_name: 'EGYPT',
  },
  {
    country_id: 'EH',
    country_name: 'WESTERN SAHARA',
  },
  {
    country_id: 'ER',
    country_name: 'ERITREA',
  },
  {
    country_id: 'ES',
    country_name: 'SPAIN',
  },
  {
    country_id: 'ET',
    country_name: 'ETHIOPIA',
  },
  {
    country_id: 'FI',
    country_name: 'FINLAND',
  },
  {
    country_id: 'FJ',
    country_name: 'FIJI',
  },
  {
    country_id: 'FK',
    country_name: 'FALKLAND ISLANDS (MALVINAS)',
  },
  {
    country_id: 'FM',
    country_name: 'MICRONESIA, FEDERATED STATES OF',
  },
  {
    country_id: 'FO',
    country_name: 'FAROE ISLANDS',
  },
  {
    country_id: 'FR',
    country_name: 'FRANCE',
  },
  {
    country_id: 'GA',
    country_name: 'GABON',
  },
  {
    country_id: 'GB',
    country_name: 'UNITED KINGDOM',
  },
  {
    country_id: 'GD',
    country_name: 'GRENADA',
  },
  {
    country_id: 'GE',
    country_name: 'GEORGIA',
  },
  {
    country_id: 'GF',
    country_name: 'FRENCH GUIANA',
  },
  {
    country_id: 'GG',
    country_name: 'GUERNSEY',
  },
  {
    country_id: 'GH',
    country_name: 'GHANA',
  },
  {
    country_id: 'GI',
    country_name: 'GIBRALTAR',
  },
  {
    country_id: 'GL',
    country_name: 'GREENLAND',
  },
  {
    country_id: 'GM',
    country_name: 'GAMBIA',
  },
  {
    country_id: 'GN',
    country_name: 'GUINEA',
  },
  {
    country_id: 'GP',
    country_name: 'GUADELOUPE',
  },
  {
    country_id: 'GQ',
    country_name: 'EQUATORIAL GUINEA',
  },
  {
    country_id: 'GR',
    country_name: 'GREECE',
  },
  {
    country_id: 'GS',
    country_name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
  },
  {
    country_id: 'GT',
    country_name: 'GUATEMALA',
  },
  {
    country_id: 'GU',
    country_name: 'GUAM',
  },
  {
    country_id: 'GW',
    country_name: 'GUINEA-BISSAU',
  },
  {
    country_id: 'GY',
    country_name: 'GUYANA',
  },
  {
    country_id: 'HK',
    country_name: 'HONG KONG',
  },
  {
    country_id: 'HM',
    country_name: 'HEARD ISLAND AND MCDONALD ISLANDS',
  },
  {
    country_id: 'HN',
    country_name: 'HONDURAS',
  },
  {
    country_id: 'HR',
    country_name: 'CROATIA',
  },
  {
    country_id: 'HT',
    country_name: 'HAITI',
  },
  {
    country_id: 'HU',
    country_name: 'HUNGARY',
  },
  {
    country_id: 'ID',
    country_name: 'INDONESIA',
  },
  {
    country_id: 'IE',
    country_name: 'IRELAND',
  },
  {
    country_id: 'IL',
    country_name: 'ISRAEL',
  },
  {
    country_id: 'IM',
    country_name: 'ISLE OF MAN',
  },
  {
    country_id: 'IO',
    country_name: 'BRITISH INDIAN OCEAN TERRITORY',
  },
  {
    country_id: 'IQ',
    country_name: 'IRAQ',
  },
  {
    country_id: 'IR',
    country_name: 'IRAN, ISLAMIC REPUBLIC OF',
  },
  {
    country_id: 'IS',
    country_name: 'ICELAND',
  },
  {
    country_id: 'IT',
    country_name: 'ITALY',
  },
  {
    country_id: 'JE',
    country_name: 'JERSEY',
  },
  {
    country_id: 'JM',
    country_name: 'JAMAICA',
  },
  {
    country_id: 'JO',
    country_name: 'JORDAN',
  },
  {
    country_id: 'JP',
    country_name: 'JAPAN',
  },
  {
    country_id: 'KE',
    country_name: 'KENYA',
  },
  {
    country_id: 'KG',
    country_name: 'KYRGYZSTAN',
  },
  {
    country_id: 'KH',
    country_name: 'CAMBODIA',
  },
  {
    country_id: 'KI',
    country_name: 'KIRIBATI',
  },
  {
    country_id: 'KM',
    country_name: 'COMOROS',
  },
  {
    country_id: 'KN',
    country_name: 'SAINT KITTS AND NEVIS',
  },
  {
    country_id: 'KP',
    country_name: 'KOREA, DEMOCRATIC PEOPLE S REPUBLIC OF',
  },
  {
    country_id: 'KR',
    country_name: 'KOREA, REPUBLIC OF',
  },
  {
    country_id: 'KW',
    country_name: 'KUWAIT',
  },
  {
    country_id: 'KY',
    country_name: 'CAYMAN ISLANDS',
  },
  {
    country_id: 'KZ',
    country_name: 'KAZAKHSTAN',
  },
  {
    country_id: 'LA',
    country_name: 'LAO PEOPLE S DEMOCRATIC REPUBLIC',
  },
  {
    country_id: 'LB',
    country_name: 'LEBANON',
  },
  {
    country_id: 'LC',
    country_name: 'SAINT LUCIA',
  },
  {
    country_id: 'LI',
    country_name: 'LIECHTENSTEIN',
  },
  {
    country_id: 'LK',
    country_name: 'SRI LANKA',
  },
  {
    country_id: 'LR',
    country_name: 'LIBERIA',
  },
  {
    country_id: 'LS',
    country_name: 'LESOTHO',
  },
  {
    country_id: 'LT',
    country_name: 'LITHUANIA',
  },
  {
    country_id: 'LU',
    country_name: 'LUXEMBOURG',
  },
  {
    country_id: 'LV',
    country_name: 'LATVIA',
  },
  {
    country_id: 'LY',
    country_name: 'LIBYAN ARAB JAMAHIRIYA',
  },
  {
    country_id: 'MA',
    country_name: 'MOROCCO',
  },
  {
    country_id: 'MC',
    country_name: 'MONACO',
  },
  {
    country_id: 'MD',
    country_name: 'MOLDOVA, REPUBLIC OF',
  },
  {
    country_id: 'ME',
    country_name: 'MONTENEGRO',
  },
  {
    country_id: 'MF',
    country_name: 'SAINT MARTIN',
  },
  {
    country_id: 'MG',
    country_name: 'MADAGASCAR',
  },
  {
    country_id: 'MH',
    country_name: 'MARSHALL ISLANDS',
  },
  {
    country_id: 'MK',
    country_name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  },
  {
    country_id: 'ML',
    country_name: 'MALI',
  },
  {
    country_id: 'MM',
    country_name: 'MYANMAR',
  },
  {
    country_id: 'MN',
    country_name: 'MONGOLIA',
  },
  {
    country_id: 'MO',
    country_name: 'MACAO',
  },
  {
    country_id: 'MP',
    country_name: 'NORTHERN MARIANA ISLANDS',
  },
  {
    country_id: 'MQ',
    country_name: 'MARTINIQUE',
  },
  {
    country_id: 'MR',
    country_name: 'MAURITANIA',
  },
  {
    country_id: 'MS',
    country_name: 'MONTSERRAT',
  },
  {
    country_id: 'MT',
    country_name: 'MALTA',
  },
  {
    country_id: 'MU',
    country_name: 'MAURITIUS',
  },
  {
    country_id: 'MV',
    country_name: 'MALDIVES',
  },
  {
    country_id: 'MW',
    country_name: 'MALAWI',
  },
  {
    country_id: 'MX',
    country_name: 'MEXICO',
  },
  {
    country_id: 'MY',
    country_name: 'MALAYSIA',
  },
  {
    country_id: 'MZ',
    country_name: 'MOZAMBIQUE',
  },
  {
    country_id: 'NA',
    country_name: 'NAMIBIA',
  },
  {
    country_id: 'NC',
    country_name: 'NEW CALEDONIA',
  },
  {
    country_id: 'NE',
    country_name: 'NIGER',
  },
  {
    country_id: 'NF',
    country_name: 'NORFOLK ISLAND',
  },
  {
    country_id: 'NG',
    country_name: 'NIGERIA',
  },
  {
    country_id: 'NI',
    country_name: 'NICARAGUA',
  },
  {
    country_id: 'NL',
    country_name: 'NETHERLANDS',
  },
  {
    country_id: 'NO',
    country_name: 'NORWAY',
  },
  {
    country_id: 'NP',
    country_name: 'NEPAL',
  },
  {
    country_id: 'NR',
    country_name: 'NAURU',
  },
  {
    country_id: 'NU',
    country_name: 'NIUE',
  },
  {
    country_id: 'NZ',
    country_name: 'NEW ZEALAND',
  },
  {
    country_id: 'OM',
    country_name: 'OMAN',
  },
  {
    country_id: 'PA',
    country_name: 'PANAMA',
  },
  {
    country_id: 'PE',
    country_name: 'PERU',
  },
  {
    country_id: 'PF',
    country_name: 'FRENCH POLYNESIA',
  },
  {
    country_id: 'PG',
    country_name: 'PAPUA NEW GUINEA',
  },
  {
    country_id: 'PH',
    country_name: 'PHILIPPINES',
  },
  {
    country_id: 'PK',
    country_name: 'PAKISTAN',
  },
  {
    country_id: 'PL',
    country_name: 'POLAND',
  },
  {
    country_id: 'PM',
    country_name: 'SAINT PIERRE AND MIQUELON',
  },
  {
    country_id: 'PN',
    country_name: 'PITCAIRN',
  },
  {
    country_id: 'PR',
    country_name: 'PUERTO RICO',
  },
  {
    country_id: 'PS',
    country_name: 'PALESTINIAN TERRITORY, OCCUPIED',
  },
  {
    country_id: 'PT',
    country_name: 'PORTUGAL',
  },
  {
    country_id: 'PW',
    country_name: 'PALAU',
  },
  {
    country_id: 'PY',
    country_name: 'PARAGUAY',
  },
  {
    country_id: 'QA',
    country_name: 'QATAR',
  },
  {
    country_id: 'RE',
    country_name: 'REUNION',
  },
  {
    country_id: 'RO',
    country_name: 'ROMANIA',
  },
  {
    country_id: 'RS',
    country_name: 'SERBIA',
  },
  {
    country_id: 'RU',
    country_name: 'RUSSIAN FEDERATION',
  },
  {
    country_id: 'RW',
    country_name: 'RWANDA',
  },
  {
    country_id: 'SA',
    country_name: 'SAUDI ARABIA',
  },
  {
    country_id: 'SB',
    country_name: 'SOLOMON ISLANDS',
  },
  {
    country_id: 'SC',
    country_name: 'SEYCHELLES',
  },
  {
    country_id: 'SD',
    country_name: 'SUDAN',
  },
  {
    country_id: 'SE',
    country_name: 'SWEDEN',
  },
  {
    country_id: 'SG',
    country_name: 'SINGAPORE',
  },
  {
    country_id: 'SH',
    country_name: 'SAINT HELENA',
  },
  {
    country_id: 'SI',
    country_name: 'SLOVENIA',
  },
  {
    country_id: 'SJ',
    country_name: 'SVALBARD AND JAN MAYEN',
  },
  {
    country_id: 'SK',
    country_name: 'SLOVAKIA',
  },
  {
    country_id: 'SL',
    country_name: 'SIERRA LEONE',
  },
  {
    country_id: 'SM',
    country_name: 'SAN MARINO',
  },
  {
    country_id: 'SN',
    country_name: 'SENEGAL',
  },
  {
    country_id: 'SO',
    country_name: 'SOMALIA',
  },
  {
    country_id: 'SR',
    country_name: 'SURINAME',
  },
  {
    country_id: 'ST',
    country_name: 'SAO TOME AND PRINCIPE',
  },
  {
    country_id: 'SV',
    country_name: 'EL SALVADOR',
  },
  {
    country_id: 'SY',
    country_name: 'SYRIAN ARAB REPUBLIC',
  },
  {
    country_id: 'SZ',
    country_name: 'SWAZILAND',
  },
  {
    country_id: 'TC',
    country_name: 'TURKS AND CAICOS ISLANDS',
  },
  {
    country_id: 'TD',
    country_name: 'CHAD',
  },
  {
    country_id: 'TF',
    country_name: 'FRENCH SOUTHERN TERRITORIES',
  },
  {
    country_id: 'TG',
    country_name: 'TOGO',
  },
  {
    country_id: 'TH',
    country_name: 'THAILAND',
  },
  {
    country_id: 'TJ',
    country_name: 'TAJIKISTAN',
  },
  {
    country_id: 'TK',
    country_name: 'TOKELAU',
  },
  {
    country_id: 'TL',
    country_name: 'TIMOR-LESTE',
  },
  {
    country_id: 'TM',
    country_name: 'TURKMENISTAN',
  },
  {
    country_id: 'TN',
    country_name: 'TUNISIA',
  },
  {
    country_id: 'TO',
    country_name: 'TONGA',
  },
  {
    country_id: 'TR',
    country_name: 'TURKEY',
  },
  {
    country_id: 'TT',
    country_name: 'TRINIDAD AND TOBAGO',
  },
  {
    country_id: 'TV',
    country_name: 'TUVALU',
  },
  {
    country_id: 'TW',
    country_name: 'TAIWAN, PROVINCE OF CHINA',
  },
  {
    country_id: 'TZ',
    country_name: 'TANZANIA, UNITED REPUBLIC OF',
  },
  {
    country_id: 'UA',
    country_name: 'UKRAINE',
  },
  {
    country_id: 'UG',
    country_name: 'UGANDA',
  },
  {
    country_id: 'UM',
    country_name: 'UNITED STATES MINOR OUTLYING ISLANDS',
  },
  {
    country_id: 'US',
    country_name: 'UNITED STATES',
  },
  {
    country_id: 'UY',
    country_name: 'URUGUAY',
  },
  {
    country_id: 'UZ',
    country_name: 'UZBEKISTAN',
  },
  {
    country_id: 'VA',
    country_name: 'HOLY SEE (VATICAN CITY STATE)',
  },
  {
    country_id: 'VC',
    country_name: 'SAINT VINCENT AND THE GRENADINES',
  },
  {
    country_id: 'VE',
    country_name: 'VENEZUELA',
  },
  {
    country_id: 'VG',
    country_name: 'VIRGIN ISLANDS, BRITISH',
  },
  {
    country_id: 'VI',
    country_name: 'VIRGIN ISLANDS, U.S.',
  },
  {
    country_id: 'VN',
    country_name: 'VIET NAM',
  },
  {
    country_id: 'VU',
    country_name: 'VANUATU',
  },
  {
    country_id: 'WF',
    country_name: 'WALLIS AND FUTUNA',
  },
  {
    country_id: 'WS',
    country_name: 'SAMOA',
  },
  {
    country_id: 'YE',
    country_name: 'YEMEN',
  },
  {
    country_id: 'YT',
    country_name: 'MAYOTTE',
  },
  {
    country_id: 'ZA',
    country_name: 'SOUTH AFRICA',
  },
  {
    country_id: 'ZM',
    country_name: 'ZAMBIA',
  },
  {
    country_id: 'ZW',
    country_name: 'ZIMBABWE',
  },
  {
    country_id: 'IN',
    country_name: 'INDIA',
  },
];


export const TIMEZONE_LIST = [
  {
    value: 'Dateline Standard Time',
    abbr: 'DST',
    offset: -12,
    isdst: false,
    text: '(UTC-12:00) International Date Line West',
    utc: [
      'Etc/GMT+12',
    ],
  },
  {
    value: 'UTC-11',
    abbr: 'U',
    offset: -11,
    isdst: false,
    text: '(UTC-11:00) Coordinated Universal Time-11',
    utc: [
      'Etc/GMT+11',
      'Pacific/Midway',
      'Pacific/Niue',
      'Pacific/Pago_Pago',
    ],
  },
  {
    value: 'Hawaiian Standard Time',
    abbr: 'HST',
    offset: -10,
    isdst: false,
    text: '(UTC-10:00) Hawaii',
    utc: [
      'Etc/GMT+10',
      'Pacific/Honolulu',
      'Pacific/Johnston',
      'Pacific/Rarotonga',
      'Pacific/Tahiti',
    ],
  },
  {
    value: 'Alaskan Standard Time',
    abbr: 'AKDT',
    offset: -8,
    isdst: true,
    text: '(UTC-09:00) Alaska',
    utc: [
      'America/Anchorage',
      'America/Juneau',
      'America/Nome',
      'America/Sitka',
      'America/Yakutat',
    ],
  },
  {
    value: 'Pacific Standard Time (Mexico)',
    abbr: 'PDT',
    offset: -7,
    isdst: true,
    text: '(UTC-08:00) Baja California',
    utc: [
      'America/Santa_Isabel',
    ],
  },
  {
    value: 'Pacific Daylight Time',
    abbr: 'PDT',
    offset: -7,
    isdst: true,
    text: '(UTC-07:00) Pacific Time (US & Canada)',
    utc: [
      'America/Dawson',
      'America/Los_Angeles',
      'America/Tijuana',
      'America/Vancouver',
      'America/Whitehorse',
    ],
  },
  {
    value: 'Pacific Standard Time',
    abbr: 'PST',
    offset: -8,
    isdst: false,
    text: '(UTC-08:00) Pacific Time (US & Canada)',
    utc: [
      'America/Dawson',
      'America/Los_Angeles',
      'America/Tijuana',
      'America/Vancouver',
      'America/Whitehorse',
      'PST8PDT',
    ],
  },
  {
    value: 'US Mountain Standard Time',
    abbr: 'UMST',
    offset: -7,
    isdst: false,
    text: '(UTC-07:00) Arizona',
    utc: [
      'America/Creston',
      'America/Dawson_Creek',
      'America/Hermosillo',
      'America/Phoenix',
      'Etc/GMT+7',
    ],
  },
  {
    value: 'Mountain Standard Time (Mexico)',
    abbr: 'MDT',
    offset: -6,
    isdst: true,
    text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    utc: [
      'America/Chihuahua',
      'America/Mazatlan',
    ],
  },
  {
    value: 'Mountain Standard Time',
    abbr: 'MDT',
    offset: -6,
    isdst: true,
    text: '(UTC-07:00) Mountain Time (US & Canada)',
    utc: [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Ojinaga',
      'America/Yellowknife',
      'MST7MDT',
    ],
  },
  {
    value: 'Central America Standard Time',
    abbr: 'CAST',
    offset: -6,
    isdst: false,
    text: '(UTC-06:00) Central America',
    utc: [
      'America/Belize',
      'America/Costa_Rica',
      'America/El_Salvador',
      'America/Guatemala',
      'America/Managua',
      'America/Tegucigalpa',
      'Etc/GMT+6',
      'Pacific/Galapagos',
    ],
  },
  {
    value: 'Central Standard Time',
    abbr: 'CDT',
    offset: -5,
    isdst: true,
    text: '(UTC-06:00) Central Time (US & Canada)',
    utc: [
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Matamoros',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT',
    ],
  },
  {
    value: 'Central Standard Time (Mexico)',
    abbr: 'CDT',
    offset: -5,
    isdst: true,
    text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    utc: [
      'America/Bahia_Banderas',
      'America/Cancun',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
    ],
  },
  {
    value: 'Canada Central Standard Time',
    abbr: 'CCST',
    offset: -6,
    isdst: false,
    text: '(UTC-06:00) Saskatchewan',
    utc: [
      'America/Regina',
      'America/Swift_Current',
    ],
  },
  {
    value: 'SA Pacific Standard Time',
    abbr: 'SPST',
    offset: -5,
    isdst: false,
    text: '(UTC-05:00) Bogota, Lima, Quito',
    utc: [
      'America/Bogota',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Jamaica',
      'America/Lima',
      'America/Panama',
      'America/Rio_Branco',
      'Etc/GMT+5',
    ],
  },
  {
    value: 'Eastern Standard Time',
    abbr: 'EDT',
    offset: -4,
    isdst: true,
    text: '(UTC-05:00) Eastern Time (US & Canada)',
    utc: [
      'America/Detroit',
      'America/Havana',
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto',
      'EST5EDT',
    ],
  },
  {
    value: 'US Eastern Standard Time',
    abbr: 'UEDT',
    offset: -4,
    isdst: true,
    text: '(UTC-05:00) Indiana (East)',
    utc: [
      'America/Indiana/Marengo',
      'America/Indiana/Vevay',
      'America/Indianapolis',
    ],
  },
  {
    value: 'Venezuela Standard Time',
    abbr: 'VST',
    offset: -4.5,
    isdst: false,
    text: '(UTC-04:30) Caracas',
    utc: [
      'America/Caracas',
    ],
  },
  {
    value: 'Paraguay Standard Time',
    abbr: 'PYT',
    offset: -4,
    isdst: false,
    text: '(UTC-04:00) Asuncion',
    utc: [
      'America/Asuncion',
    ],
  },
  {
    value: 'Atlantic Standard Time',
    abbr: 'ADT',
    offset: -3,
    isdst: true,
    text: '(UTC-04:00) Atlantic Time (Canada)',
    utc: [
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Moncton',
      'America/Thule',
      'Atlantic/Bermuda',
    ],
  },
  {
    value: 'Central Brazilian Standard Time',
    abbr: 'CBST',
    offset: -4,
    isdst: false,
    text: '(UTC-04:00) Cuiaba',
    utc: [
      'America/Campo_Grande',
      'America/Cuiaba',
    ],
  },
  {
    value: 'SA Western Standard Time',
    abbr: 'SWST',
    offset: -4,
    isdst: false,
    text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    utc: [
      'America/Anguilla',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Curacao',
      'America/Dominica',
      'America/Grand_Turk',
      'America/Grenada',
      'America/Guadeloupe',
      'America/Guyana',
      'America/Kralendijk',
      'America/La_Paz',
      'America/Lower_Princes',
      'America/Manaus',
      'America/Marigot',
      'America/Martinique',
      'America/Montserrat',
      'America/Port_of_Spain',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Santo_Domingo',
      'America/St_Barthelemy',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Tortola',
      'Etc/GMT+4',
    ],
  },
  {
    value: 'Pacific SA Standard Time',
    abbr: 'PSST',
    offset: -4,
    isdst: false,
    text: '(UTC-04:00) Santiago',
    utc: [
      'America/Santiago',
      'Antarctica/Palmer',
    ],
  },
  {
    value: 'Newfoundland Standard Time',
    abbr: 'NDT',
    offset: -2.5,
    isdst: true,
    text: '(UTC-03:30) Newfoundland',
    utc: [
      'America/St_Johns',
    ],
  },
  {
    value: 'E. South America Standard Time',
    abbr: 'ESAST',
    offset: -3,
    isdst: false,
    text: '(UTC-03:00) Brasilia',
    utc: [
      'America/Sao_Paulo',
    ],
  },
  {
    value: 'Argentina Standard Time',
    abbr: 'AST',
    offset: -3,
    isdst: false,
    text: '(UTC-03:00) Buenos Aires',
    utc: [
      'America/Argentina/La_Rioja',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Buenos_Aires',
      'America/Catamarca',
      'America/Cordoba',
      'America/Jujuy',
      'America/Mendoza',
    ],
  },
  {
    value: 'SA Eastern Standard Time',
    abbr: 'SEST',
    offset: -3,
    isdst: false,
    text: '(UTC-03:00) Cayenne, Fortaleza',
    utc: [
      'America/Araguaina',
      'America/Belem',
      'America/Cayenne',
      'America/Fortaleza',
      'America/Maceio',
      'America/Paramaribo',
      'America/Recife',
      'America/Santarem',
      'Antarctica/Rothera',
      'Atlantic/Stanley',
      'Etc/GMT+3',
    ],
  },
  {
    value: 'Greenland Standard Time',
    abbr: 'GDT',
    offset: -3,
    isdst: true,
    text: '(UTC-03:00) Greenland',
    utc: [
      'America/Godthab',
    ],
  },
  {
    value: 'Montevideo Standard Time',
    abbr: 'MST',
    offset: -3,
    isdst: false,
    text: '(UTC-03:00) Montevideo',
    utc: [
      'America/Montevideo',
    ],
  },
  {
    value: 'Bahia Standard Time',
    abbr: 'BST',
    offset: -3,
    isdst: false,
    text: '(UTC-03:00) Salvador',
    utc: [
      'America/Bahia',
    ],
  },
  {
    value: 'UTC-02',
    abbr: 'U',
    offset: -2,
    isdst: false,
    text: '(UTC-02:00) Coordinated Universal Time-02',
    utc: [
      'America/Noronha',
      'Atlantic/South_Georgia',
      'Etc/GMT+2',
    ],
  },
  {
    value: 'Mid-Atlantic Standard Time',
    abbr: 'MDT',
    offset: -1,
    isdst: true,
    text: '(UTC-02:00) Mid-Atlantic - Old',
    utc: [],
  },
  {
    value: 'Azores Standard Time',
    abbr: 'ADT',
    offset: 0,
    isdst: true,
    text: '(UTC-01:00) Azores',
    utc: [
      'America/Scoresbysund',
      'Atlantic/Azores',
    ],
  },
  {
    value: 'Cape Verde Standard Time',
    abbr: 'CVST',
    offset: -1,
    isdst: false,
    text: '(UTC-01:00) Cape Verde Is.',
    utc: [
      'Atlantic/Cape_Verde',
      'Etc/GMT+1',
    ],
  },
  {
    value: 'Morocco Standard Time',
    abbr: 'MDT',
    offset: 1,
    isdst: true,
    text: '(UTC) Casablanca',
    utc: [
      'Africa/Casablanca',
      'Africa/El_Aaiun',
    ],
  },
  {
    value: 'UTC',
    abbr: 'UTC',
    offset: 0,
    isdst: false,
    text: '(UTC) Coordinated Universal Time',
    utc: [
      'America/Danmarkshavn',
      'Etc/GMT',
    ],
  },
  {
    value: 'Greenwich Mean Time',
    abbr: 'GMT',
    offset: 0,
    isdst: false,
    text: '(UTC) Edinburgh, London',
    utc: [
      'Europe/Isle_of_Man',
      'Europe/Guernsey',
      'Europe/Jersey',
      'Europe/London',
    ],
  },
  {
    value: 'British Summer Time',
    abbr: 'BST',
    offset: 1,
    isdst: true,
    text: '(UTC+01:00) Edinburgh, London',
    utc: [
      'Europe/Isle_of_Man',
      'Europe/Guernsey',
      'Europe/Jersey',
      'Europe/London',
    ],
  },
  {
    value: 'GMT Standard Time',
    abbr: 'GDT',
    offset: 1,
    isdst: true,
    text: '(UTC) Dublin, Lisbon',
    utc: [
      'Atlantic/Canary',
      'Atlantic/Faeroe',
      'Atlantic/Madeira',
      'Europe/Dublin',
      'Europe/Lisbon',
    ],
  },
  {
    value: 'Greenwich Standard Time',
    abbr: 'GST',
    offset: 0,
    isdst: false,
    text: '(UTC) Monrovia, Reykjavik',
    utc: [
      'Africa/Abidjan',
      'Africa/Accra',
      'Africa/Bamako',
      'Africa/Banjul',
      'Africa/Bissau',
      'Africa/Conakry',
      'Africa/Dakar',
      'Africa/Freetown',
      'Africa/Lome',
      'Africa/Monrovia',
      'Africa/Nouakchott',
      'Africa/Ouagadougou',
      'Africa/Sao_Tome',
      'Atlantic/Reykjavik',
      'Atlantic/St_Helena',
    ],
  },
  {
    value: 'W. Europe Standard Time',
    abbr: 'WEDT',
    offset: 2,
    isdst: true,
    text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    utc: [
      'Arctic/Longyearbyen',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Berlin',
      'Europe/Busingen',
      'Europe/Gibraltar',
      'Europe/Luxembourg',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Stockholm',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Zurich',
    ],
  },
  {
    value: 'Central Europe Standard Time',
    abbr: 'CEDT',
    offset: 2,
    isdst: true,
    text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    utc: [
      'Europe/Belgrade',
      'Europe/Bratislava',
      'Europe/Budapest',
      'Europe/Ljubljana',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Tirane',
    ],
  },
  {
    value: 'Romance Standard Time',
    abbr: 'RDT',
    offset: 2,
    isdst: true,
    text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    utc: [
      'Africa/Ceuta',
      'Europe/Brussels',
      'Europe/Copenhagen',
      'Europe/Madrid',
      'Europe/Paris',
    ],
  },
  {
    value: 'Central European Standard Time',
    abbr: 'CEDT',
    offset: 2,
    isdst: true,
    text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    utc: [
      'Europe/Sarajevo',
      'Europe/Skopje',
      'Europe/Warsaw',
      'Europe/Zagreb',
    ],
  },
  {
    value: 'W. Central Africa Standard Time',
    abbr: 'WCAST',
    offset: 1,
    isdst: false,
    text: '(UTC+01:00) West Central Africa',
    utc: [
      'Africa/Algiers',
      'Africa/Bangui',
      'Africa/Brazzaville',
      'Africa/Douala',
      'Africa/Kinshasa',
      'Africa/Lagos',
      'Africa/Libreville',
      'Africa/Luanda',
      'Africa/Malabo',
      'Africa/Ndjamena',
      'Africa/Niamey',
      'Africa/Porto-Novo',
      'Africa/Tunis',
      'Etc/GMT-1',
    ],
  },
  {
    value: 'Namibia Standard Time',
    abbr: 'NST',
    offset: 1,
    isdst: false,
    text: '(UTC+01:00) Windhoek',
    utc: [
      'Africa/Windhoek',
    ],
  },
  {
    value: 'GTB Standard Time',
    abbr: 'GDT',
    offset: 3,
    isdst: true,
    text: '(UTC+02:00) Athens, Bucharest',
    utc: [
      'Asia/Nicosia',
      'Europe/Athens',
      'Europe/Bucharest',
      'Europe/Chisinau',
    ],
  },
  {
    value: 'Middle East Standard Time',
    abbr: 'MEDT',
    offset: 3,
    isdst: true,
    text: '(UTC+02:00) Beirut',
    utc: [
      'Asia/Beirut',
    ],
  },
  {
    value: 'Egypt Standard Time',
    abbr: 'EST',
    offset: 2,
    isdst: false,
    text: '(UTC+02:00) Cairo',
    utc: [
      'Africa/Cairo',
    ],
  },
  {
    value: 'Syria Standard Time',
    abbr: 'SDT',
    offset: 3,
    isdst: true,
    text: '(UTC+02:00) Damascus',
    utc: [
      'Asia/Damascus',
    ],
  },
  {
    value: 'E. Europe Standard Time',
    abbr: 'EEDT',
    offset: 3,
    isdst: true,
    text: '(UTC+02:00) E. Europe',
    utc: [
      'Asia/Nicosia',
      'Europe/Athens',
      'Europe/Bucharest',
      'Europe/Chisinau',
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Nicosia',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
    ],
  },
  {
    value: 'South Africa Standard Time',
    abbr: 'SAST',
    offset: 2,
    isdst: false,
    text: '(UTC+02:00) Harare, Pretoria',
    utc: [
      'Africa/Blantyre',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      'Africa/Harare',
      'Africa/Johannesburg',
      'Africa/Kigali',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Maputo',
      'Africa/Maseru',
      'Africa/Mbabane',
      'Etc/GMT-2',
    ],
  },
  {
    value: 'FLE Standard Time',
    abbr: 'FDT',
    offset: 3,
    isdst: true,
    text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    utc: [
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
    ],
  },
  {
    value: 'Turkey Standard Time',
    abbr: 'TDT',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Istanbul',
    utc: [
      'Europe/Istanbul',
    ],
  },
  {
    value: 'Israel Standard Time',
    abbr: 'JDT',
    offset: 3,
    isdst: true,
    text: '(UTC+02:00) Jerusalem',
    utc: [
      'Asia/Jerusalem',
    ],
  },
  {
    value: 'Libya Standard Time',
    abbr: 'LST',
    offset: 2,
    isdst: false,
    text: '(UTC+02:00) Tripoli',
    utc: [
      'Africa/Tripoli',
    ],
  },
  {
    value: 'Jordan Standard Time',
    abbr: 'JST',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Amman',
    utc: [
      'Asia/Amman',
    ],
  },
  {
    value: 'Arabic Standard Time',
    abbr: 'AST',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Baghdad',
    utc: [
      'Asia/Baghdad',
    ],
  },
  {
    value: 'Kaliningrad Standard Time',
    abbr: 'KST',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Kaliningrad, Minsk',
    utc: [
      'Europe/Kaliningrad',
      'Europe/Minsk',
    ],
  },
  {
    value: 'Arab Standard Time',
    abbr: 'AST',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Kuwait, Riyadh',
    utc: [
      'Asia/Aden',
      'Asia/Bahrain',
      'Asia/Kuwait',
      'Asia/Qatar',
      'Asia/Riyadh',
    ],
  },
  {
    value: 'E. Africa Standard Time',
    abbr: 'EAST',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Nairobi',
    utc: [
      'Africa/Addis_Ababa',
      'Africa/Asmera',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Juba',
      'Africa/Kampala',
      'Africa/Khartoum',
      'Africa/Mogadishu',
      'Africa/Nairobi',
      'Antarctica/Syowa',
      'Etc/GMT-3',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte',
    ],
  },
  {
    value: 'Moscow Standard Time',
    abbr: 'MSK',
    offset: 3,
    isdst: false,
    text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
    utc: [
      'Europe/Kirov',
      'Europe/Moscow',
      'Europe/Simferopol',
      'Europe/Volgograd',
    ],
  },
  {
    value: 'Samara Time',
    abbr: 'SAMT',
    offset: 4,
    isdst: false,
    text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
    utc: [
      'Europe/Astrakhan',
      'Europe/Samara',
      'Europe/Ulyanovsk',
    ],
  },
  {
    value: 'Iran Standard Time',
    abbr: 'IDT',
    offset: 4.5,
    isdst: true,
    text: '(UTC+03:30) Tehran',
    utc: [
      'Asia/Tehran',
    ],
  },
  {
    value: 'Arabian Standard Time',
    abbr: 'AST',
    offset: 4,
    isdst: false,
    text: '(UTC+04:00) Abu Dhabi, Muscat',
    utc: [
      'Asia/Dubai',
      'Asia/Muscat',
      'Etc/GMT-4',
    ],
  },
  {
    value: 'Azerbaijan Standard Time',
    abbr: 'ADT',
    offset: 5,
    isdst: true,
    text: '(UTC+04:00) Baku',
    utc: [
      'Asia/Baku',
    ],
  },
  {
    value: 'Mauritius Standard Time',
    abbr: 'MST',
    offset: 4,
    isdst: false,
    text: '(UTC+04:00) Port Louis',
    utc: [
      'Indian/Mahe',
      'Indian/Mauritius',
      'Indian/Reunion',
    ],
  },
  {
    value: 'Georgian Standard Time',
    abbr: 'GST',
    offset: 4,
    isdst: false,
    text: '(UTC+04:00) Tbilisi',
    utc: [
      'Asia/Tbilisi',
    ],
  },
  {
    value: 'Caucasus Standard Time',
    abbr: 'CST',
    offset: 4,
    isdst: false,
    text: '(UTC+04:00) Yerevan',
    utc: [
      'Asia/Yerevan',
    ],
  },
  {
    value: 'Afghanistan Standard Time',
    abbr: 'AST',
    offset: 4.5,
    isdst: false,
    text: '(UTC+04:30) Kabul',
    utc: [
      'Asia/Kabul',
    ],
  },
  {
    value: 'West Asia Standard Time',
    abbr: 'WAST',
    offset: 5,
    isdst: false,
    text: '(UTC+05:00) Ashgabat, Tashkent',
    utc: [
      'Antarctica/Mawson',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Dushanbe',
      'Asia/Oral',
      'Asia/Samarkand',
      'Asia/Tashkent',
      'Etc/GMT-5',
      'Indian/Kerguelen',
      'Indian/Maldives',
    ],
  },
  {
    value: 'Yekaterinburg Time',
    abbr: 'YEKT',
    offset: 5,
    isdst: false,
    text: '(UTC+05:00) Yekaterinburg',
    utc: [
      'Asia/Yekaterinburg',
    ],
  },
  {
    value: 'Pakistan Standard Time',
    abbr: 'PKT',
    offset: 5,
    isdst: false,
    text: '(UTC+05:00) Islamabad, Karachi',
    utc: [
      'Asia/Karachi',
    ],
  },
  {
    value: 'India Standard Time',
    abbr: 'IST',
    offset: 5.5,
    isdst: false,
    text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    utc: [
      'Asia/Kolkata',
    ],
  },
  {
    value: 'Sri Lanka Standard Time',
    abbr: 'SLST',
    offset: 5.5,
    isdst: false,
    text: '(UTC+05:30) Sri Jayawardenepura',
    utc: [
      'Asia/Colombo',
    ],
  },
  {
    value: 'Nepal Standard Time',
    abbr: 'NST',
    offset: 5.75,
    isdst: false,
    text: '(UTC+05:45) Kathmandu',
    utc: [
      'Asia/Katmandu',
    ],
  },
  {
    value: 'Central Asia Standard Time',
    abbr: 'CAST',
    offset: 6,
    isdst: false,
    text: '(UTC+06:00) Astana',
    utc: [
      'Antarctica/Vostok',
      'Asia/Almaty',
      'Asia/Bishkek',
      'Asia/Qyzylorda',
      'Asia/Urumqi',
      'Etc/GMT-6',
      'Indian/Chagos',
    ],
  },
  {
    value: 'Bangladesh Standard Time',
    abbr: 'BST',
    offset: 6,
    isdst: false,
    text: '(UTC+06:00) Dhaka',
    utc: [
      'Asia/Dhaka',
      'Asia/Thimphu',
    ],
  },
  {
    value: 'Myanmar Standard Time',
    abbr: 'MST',
    offset: 6.5,
    isdst: false,
    text: '(UTC+06:30) Yangon (Rangoon)',
    utc: [
      'Asia/Rangoon',
      'Indian/Cocos',
    ],
  },
  {
    value: 'SE Asia Standard Time',
    abbr: 'SAST',
    offset: 7,
    isdst: false,
    text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    utc: [
      'Antarctica/Davis',
      'Asia/Bangkok',
      'Asia/Hovd',
      'Asia/Jakarta',
      'Asia/Phnom_Penh',
      'Asia/Pontianak',
      'Asia/Saigon',
      'Asia/Vientiane',
      'Etc/GMT-7',
      'Indian/Christmas',
    ],
  },
  {
    value: 'N. Central Asia Standard Time',
    abbr: 'NCAST',
    offset: 7,
    isdst: false,
    text: '(UTC+07:00) Novosibirsk',
    utc: [
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Omsk',
    ],
  },
  {
    value: 'China Standard Time',
    abbr: 'CST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    utc: [
      'Asia/Hong_Kong',
      'Asia/Macau',
      'Asia/Shanghai',
    ],
  },
  {
    value: 'North Asia Standard Time',
    abbr: 'NAST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Krasnoyarsk',
    utc: [
      'Asia/Krasnoyarsk',
    ],
  },
  {
    value: 'Singapore Standard Time',
    abbr: 'MPST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Kuala Lumpur, Singapore',
    utc: [
      'Asia/Brunei',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Makassar',
      'Asia/Manila',
      'Asia/Singapore',
      'Etc/GMT-8',
    ],
  },
  {
    value: 'W. Australia Standard Time',
    abbr: 'WAST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Perth',
    utc: [
      'Antarctica/Casey',
      'Australia/Perth',
    ],
  },
  {
    value: 'Taipei Standard Time',
    abbr: 'TST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Taipei',
    utc: [
      'Asia/Taipei',
    ],
  },
  {
    value: 'Ulaanbaatar Standard Time',
    abbr: 'UST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Ulaanbaatar',
    utc: [
      'Asia/Choibalsan',
      'Asia/Ulaanbaatar',
    ],
  },
  {
    value: 'North Asia East Standard Time',
    abbr: 'NAEST',
    offset: 8,
    isdst: false,
    text: '(UTC+08:00) Irkutsk',
    utc: [
      'Asia/Irkutsk',
    ],
  },
  {
    value: 'Japan Standard Time',
    abbr: 'JST',
    offset: 9,
    isdst: false,
    text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    utc: [
      'Asia/Dili',
      'Asia/Jayapura',
      'Asia/Tokyo',
      'Etc/GMT-9',
      'Pacific/Palau',
    ],
  },
  {
    value: 'Korea Standard Time',
    abbr: 'KST',
    offset: 9,
    isdst: false,
    text: '(UTC+09:00) Seoul',
    utc: [
      'Asia/Pyongyang',
      'Asia/Seoul',
    ],
  },
  {
    value: 'Cen. Australia Standard Time',
    abbr: 'CAST',
    offset: 9.5,
    isdst: false,
    text: '(UTC+09:30) Adelaide',
    utc: [
      'Australia/Adelaide',
      'Australia/Broken_Hill',
    ],
  },
  {
    value: 'AUS Central Standard Time',
    abbr: 'ACST',
    offset: 9.5,
    isdst: false,
    text: '(UTC+09:30) Darwin',
    utc: [
      'Australia/Darwin',
    ],
  },
  {
    value: 'E. Australia Standard Time',
    abbr: 'EAST',
    offset: 10,
    isdst: false,
    text: '(UTC+10:00) Brisbane',
    utc: [
      'Australia/Brisbane',
      'Australia/Lindeman',
    ],
  },
  {
    value: 'AUS Eastern Standard Time',
    abbr: 'AEST',
    offset: 10,
    isdst: false,
    text: '(UTC+10:00) Canberra, Melbourne, Sydney',
    utc: [
      'Australia/Melbourne',
      'Australia/Sydney',
    ],
  },
  {
    value: 'West Pacific Standard Time',
    abbr: 'WPST',
    offset: 10,
    isdst: false,
    text: '(UTC+10:00) Guam, Port Moresby',
    utc: [
      'Antarctica/DumontDUrville',
      'Etc/GMT-10',
      'Pacific/Guam',
      'Pacific/Port_Moresby',
      'Pacific/Saipan',
      'Pacific/Truk',
    ],
  },
  {
    value: 'Tasmania Standard Time',
    abbr: 'TST',
    offset: 10,
    isdst: false,
    text: '(UTC+10:00) Hobart',
    utc: [
      'Australia/Currie',
      'Australia/Hobart',
    ],
  },
  {
    value: 'Yakutsk Standard Time',
    abbr: 'YST',
    offset: 9,
    isdst: false,
    text: '(UTC+09:00) Yakutsk',
    utc: [
      'Asia/Chita',
      'Asia/Khandyga',
      'Asia/Yakutsk',
    ],
  },
  {
    value: 'Central Pacific Standard Time',
    abbr: 'CPST',
    offset: 11,
    isdst: false,
    text: '(UTC+11:00) Solomon Is., New Caledonia',
    utc: [
      'Antarctica/Macquarie',
      'Etc/GMT-11',
      'Pacific/Efate',
      'Pacific/Guadalcanal',
      'Pacific/Kosrae',
      'Pacific/Noumea',
      'Pacific/Ponape',
    ],
  },
  {
    value: 'Vladivostok Standard Time',
    abbr: 'VST',
    offset: 11,
    isdst: false,
    text: '(UTC+11:00) Vladivostok',
    utc: [
      'Asia/Sakhalin',
      'Asia/Ust-Nera',
      'Asia/Vladivostok',
    ],
  },
  {
    value: 'New Zealand Standard Time',
    abbr: 'NZST',
    offset: 12,
    isdst: false,
    text: '(UTC+12:00) Auckland, Wellington',
    utc: [
      'Antarctica/McMurdo',
      'Pacific/Auckland',
    ],
  },
  {
    value: 'UTC+12',
    abbr: 'U',
    offset: 12,
    isdst: false,
    text: '(UTC+12:00) Coordinated Universal Time+12',
    utc: [
      'Etc/GMT-12',
      'Pacific/Funafuti',
      'Pacific/Kwajalein',
      'Pacific/Majuro',
      'Pacific/Nauru',
      'Pacific/Tarawa',
      'Pacific/Wake',
      'Pacific/Wallis',
    ],
  },
  {
    value: 'Fiji Standard Time',
    abbr: 'FST',
    offset: 12,
    isdst: false,
    text: '(UTC+12:00) Fiji',
    utc: [
      'Pacific/Fiji',
    ],
  },
  {
    value: 'Magadan Standard Time',
    abbr: 'MST',
    offset: 12,
    isdst: false,
    text: '(UTC+12:00) Magadan',
    utc: [
      'Asia/Anadyr',
      'Asia/Kamchatka',
      'Asia/Magadan',
      'Asia/Srednekolymsk',
    ],
  },
  {
    value: 'Kamchatka Standard Time',
    abbr: 'KDT',
    offset: 13,
    isdst: true,
    text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    utc: [
      'Asia/Kamchatka',
    ],
  },
  {
    value: 'Tonga Standard Time',
    abbr: 'TST',
    offset: 13,
    isdst: false,
    text: '(UTC+13:00) Nuku alofa',
    utc: [
      'Etc/GMT-13',
      'Pacific/Enderbury',
      'Pacific/Fakaofo',
      'Pacific/Tongatapu',
    ],
  },
  {
    value: 'Samoa Standard Time',
    abbr: 'SST',
    offset: 13,
    isdst: false,
    text: '(UTC+13:00) Samoa',
    utc: [
      'Pacific/Apia',
    ],
  },
];
