export const ADD_MERCHANT_MUTATION = (
  `mutation addMerchantMutation($merchantData: MerchantInput) {
      addMerchant(merchant: $merchantData) {
        merchantId
        organisationName 
        merchantAccount 
        conduit
        region 
        acquirer 
        processingTimezone 
      }
    }
  `);


export const CREATE_OR_UPDATE_MERCHANT_MUTATION = (
  `mutation updateMerchantMutation($merchantData: MerchantInput) {
    updateMerchant(merchant: $merchantData) {
      merchantId
      organisationName
      merchantAccount
      conduit
      region
      acquirer
      processingTimezone
    }
  }`
);

export const REMOVE_MERCHANT_MUTATION = () => (
  `mutation removeMerchantMutation($removeMerchant: String!) {
    deleteMerchant(merchantId: $removeMerchant) {
      merchantId
    }
  }`
);
