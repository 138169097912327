// Import external modules
import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Datepicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import { Table, Form, Button, Loader, Dropdown, Input, Pagination, Radio, Icon } from 'semantic-ui-react';

// Import internal modules
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';
import ModalDialog from './refundModal';
import { getStatusFilterDropdownOptionsArray } from './helpers/getStatusFilterDropdownOptionsArray';
import { RadioWrapper } from './styles';
import { TableWrapper } from '../../components/TableWrapper';
import { MainContentWrapper } from '../../components/MainContentWrapper';
import {
  createTransactionsGraphqlQuery,
  createTransactionsSummaryGraphqlQuery,
  createExportTransactionsGraphqlQuery,
  createOrphanedSettlementsSummaryGraphqlQuery,
} from './createTransactionsGraphqlQuery';
import APIService from '../../services/APIService';
import generateModalContent from './generateModalContent';
import graphqlToUiStatus from './graphqlToUiStatus';

// Import constants
import {
  COMPLETED,
  CHECKEXPORTTRANSACTIONSTATUS,
  DEFAULT_NR_OF_TABLE_ROWS,
  DEPLOYMENT_REGION,
  DEPLOYMENT_REGION_US,
  GRAPHQL,
  INITIATEEXPORTTRANSACTION,
  TRANSACTION_STATUS_KEY_AUTHORISED as AUTHORISED,
  TRANSACTION_STATUS_KEY_CLEARED as CLEARED,
  TRANSACTION_STATUS_KEY_SETTLED as SETTLED,
  TRANSACTION_STATUS_KEY_SETTLED_APAC as SETTLED_APAC,
  TRANSACTION_STATUS_KEY_SETTLED_EU as SETTLED_EU,
  TRANSACTION_STATUS_KEY_REFUND as REFUND,
  TRANSACTION_STATUS_KEY_REFUNDED as REFUNDED,
  TRANSACTION_STATUS_KEY_REFUND_REQUESTED as REFUND_REQUESTED,
  TRANSACTION_STATUS_KEY_REFUND_REQUESTED_ERROR as REFUND_REQUESTED_ERROR,
  TRANSACTION_STATUS_KEY_UNKNOWN as UNKNOWN,
  TRANSACTION_STATUS_KEY_DECLINED as DECLINED,
  TRANSACTION_STATUS_KEY_FAILED_CLEARING as FAILED_CLEARING,
  TRANSACTION_STATUS_KEY_FAILED_SETTLEMENT as FAILED_SETTLEMENT,
  TX_STATUS_REFUND,
  EXPORT_STATUS_MAX_REACH,
} from '../App/constants';

//! FIXME: This has grown increasingly complex and should be refactored.
export const STATUS_MAP = { // Exporting to be used in tests.
  [REFUND]: { key: 'refund', value: 'REFUND', text: 'Refund', graphQlEnum: 'REFUND' },
  [REFUNDED]: { key: 'refunded', value: 'REFUNDED', text: 'Refunded', graphQlEnum: 'REFUNDED' },
  [REFUND_REQUESTED]: { key: 'refundRequested', value: 'REFUND_REQUESTED', text: 'Refund Requested', graphQlEnum: 'REFUND_REQUESTED' },
  [REFUND_REQUESTED_ERROR]: { key: 'refundRequestedError', value: 'REFUND_REQUESTED_ERROR', text: 'Refund Requested Error', graphQlEnum: 'REFUND_REQUESTED_ERROR' },
  [AUTHORISED]: { key: 'authorised', value: 'AUTHORISED', text: 'Authorised', graphQlEnum: 'PENDING_CLEARING' },
  [CLEARED]: { key: 'cleared', value: 'CLEARED', text: 'Cleared', graphQlEnum: 'PENDING_SETTLEMENT' },
  [SETTLED]: { key: 'settled', value: 'SETTLED', text: 'Settled', graphQlEnum: 'SETTLED', columnText: 'SETTLED' },
  [SETTLED_APAC]: { key: 'settled (apac)', value: 'SETTLED_APAC', text: 'Settled (Asia-Pac)', graphQlEnum: 'SETTLED', columnText: 'SETTLED' },
  [SETTLED_EU]: { key: 'settled (eu)', value: 'SETTLED_EU', text: 'Settled (Europe)', graphQlEnum: 'PENDING_SETTLEMENT', columnText: 'SETTLED' }, // Temporary hack
  [UNKNOWN]: { key: 'unknown', value: 'UNKNOWN', text: 'Unknown', graphQlEnum: 'UNKNOWN' },
  [DECLINED]: { key: 'declined', value: 'DECLINED', text: 'Declined', graphQlEnum: 'DECLINED' },
  [FAILED_CLEARING]: { key: 'failedClearing', value: 'FAILED_CLEARING', text: 'Failed Clearing', graphQlEnum: 'FAILED_CLEARING' },
  [FAILED_SETTLEMENT]: { key: 'failedSettlement', value: 'FAILED_SETTLEMENT', text: 'Failed Settlement', graphQlEnum: 'FAILED_SETTLEMENT' },
};

/* Module wide constants */
const ERROR_MESSAGE_EXPORT = 'Export failed, please try again!';
const ERROR_MESSAGE_EXPORT_MAX_REACH = 'Export limit exceed, please reduce export size';
const ERROR_MESSAGE_FETCH_SUMMARY = 'Fetching summary failed. Try again or adjust the filter!';
const ERROR_MESSAGE_FETCH_ORPHAN_SUMMARY = 'Fetching adjustment failed. Please try again!';
const ERROR_MESSAGE_FETCH_TRANSACTIONS = 'Fetching transactions failed, please retry!';
const MAXIMUM_CARD_NUMBER_VALUE = 999;
const MINIMUM_CARD_NUMBER_VALUE = 0;

const errorColour = '#ff0000';

const deploymentRegionIsUs = DEPLOYMENT_REGION === DEPLOYMENT_REGION_US;

class TransactionsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      endDate: null,
      loadingOrphanedSettlementsData: false,
      loadingTransactions: false,
      orphanedSettlementsCount: null,
      orphanedSettlementsValue: null,
      startDate: null,
      totalValue: null,
      transactions: null,
      /* Filters */
      acquirerMid: '',
      acquirerTid: '',
      cardNumber: '',
      dateFilterSetToSettlement: false,
      isoEndDate: '',
      isoStartDate: '',
      machineReference: null,
      statusFilterValue: null,
      statusFilterValueAtRefresh: null,
      transactionAmount: null,
      transactionId: '',
      /* Pagination */
      activePage: 1,
      totalPages: null,
      /* Filtering */
      lastSearchFiltering: null,
      /* Export */
      exportInProgress: false,
      exportLink: null,
      /* Errors */
      errorExport: false,
      errorFetchOrphanSummary: false,
      errorFetchSummary: false,
      errorFetchTransactions: false,
    };
    this.openModal = this.openModal.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.updateStatusFilter = this.updateStatusFilter.bind(this);
    this.fetchTransactions = this.fetchTransactions.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleExportDownload = this.handleExportDownload.bind(this);
  }

  componentDidMount() {
    const now = new Date();
    const yesterday = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    yesterday.setHours(0, 0, 0, 0);
    const startDate = new Date(yesterday.getTime());
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    const endDate = new Date(today);
    // because setState is async...
    this.handleStartDate(startDate, () => {
      this.handleEndDate(endDate, () => {
        this.searchTransactions();
      });
    });
  }

  fetchTransactionsSummary = async (filtering) => {
    console.debug('fetchTransactionsSummary', filtering);
    const { history } = this.props;

    const accessToken = window.localStorage.getItem('accessToken');
    const query = createTransactionsSummaryGraphqlQuery(filtering);
    const apiService = new APIService();
    const uri = GRAPHQL;
    const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
    if (result.data) return result.data;
    this.setState({
      errorFetchSummary: true,
    });
    return false;
  }

  fetchOrphanedSettlementsSummary = async (filtering) => {
    console.debug('fetchOrphanedSettlementsSumary', filtering);
    const { history } = this.props;

    const accessToken = window.localStorage.getItem('accessToken');
    const query = createOrphanedSettlementsSummaryGraphqlQuery(filtering);
    const apiService = new APIService();
    const uri = GRAPHQL;
    const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
    if (result.data) return result.data;
    this.setState({
      errorFetchOrphanSummary: true,
    });
    return false;
  }

  exportTransactions = async (format) => {
    const { lastSearchFiltering, statusFilterValueAtRefresh } = this.state;
    const { history } = this.props;
    const accessToken = window.localStorage.getItem('accessToken');
    const considerClearedAsSettled = statusFilterValueAtRefresh === STATUS_MAP.settledEu.value;
    const query = createExportTransactionsGraphqlQuery(lastSearchFiltering, format, considerClearedAsSettled);
    const apiService = new APIService();
    this.setState({
      exportInProgress: true,
      errorExport: false,
    });

    const uri = INITIATEEXPORTTRANSACTION;
    const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
    if (!result || !result.id) {
      this.setState({
        exportInProgress: false,
        errorExport: true,
      });
      return;
    }
    const data = await apiService.checkExportTransactionStatus({ payload: { id: result.id }, accessToken, history, window, uri: CHECKEXPORTTRANSACTIONSTATUS });
    if (data && data.status === COMPLETED) {
      this.setState({
        exportInProgress: false,
      });
      const exportLink = (data) ? data.url : null;
      this.setState({
        exportLink,
      });
    } else {
      this.setState({
        exportInProgress: false,
        errorExport: true,
        exportErrorMessage: data.status === EXPORT_STATUS_MAX_REACH ? ERROR_MESSAGE_EXPORT_MAX_REACH : null,
      });
    }
  }

  fetchTransactions = async (paging, filtering, getSummary) => {
    console.debug('fetchTransactions:', paging, filtering, getSummary);
    const { history } = this.props;
    const accessToken = window.localStorage.getItem('accessToken');
    const query = createTransactionsGraphqlQuery(filtering, paging, getSummary);
    const apiService = new APIService();
    const uri = GRAPHQL;
    const searchResult = await apiService.request({ payload: { query }, accessToken, history, window, uri });
    if (searchResult.data) {
      return searchResult.data;
    }
    this.setState({
      exportInProgress: false,
      loadingTransactions: false,
      errorFetchTransactions: true,
    });
    return false;
  }

  openModal = (data) => {
    const { statusFilterValueAtRefresh } = this.state;
    const uiStatus = graphqlToUiStatus(STATUS_MAP, data.status, statusFilterValueAtRefresh);
    ModalDialog.show({
      title: `Details for transaction id ${data.transactionId}`,
      closeOnClick: false,
      status: data.status,
      refundStatus: data.refundStatus,
      transactionSequenceNumber: data.transactionSequenceNumber,
      content: generateModalContent(data, uiStatus),
    });
  }

  updateTransactionStatus = (status, transactionSequenceNumber) => {
    const { transactions } = this.state;

    const updatedTransaction = transactions.find(transaction => transaction.transactionSequenceNumber === transactionSequenceNumber);
    updatedTransaction.refundStatus = status;

    this.setState({ transactions });
  }

  handleStartDate = (date, cb) => {
    if (date) {
      this.setState({ startDate: date, isoStartDate: date.toISOString() }, cb);
    } else {
      this.setState({ startDate: '', isoStartDate: '' }, cb);
    }
  }

  handleEndDate = (date, cb) => {
    if (date) {
      this.setState({ endDate: date, isoEndDate: date.toISOString() }, cb);
    } else {
      this.setState({ endDate: '', isoEndDate: '' }, cb);
    }
  }

  /* Pagination Handling */
  handlePaginationChange = async (_e, { activePage }) => {
    if (activePage === 0) return;
    const {
      lastSearchFiltering,
    } = this.state;
    this.setState({
      activePage,
      loadingTransactions: true,
    });
    const fetchResult = await this.fetchTransactions({
      first: DEFAULT_NR_OF_TABLE_ROWS,
      //! check +- 1
      skip: DEFAULT_NR_OF_TABLE_ROWS * (activePage - 1),
    }, lastSearchFiltering, false);

    if (!fetchResult) return;

    // Check that user hasn't paged further during await. If so dont' render the returned transactions. If the user is
    // still on the same page render the transactions.
    //! FIXME: If we could cancel the pending request, that would be better. But at least this prevents using the wrong
    // transactions.
    const { activePage: latestActivePage } = this.state;
    if (activePage !== latestActivePage) { return; }

    // Update total pages based on best information (hasNextPage vs summary)
    const { hasNextPage } = fetchResult.transactions.pageInfo;
    const nextPage = hasNextPage ? activePage + 1 : activePage; // Use this pages
    const { totalPages } = this.state; // Get this state here, after await in case it's been changed elsewhere during await.

    this.setState({
      transactions: fetchResult.transactions.edges.map(edge => edge.node),
      loadingTransactions: false,
      totalPages: Math.max(nextPage, totalPages),
    });
  }

  handleChange = (e) => {
    const { name } = e.target;
    this.setState({ [name]: e.target.value });
  }

  handleChangeCardNumber = (e) => {
    const { value } = e.target;
    if (value.indexOf(' ') !== -1) { return; }
    const numericValue = Number(value);
    if (!Number.isNaN(numericValue)
        && numericValue >= MINIMUM_CARD_NUMBER_VALUE
        && numericValue <= MAXIMUM_CARD_NUMBER_VALUE) { this.setState({ cardNumber: value }); }
  }

  handleExportDownload = () => {
    this.setState({
      exportLink: null,
    });
  }

  updateStatusFilter = (_e, { value: statusFilterValue }) => {
    const {
      [SETTLED]: settled,
      [SETTLED_APAC]: settledApac,
      [SETTLED_EU]: settledEu,
      [CLEARED]: cleared,
    } = STATUS_MAP;
    const dateFilterSetToSettlement = [settled.value, settledApac.value, settledEu.value, cleared.value].includes(statusFilterValue);

    return this.setState({ statusFilterValue, dateFilterSetToSettlement });
  }

  setFilterToSettlement = () => (this.setState({ dateFilterSetToSettlement: true }))

  setFilterToTransaction = () => (this.setState({ dateFilterSetToSettlement: false }))

  searchTransactions = async () => {
    const {
      /* Filtering */
      statusFilterValue,
      isoStartDate,
      isoEndDate,
      dateFilterSetToSettlement,
      acquirerMid,
      acquirerTid,
      machineReference,
      transactionId,
      cardNumber,
      transactionAmount,
    } = this.state;

    const shouldLoadOrphanedSettlementsData = statusFilterValue === STATUS_MAP[SETTLED_APAC].value;
    // clear
    this.setState({
      loadingTransactions: true,
      loadingOrphanedSettlementsData: shouldLoadOrphanedSettlementsData,
      transactions: null,
      totalValue: null,
      orphanedSettlementsValue: null,
      count: null,
      orphanedSettlementsCount: null,
      totalPages: null,
      /* Export */
      exportInProgress: null,
      exportLink: null,
      statusFilterValueAtRefresh: statusFilterValue,
      /* Errors */
      errorExport: false,
      errorFetchOrphanSummary: false,
      errorFetchSummary: false,
      errorFetchTransactions: false,
    });

    const filtering = {};
    const statusesUsingClearingDate = [CLEARED, SETTLED_EU]; // FIXME: This should probably be moved to the top of the file or even to constants.
    const filterValuesOfStatusesUsingClearingDate = statusesUsingClearingDate.map(statusKey => STATUS_MAP[statusKey].value);

    // ???: Is the below way of doing this good for readability? Or just confusing?
    const shouldFilterByClearingDate = dateFilterSetToSettlement && filterValuesOfStatusesUsingClearingDate.includes(statusFilterValue);
    const shouldFilterBySettlementDate = !shouldFilterByClearingDate && dateFilterSetToSettlement;
    const shouldFilterByTransactionDate = !shouldFilterByClearingDate && !shouldFilterBySettlementDate;

    if (shouldFilterByClearingDate) {
      filtering.startClearingDate = isoStartDate;
      filtering.endClearingDate = isoEndDate;
    }
    if (shouldFilterBySettlementDate) {
      filtering.startSettlementDate = isoStartDate;
      filtering.endSettlementDate = isoEndDate;
    }
    if (shouldFilterByTransactionDate) {
      filtering.startTransactionDate = isoStartDate;
      filtering.endTransactionDate = isoEndDate;
    }

    // TODO: you know what to do
    if (statusFilterValue) {
      filtering.status = Object.values(STATUS_MAP).find(status => status.value === statusFilterValue).graphQlEnum;
    }
    if (acquirerMid) filtering.acquirerMid = acquirerMid;
    if (acquirerTid) filtering.acquirerTid = acquirerTid;
    if (machineReference) { filtering.machineReference = machineReference; }
    if (transactionId) filtering.transactionId = transactionId;
    if (cardNumber) filtering.cardNumber = cardNumber;
    if (transactionAmount) filtering.amount = transactionAmount;
    if (cardNumber) { filtering.cardNumber = cardNumber; }

    const fetchResult = await this.fetchTransactions({
      first: DEFAULT_NR_OF_TABLE_ROWS,
      //! not sure with passing undefined
      // after: endCursor || undefined,
    }, filtering, false);

    if (!fetchResult) return;

    const transactionsData = fetchResult.transactions;

    // Update paging based on returned results
    const transactionsExist = transactionsData.edges.length > 0;
    const { hasNextPage } = transactionsData.pageInfo;

    this.setState({
      lastSearchFiltering: filtering,
      transactions: transactionsData.edges.map(edge => edge.node),
      loadingTransactions: false,
      activePage: !transactionsExist ? 0 : 1,
      totalPages: !transactionsExist ? 0 : !hasNextPage ? 1 : 2,
    });

    const { transactionSummary } = await this.fetchTransactionsSummary(filtering);
    if (!transactionSummary) { return; }

    this.setState({
      totalPages: Math.ceil(transactionSummary.numberOfTransactions / DEFAULT_NR_OF_TABLE_ROWS),
      count: transactionSummary.numberOfTransactions,
      totalValue: transactionSummary.valueOfTransactions,
    });

    if (!shouldLoadOrphanedSettlementsData) { return; }
    const { orphanedSettlementsSummary } = await this.fetchOrphanedSettlementsSummary(filtering);
    if (!orphanedSettlementsSummary) { return; }

    this.setState({
      orphanedSettlementsCount: orphanedSettlementsSummary.numberOfTransactions,
      orphanedSettlementsValue: orphanedSettlementsSummary.valueOfTransactions,
      loadingOrphanedSettlementsData: false,
    });
  }

  displayTransactions = () => {
    const { transactions, statusFilterValueAtRefresh } = this.state;
    const rowStyle = { cursor: 'pointer' };
    const refundRowStyle = _.extend({}, rowStyle, { boxShadow: 'inset 3px 0px 0 0 red' });
    const isRefundTx = transaction => transaction.refundStatus || transaction.status === TX_STATUS_REFUND;
    return (
      <React.Fragment>
        {transactions.map((transaction, index) => (
          <Table.Row data={index} key={transaction.transactionId} style={isRefundTx(transaction) ? refundRowStyle : rowStyle} onClick={() => this.openModal(transaction)}>
            <Table.Cell>{transaction.acquirerMerchantId}</Table.Cell>
            <Table.Cell>{transaction.acquirerTerminalId}</Table.Cell>
            <Table.Cell>{transaction.transactionId}</Table.Cell>
            <Table.Cell>{transaction.transactionDate ? new Date(transaction.transactionDate).toLocaleString() : null}</Table.Cell>
            <Table.Cell>
              { [STATUS_MAP.cleared.value, STATUS_MAP.settledEu.value].includes(statusFilterValueAtRefresh) || deploymentRegionIsUs
                ? transaction.clearingDate ? new Date(transaction.clearingDate).toLocaleDateString() : null
                : transaction.settlementDate ? new Date(transaction.settlementDate).toLocaleDateString() : null
              }
            </Table.Cell>
            <Table.Cell>{graphqlToUiStatus(STATUS_MAP, transaction.status, statusFilterValueAtRefresh)}</Table.Cell>
            <Table.Cell>{transaction.machineReference}</Table.Cell>
            <Table.Cell>{(transaction.amount / 100).toFixed(2)}</Table.Cell>
            <Table.Cell>{transaction.currency}</Table.Cell>
            <Table.Cell>{transaction.cardNumber}</Table.Cell>
            <Table.Cell className={`cardType ${transaction.cardType ? transaction.cardType.toLowerCase() : ''}`}><div></div></Table.Cell>
          </Table.Row>
        ))}
      </React.Fragment>
    );
  }

  isExportLimitReach = () => {
    const { count } = this.state;
    const exportLimit = window.localStorage.getItem('exportLimit');
    return count > exportLimit;
  }

  generateExportButton = () => {
    const {
      transactions,
      loadingTransactions,
      errorFetchTransactions,
      errorFetchSummary,
      count,
    } = this.state;
    const exportLimit = window.localStorage.getItem('exportLimit');
    const isNoTx = !transactions || transactions.length === 0;
    const isError = errorFetchTransactions || errorFetchSummary;
    const isLoading = loadingTransactions || count === null;
    const isExportMaxReach = this.isExportLimitReach();
    const isDisabled = isNoTx
      || isLoading
      || isError
      || isExportMaxReach;
    let tooltipMessage = '';
    if (isNoTx || errorFetchTransactions) tooltipMessage = 'No transaction to export';
    if (isError) tooltipMessage = 'Error occurs, please retry';
    if (isLoading) tooltipMessage = 'Waiting on data results';
    if (isExportMaxReach) tooltipMessage = `Export limit: ${exportLimit}`;
    const tooltipDiv = isDisabled ? (
      <ReactTooltip id="export-tip" place="top" type="dark" effect="float">
        <span>{tooltipMessage}</span>
      </ReactTooltip>
    ) : null;
    return (
      <Button.Group>
        {tooltipDiv}
        <Button
          data-tip
          data-for="export-tip"
          onClick={isDisabled ? null : () => this.exportTransactions('csv')}
          style={{ borderRadius: 4, cursor: isDisabled ? 'not-allowed' : '', opacity: isDisabled ? '.5' : '' }}
        >
          Export
        </Button>
      </Button.Group>
    );
  }

  render() {
    const {
      transactions,
      loadingTransactions,
      startDate,
      endDate,
      totalValue,
      count,
      orphanedSettlementsCount,
      orphanedSettlementsValue,
      loadingOrphanedSettlementsData,
      statusFilterValue,
      statusFilterValueAtRefresh,
      cardNumber,
      /* Pagination */
      activePage,
      totalPages,
      /* Export */
      exportInProgress,
      exportLink,
      // dateFilterSetToSettlement,
      exportErrorMessage,
      /* Errors */
      errorExport,
      errorFetchOrphanSummary,
      errorFetchSummary,
      errorFetchTransactions,
      dateFilterSetToSettlement,
    } = this.state;

    const isExportMaxReach = this.isExportLimitReach();
    const shouldShowSummary = !errorFetchTransactions && !errorFetchSummary;
    const shouldShowOrphanedSummaryBasedOnRequest = (loadingOrphanedSettlementsData || orphanedSettlementsCount != null || orphanedSettlementsValue != null);
    const shouldShowOrphanedSummary = (shouldShowSummary && shouldShowOrphanedSummaryBasedOnRequest && !errorFetchOrphanSummary);
    const shouldShowOrphanedSummaryError = shouldShowSummary && shouldShowOrphanedSummaryBasedOnRequest && errorFetchOrphanSummary;

    return (
      <DashboardWrapper>
        <ModalDialog updateTransactionStatus={this.updateTransactionStatus} />
        <Sidebar count={count} totalValue={totalValue}>
          <Form className="block">
            <RadioWrapper>
              <Radio
                onChange={this.setFilterToTransaction}
                name="transactionType"
                value="transaction"
                checked={!dateFilterSetToSettlement}
                label="Transaction"
              />
              <Radio
                style={{ marginLeft: 30 }}
                name="transactionType"
                value="settlement"
                onChange={this.setFilterToSettlement}
                checked={dateFilterSetToSettlement}
                label={statusFilterValue === STATUS_MAP.cleared.value || deploymentRegionIsUs ? 'Clearing' : 'Settlement'}
              />
            </RadioWrapper>
            <Form.Field>
              <label style={{ position: 'relative' }} htmlFor="input-start-date">
                Start Date
                <Icon style={{ position: 'absolute', zIndex: '999', fontSize: 20, left: 5, top: 32 }} name="calendar alternate" />
              </label>
              <Datepicker
                id="input-start-date"
                selected={startDate}
                onChange={e => this.handleStartDate(e)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy h:mm aa"
                timeCaption="time"
                strictParsing
                style={{ paddingLeft: 35, zIndex: 3 }}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ position: 'relative' }} htmlFor="input-end-date">
                End Date
                <Icon style={{ position: 'absolute', zIndex: 2, fontSize: 20, left: 5, top: 32 }} name="calendar alternate" />
              </label>
              <Datepicker
                id="input-end-date"
                selected={endDate}
                onChange={e => this.handleEndDate(e)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy h:mm aa"
                timeCaption="time"
                style={{ zIndex: 1 }}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                placeholder="Select status"
                clearable
                fluid
                search
                selection
                options={getStatusFilterDropdownOptionsArray(STATUS_MAP)}
                value={statusFilterValue}
                onChange={this.updateStatusFilter}
                className="till-font"
              />
            </Form.Field>
            <Form.Field>
              <Input className="till-font" name="acquirerMid" placeholder="Bank MID" onChange={e => this.handleChange(e)} />
            </Form.Field>
            <Form.Field>
              <Input className="till-font" name="acquirerTid" placeholder="Bank TID" onChange={e => this.handleChange(e)} />
            </Form.Field>
            <Form.Field>
              <Input className="till-font" name="machineReference" placeholder="Machine Reference" onChange={e => this.handleChange(e)} />
            </Form.Field>
            <Form.Field>
              <Input className="till-font" name="transactionId" placeholder="Transaction ID" onChange={e => this.handleChange(e)} />
            </Form.Field>
            <Form.Field>
              <Input className="till-font" name="transactionAmount" placeholder="Transaction Amount" type="number" step="0.01" min="0" onChange={e => this.handleChange(e)} />
            </Form.Field>
            <Form.Field>
              <Input className="till-font" name="cardNumber" placeholder="Card Number (last 3 digits)" onChange={e => this.handleChangeCardNumber(e)} value={cardNumber} />
            </Form.Field>
            <div className="actions">
              <Button.Group>
                <Button
                  onClick={() => this.searchTransactions()}
                  style={{ background: '#f9581f', color: 'white' }}
                >
                  Refresh
                </Button>
              </Button.Group>
              <div style={{ height: '10px' }} />
              <Button.Group>
                {exportLink ? (
                  <Button
                    onClick={() => this.handleExportDownload()}
                    href={exportLink}
                    download
                    style={{ background: '#f9581f', color: 'white' }}
                  >
                    Download
                  </Button>
                ) : exportInProgress ? (
                  <Button loading disabled>Exporting</Button>
                ) : this.generateExportButton()
                }
              </Button.Group>
            </div>
            <div>
              { errorExport ? (
                <div loading style={{ color: errorColour, marginBottom: '10px' }}>
                  { exportErrorMessage || ERROR_MESSAGE_EXPORT }
                  <br />
                </div>
              ) : null
              }
              { !shouldShowSummary ? (
                <span loading style={{ color: errorColour }}>
                  {ERROR_MESSAGE_FETCH_SUMMARY}
                  <br />
                </span>
              ) : null
              }
              { shouldShowSummary ? (
                <>
                  { isExportMaxReach ? (
                    <>
                      <span style={{ color: 'red' }}>
                        Too many records to export, please narrow down your search criterias
                      </span>
                      <br />
                    </>
                  ) : null }
                  <span>
                    Report Count:&nbsp;
                    {count != null ? count : 'Estimating...'}
                  </span>
                  <br />
                  <span>
                    Report Value:&nbsp;
                    { totalValue != null ? (totalValue / 100).toFixed(2) : 'Estimating...'}
                  </span>
                </>
              ) : null
              }
            </div>
            { shouldShowOrphanedSummaryError ? (
              <span loading style={{ color: errorColour }}>
                {ERROR_MESSAGE_FETCH_ORPHAN_SUMMARY}
                <br />
              </span>
            ) : null
            }
            { shouldShowOrphanedSummary ? (
              <div>
                <span>
                  Adjusted Count:&nbsp;
                  {loadingOrphanedSettlementsData ? 'Estimating...' : orphanedSettlementsCount || 0}
                </span>
                <br />
                <span>
                  Adjusted Value:&nbsp;
                  {loadingOrphanedSettlementsData ? 'Estimating...' : (orphanedSettlementsValue / 100).toFixed(2)}
                </span>
              </div>
            ) : null }
            { shouldShowOrphanedSummary ? (
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  Total Count:&nbsp;
                  {loadingOrphanedSettlementsData ? 'Estimating...' : count + orphanedSettlementsCount}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Total Value:&nbsp;
                  {loadingOrphanedSettlementsData ? 'Estimating...' : ((totalValue + orphanedSettlementsValue) / 100).toFixed(2)}
                </span>
              </div>
            ) : null }
          </Form>
        </Sidebar>
        <MainContentWrapper>
          {/* Pagination */}
          <aside className="pag-wrapper">
            <Pagination
              activePage={activePage || 0}
              totalPages={totalPages || 0}
              ellipsisItem={null}
              lastItem={null}
              firstItem={null}
              boundaryRange={0}
              siblingRange={0}
              size="mini"
              onPageChange={this.handlePaginationChange}
              style={{ marginBottom: '10px' }}
            />
          </aside>
          <TableWrapper>
            <Table textAlign="center" singleLine striped selectable style={{ width: '100%' }}>
              <Table.Header className="till-font">
                <Table.Row>
                  <Table.HeaderCell>Bank MID</Table.HeaderCell>
                  <Table.HeaderCell>Bank TID</Table.HeaderCell>
                  <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                  <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                  <Table.HeaderCell>{statusFilterValueAtRefresh === STATUS_MAP.cleared.value || deploymentRegionIsUs ? 'Clearing Date' : 'Settlement Date'}</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Machine</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Currency</Table.HeaderCell>
                  <Table.HeaderCell>Card</Table.HeaderCell>
                  <Table.HeaderCell>Card Type</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {transactions && !loadingTransactions && !errorFetchTransactions ? (
                <Table.Body>
                  {this.displayTransactions()}
                </Table.Body>
              ) : null
              }
            </Table>
          </TableWrapper>
          { errorFetchTransactions ? (
            <div style={{ textAlign: 'center', margin: '10vw', color: errorColour, fontSize: '1.2em', fontWeight: 'bold' }}>
              {ERROR_MESSAGE_FETCH_TRANSACTIONS}
            </div>
          ) : (
            null
          )}
          {loadingTransactions ? <Loader className="table-loader" size="medium" active> Loading </Loader> : null}
        </MainContentWrapper>
      </DashboardWrapper>
    );
  }
}

TransactionsDashboard.propTypes = {
  history: PropTypes.any,
};

export default withRouter(TransactionsDashboard);
