import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup, Button } from 'semantic-ui-react';
import ConfirmActionPrompt from '../ConfirmActionPrompt';

class ConfirmActionPopupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  async handleSubmit() {
    const { submit } = this.props;
    try {
      await submit();
    } catch (err) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { triggerText, loading, contentText } = this.props;
    const { isOpen } = this.state;
    return (
      <Popup
        open={isOpen}
        onOpen={() => this.setState({ isOpen: true })}
        onClose={() => this.setState({ isOpen: false })}
        trigger={<Button style={{ float: 'right' }} size="mini">{ triggerText }</Button>}
        content={(
          <ConfirmActionPrompt
            text={contentText}
            submit={() => this.handleSubmit()}
            cancel={() => this.setState({ isOpen: false })}
            loading={loading}
          />
        )}
        on="click"
        position="left center"
      />
    );
  }
}

ConfirmActionPopupButton.propTypes = {
  triggerText: PropTypes.string,
  submit: PropTypes.func,
  loading: PropTypes.bool,
  contentText: PropTypes.string,
};

export default ConfirmActionPopupButton;
