import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Popup, Button } from 'semantic-ui-react';
import ModalDialog from '../../components/Modal';
import Modal from '../../components/Modal/styles';
import APIService from '../../services/APIService';
import { REFUND_REQUESTED, REFUND_PERMISSION, TX_STATUS_REFUND, RECAPTCHA_SITE_KEY, REFUND_REQUESTED_ERROR } from '../App/constants';

const TRANSACTION_STATUSES_REQUIRED_FOR_REFUND = [
  'PENDING_SETTLEMENT',
  'SETTLED',
];
export default class RefundModalDialog extends ModalDialog {
  constructor() {
    super();
    this.state = Object.assign(
      this.state,
      {
        gToken: null,
        recaptchaState: false,
      },
    );
  }

  transactionRefund = async (data) => {
    const { gToken } = this.state;
    const { updateTransactionStatus } = this.props;

    this.setState({ loading: true });

    const { history } = this.props;
    const accessToken = window.localStorage.getItem('accessToken');
    const apiService = new APIService();
    const uri = 'transaction-refund-request';

    const refund = await apiService.request({ payload: { transactionSequenceNumber: data.transactionSequenceNumber, gToken }, accessToken, history, window, uri });
    const refundStatus = refund.status && refund.status.toUpperCase();
    const isRefundRequestOk = refundStatus === REFUND_REQUESTED;
    if (isRefundRequestOk) updateTransactionStatus(REFUND_REQUESTED, data.transactionSequenceNumber);
    this.setState({
      loading: false,
      refundMessage: refund.message,
      messagecolor: isRefundRequestOk ? 'green' : 'red',
      refunded: isRefundRequestOk,
    });
  }

  render() {
    const {
      visible,
      data,
      loading,
      refunded,
      refundMessage,
      messagecolor,
      gToken,
    } = this.state;
    const permissions = window.localStorage.getItem('permissions');
    const userHasRefundPermission = permissions && permissions.includes(REFUND_PERMISSION);
    const refundStatusAllowRefund = !data.refundStatus || data.refundStatus === REFUND_REQUESTED_ERROR;
    const isRefundTx = data.status === TX_STATUS_REFUND;
    const isRefundable = TRANSACTION_STATUSES_REQUIRED_FOR_REFUND.includes(data.status); // Refund only works on transactions that have cleared. The backend verifies this before attempting a refund
    if (!visible) return null;
    return (
      <Modal className="modal" onClick={this.modalClick}>
        <div className="dialog">
          <div className="dialog-title">
            {data.title}
            <span className="dialog-close" onClick={this.close}> {/* eslint-disable-line */}
              +
            </span>
          </div>
          {userHasRefundPermission && refundStatusAllowRefund && !isRefundTx
            ? isRefundable
              ? (
                <Popup
                  trigger={<Button style={{ border: '2px solid #f9581f', color: '#f9581f', background: 'white' }} color="orange" onClick={() => this.setState({ gToken: false })} compact size="tiny" content="Refund this item" />}
                  content={([
                    <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={value => this.setState({ gToken: value, recaptchaState: true })} />,
                    <Button
                      style={{ background: '#f9581f', color: 'white' }}
                      fluid
                      color="orange"
                      loading={loading}
                      disabled={loading || refunded || !gToken}
                      content="Confirm refund"
                      onClick={() => this.transactionRefund(data)}
                    />,
                    <div className="dialog-content">
                      <div className="item" id="error"><span style={{ color: messagecolor }} position="left center">{refundMessage}</span></div>
                    </div>,
                  ])}
                  on="click"
                  position="left center"
                />
              )
              : (
                <Popup
                  trigger={<Button style={{ border: '2px solid #f9581f', color: '#f9581f', background: 'white' }} color="orange" compact size="tiny" content="Refund this item" />}
                  content="Transaction can not be refunded until it has cleared."
                ></Popup>
              )
            : null}
          <div className="dialog-content">
            {data.content}
          </div>
        </div>
      </Modal>
    );
  }

  close() {
    super.close();
    this.setState({
      loading: false,
      refunded: false,
      refundMessage: '',
    });
  }
}
