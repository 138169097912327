import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Button, Loader } from 'semantic-ui-react';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitHandler = (event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  }

  render() {
    const { username, password } = this.state;
    const { disabled, error } = this.props;
    return (
      <React.Fragment>
        <Form className="login" onSubmit={this.submitHandler} size="large" style={{ padding: '25px' }}>
          <p style={{ marginTop: 8, marginBottom: 20, fontFamily: '"Roboto",sans-serif', fontSize: 16, fontWeight: 300 }}>Enter your credentials</p>
          {error && <p style={{ color: '#a94442', padding: '10px', border: '1px solid #ebccd1', background: '#f2dede', borderRadius: '4px' }}>{error}</p> }
          <label style={{ fontWeight: 'lighter', fontFamily: '"Roboto", Arial, Sans-serif' }}>Username</label>
          <Form.Input id="username" style={{ marginBottom: 25, caretColor: '#f9581f' }} fluid name="username" value={username} type="text" onChange={this.handleChange} />
          <label style={{ fontWeight: 'lighter', fontFamily: '"Roboto", Arial, Sans-serif' }}>Password</label>
          <Form.Input id="password" style={{ caretColor: '#f9581f' }} fluid name="password" value={password} type="password" onChange={this.handleChange} />
          <Button
            disabled={disabled || !username || !password}
            style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', marginTop: '25px', fontSize: 16 }}
            fluid
            size="large"
          >
            Sign In
            {disabled && <Loader size="small" active />}
          </Button>
          <Link to="/forgot" className="link-form" style={{ fontFamily: "'Roboto', Tahoma, Arial", color: '#f9581f' }}>
            Forgot password?
          </Link>
        </Form>
      </React.Fragment>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default withRouter(connect()(LoginForm));
