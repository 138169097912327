import * as actionTypes from './types';

export const setUser = (user) => { // eslint-disable-line arrow-body-style
  return {
    type: actionTypes.SET_USER,
    payload: {
      user,
    },
  };
};

export const clearUser = () => { // eslint-disable-line arrow-body-style
  return {
    type: actionTypes.CLEAR_USER,
  };
};
