import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';
import FeedbackMessage from '../FeedbackMessage';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitHandler = event => { //eslint-disable-line
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  }

  render() {
    const { username } = this.state;
    const { isLoading, error, status } = this.props;
    console.log(error, status);
    return (
      <>
        <Form className="login" onSubmit={this.submitHandler} size="large">
          <p>Request a reset password email</p>
          {error && <FeedbackMessage message={error} status={status} /> }
          <label>username</label>
          <Form.Input id="username" fluid name="username" value={username} type="text" icon="user" iconPosition="left" placeholder="user" onChange={this.handleChange} />
          {isLoading ? (
            <Button style={{ color: 'white', background: '#f9581f' }} fluid size="large" loading>Please wait</Button>
          ) : (
            <Button style={{ color: 'white', background: '#f9581f' }} fluid size="large">
              Request Email
            </Button>
          )}
          <Link to="/" className="link-form">
            &lt; Login
          </Link>
        </Form>
      </>
    );
  }
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.any,
  error: PropTypes.string,
  status: PropTypes.bool,
};

export default withRouter(connect()(ResetPasswordForm));
