import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  background: url('/assets/till-background.jpg');
  background-size: 100%;
  // background-position: 175%;
  img.logo {
    width:93px;
    margin-top:36px;
    margin-bottom:36px;
    margin-right:4px;
  }
  form {
    background: white;
    box-shadow: 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
    padding: 30px;
    border-radius: 5px;
    float:left;
    width:100%;
    label {
      font-size: 15px;
      display:block;
      text-align:left;
      margin-bottom:5px;
    }
    #username, #password {
      font-size: 13px;
      height:40px;
      &:focus {
        border:1px solid #f9581f;
      }
    }
    .link-form {
      font-size: 13px;
      float:right;
      margin-top:15px;
    }
  }
`;
