export const ADD_TERMINAL_MUTATION = () => (
  `mutation addTerminalMutation($newTerminal: TerminalInput) {
      addTerminal(terminal: $newTerminal) {
        terminalId
        merchantId
        terminalDesignator
      }
    }
  `
);

export const UPDATE_TERMINAL_MUTATION = () => (
  `mutation addTerminalMutation($updateTerminal: TerminalInput) {
    updateTerminal(terminal: $updateTerminal) {
      terminalId
      merchantId
      terminalDesignator
    }
  }`
);

export const REMOVE_TERMINAL_MUTATION = () => (
  `mutation RemoveTerminalMutation($removeTerminal: String!) {
    deleteTerminal(terminalId: $removeTerminal) {
      terminalId
      merchantId
      terminalDesignator
    }
  }`
);
