import React, { Component } from 'react';
import faker from 'faker';
import { Form, Table, Button, Input, Modal, Dropdown } from 'semantic-ui-react';
import { Sidebar } from '../../components/Sidebar';
import { DashboardWrapper } from '../../components/DashboardWrapper';
import Steps from '../../components/Steps';
// import PageTitle from '../../components/PageTitle';
import ModalDialog from '../../components/Modal';

const mockData = [
  {
    email: faker.internet.email(),
    password: faker.internet.domainName(),
    username: faker.internet.userName(),
    domain: faker.internet.domainName(),
    role: faker.name.jobTitle(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    phone: faker.random.number(),
    mid: faker.random.number(),
  },
];

const midOpts = [
  { key: faker.random.number(), text: faker.random.number(), value: faker.random.number() },
  { key: faker.random.number(), text: faker.random.number(), value: faker.random.number() },
  { key: faker.random.number(), text: faker.random.number(), value: faker.random.number() },
  { key: faker.random.number(), text: faker.random.number(), value: faker.random.number() },
];

class UsersDashboard extends Component {
  constructor(props) {
    super(props);
    this.buildSideBar = this.buildSideBar.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openAddUserModal = this.openAddUserModal.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.state = {
      addUsermodal: false,
      stepDisplay: 1,
      totalSteps: 2,
    };
  }

  openAddUserModal = () => this.setState({ addUsermodal: true });

  modalClose = () => this.setState({ addUsermodal: false });

  nextStep = () => {
    const { stepDisplay, totalSteps } = this.state;
    if (stepDisplay + 1 <= totalSteps) {
      this.setState({ stepDisplay: stepDisplay + 1 });
    }
  }

  displayModal = () => {
    const { addUsermodal, stepDisplay, totalSteps } = this.state;
    return (
      <Modal size="large" open={addUsermodal} onClose={this.close}>
        <Modal.Header>Add a new user</Modal.Header>
        <Modal.Content>
          <Steps steps={totalSteps} validated={stepDisplay}></Steps>
          <Form style={{ marginTop: '20px' }}>
            {this.renderSteps()}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {stepDisplay > 1 && <Button onClick={() => this.setState({ stepDisplay: stepDisplay - 1 })}>Previous</Button>}
          <Button color="orange" onClick={() => this.nextStep()}>Next</Button>
          <Button onClick={() => this.modalClose()}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderSteps = () => {
    const { stepDisplay } = this.state;
    switch (stepDisplay) {
      case 1: return (
        <div>
          <Form.Field>
            <label>Username</label>
            <input placeholder="Username" />
          </Form.Field>
          <Form.Field>
            <label>Domain</label>
            <Dropdown
              placeholder="Select Domain"
              fluid
              search
              selection
              options={[{ key: '1', value: '1', text: 'first domain' }]}
            />
          </Form.Field>
        </div>
      );
      case 2: return (
        <div>
          <Form.Group>
            <Form.Input width={6} label="First name" placeholder="First name" />
            <Form.Input width={4} label="Last name" placeholder="Last name" />
            <Form.Input width={6} label="Email" placeholder="Email" />
          </Form.Group>
          <Form.Group>
            <Form.Input width={8} label="Role" placeholder="Email" />
            <div className="field eight wide">
              <label>Bank MID</label>
              <Dropdown placeholder="Bank MID" fluid search multiple selection options={midOpts} />
            </div>
          </Form.Group>
        </div>
      );
      default: break;
    }
    return true;
  }

  openEditModal = () => {
    ModalDialog.show({
      title: 'Update user',
      closeOnClick: false,
      hideCloseBtn: true,
      additionalButton: true,
      additionalButtonText: 'Remove user xyz',
      additionalButtonConfirm: 'Are you sure you want to remove user xyz ?',
      content: (
        <Form style={{ marginTop: '20px' }}>
          <Form.Group>
            <Form.Input width={8} label="User Name" placeholder="User Name" />
            <Form.Input width={4} label="First name" placeholder="First name" />
            <Form.Input width={4} label="Last name" placeholder="Last name" />
          </Form.Group>
          <Form.Group>
            <Form.Input width={8} label="Email" placeholder="Email" />
            <Form.Input width={8} label="Role" placeholder="Role" />
          </Form.Group>
          <Form.Group>
            <Form.Input width={6} label="Phone" placeholder="Phone" />
            <div className="field ten wide">
              <label>MID</label>
              <Dropdown placeholder="MID" fluid search multiple selection options={midOpts} />
            </div>
          </Form.Group>
          <Button color="orange" onClick={() => console.log('update')}>Update</Button>
          <Button onClick={() => ModalDialog.hide()}>Cancel</Button>
        </Form>
      ),
    });
  }

  buildSideBar = () => (
    <Sidebar>
      <Form className="block">
        <Form.Field>
          <label>Username</label>
          <Input placeholder="Username" />
        </Form.Field>
        <Form.Field>
          <label>Domain</label>
          <Input placeholder="Domain" />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Input placeholder="Email" />
        </Form.Field>
        <Form.Field>
          <label>Role</label>
          <Input placeholder="Role" />
        </Form.Field>
        <Form.Field>
          <label>Bank MID</label>
          <Input placeholder="Bank MID" />
        </Form.Field>
      </Form>
      <div className="actions">
        <Button.Group>
          <Button color="orange">Refresh</Button>
        </Button.Group>
      </div>
    </Sidebar>
  );

  render() {
    return (
      <DashboardWrapper>
        <ModalDialog />
        {this.buildSideBar()}
        {this.displayModal()}
        <div style={{ flexGrow: '1', position: 'relative' }}>
          <Button color="orange" style={{ position: 'absolute', right: '0' }} onClick={() => this.openAddUserModal()}>New User</Button>
          {/* <PageTitle>Users Dashboard</PageTitle> */}
          <Table striped selectable style={{ width: '100%' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Domain</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Bank MID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {mockData.map(item => (
                <Table.Row onClick={() => this.openEditModal()} data={`${item.username}`} key={`${item.username}`}>
                  <Table.Cell>{item.username}</Table.Cell>
                  <Table.Cell>{`${item.firstName} ${item.lastName}`}</Table.Cell>
                  <Table.Cell>{item.domain}</Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  <Table.Cell>{item.role}</Table.Cell>
                  <Table.Cell>{item.mid}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </DashboardWrapper>
    );
  }
}

export default UsersDashboard;
