export const ADD_DOMAIN_MUTATION = (
  `mutation addDomainMutation($newDomain: DomainInput) {
      addDomain(domain: $newDomain) {
        domain
        organisationName
        address {
          address1
          address2
          locality
          postcode
          state
          country
        }
        assignableActions
        assignableMerchants
        status
        creationDate
        createdBy
      }
    }
  `);


export const UPDATE_DOMAIN_MUTATION = () => (
  `mutation updateDomainMutation($updateDomain: DomainInput) {
    updateDomain(domain: $updateDomain) {
      domain
      organisationName
      address {
        address1
        address2
        locality
        postcode
        state
        country
      }
      status
    }
  }`
);

export const REMOVE_DOMAIN_MUTATION = () => (
  `mutation removeDomainMutation($removeDomain: String!) {
    deleteDomain(domain: $removeDomain) {
      domain
    }
  }`
);
