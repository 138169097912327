import React from 'react';
import PropTypes from 'prop-types';
import FeedbackMessageWrapper from './styles';

const FeedbackMessage = ({ message, status }) => (
  <FeedbackMessageWrapper>
    <p className={status ? 'positive' : 'negative'}>{message}</p>
  </FeedbackMessageWrapper>
);

FeedbackMessage.propTypes = {
  message: PropTypes.string,
  status: PropTypes.bool,
};

export default FeedbackMessage;
