import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Tab } from 'semantic-ui-react';

class AddDomainModalDomainTab extends Component {
  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
    this.submitNewDomain = this.submitNewDomain.bind(this);
    this.state = {
      domainName: '',
      organisationName: '',
      street: '',
      town: '',
      postcode: '',
      state: '',
      country: '',
    };
  }

  inputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  submitNewDomain = () => {
    const { onSubmit } = this.props;
    onSubmit(this.state);
  }

  render() {
    const { domainName, organisationName, street, town, postcode, state, country } = this.state;
    const { onCancel, loading } = this.props;
    return (
      <Tab.Pane style={{ float: 'left', width: '100%', marginBottom: '25px' }} attached={false}>
        <Form style={{ float: 'left', width: '100%' }}>
          <Form.Field>
            <label>Domain Name</label>
            <input name="domainName" onChange={e => this.inputChange(e)} placeholder="Domain Name" defaultValue={domainName} />
          </Form.Field>
          <Form.Group>
            <Form.Input name="organisationName" onChange={e => this.inputChange(e)} width={16} label="Organisation Name" placeholder="Organisation Name" defaultValue={organisationName} />
          </Form.Group>
          <Form.Group>
            <Form.Input name="street" onChange={e => this.inputChange(e)} width={8} label="Street" placeholder="Street" defaultValue={street} />
            <Form.Input name="town" onChange={e => this.inputChange(e)} width={4} label="City" placeholder="City" defaultValue={town} />
            <Form.Input name="postcode" onChange={e => this.inputChange(e)} width={4} label="Postcode" placeholder="Postcode" defaultValue={postcode} />
          </Form.Group>
          <Form.Group>
            <Form.Input name="state" fluid onChange={e => this.inputChange(e)} width={6} label="State" placeholder="State" defaultValue={state} />
            <Form.Input name="country" fluid onChange={e => this.inputChange(e)} width={10} label="Country" placeholder="Country" defaultValue={country} />
          </Form.Group>
          <Button floated="right" style={{ background: '#f9581f', color: 'white' }} loading={loading} disabled={!domainName || loading} onClick={() => this.submitNewDomain()}>Add</Button>
          <Button floated="right" onClick={() => onCancel()}>Cancel</Button>
        </Form>
      </Tab.Pane>
    );
  }
}

AddDomainModalDomainTab.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default AddDomainModalDomainTab;
