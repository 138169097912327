import styled from 'styled-components';

export const MainContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  .pag-wrapper {
    display:flex;
    justify-content:center;
  }
  .ui.text.loader {
    position: relative;
    top: 25%;
  }
`;