export const getDomainsGraphqlQuery = (paging, sorting, filtering) => {
  const pagingString = `
    {
      ${Object.keys(paging).map(pagingKey => `${pagingKey}: ${paging[pagingKey]}`)}
    }
  `;

  const sortingString = `
    {
      ${Object.keys(sorting).map(sortingKey => `${sortingKey}: ${sorting[sortingKey]}`)}
    }
  `;

  const filteringString = `
    {
      ${Object.keys(filtering).map(filteringKey => `${filteringKey}: "${filtering[filteringKey]}"`)}
    }
  `;

  return `
    {
      domains(
      paging: ${pagingString}
      sorting: ${sortingString}
      filtering: ${filteringString}
      ) {
        edges {
          cursor
          node {
            domain
            organisationName
            address {
              address1
              address2
              locality
              postcode
              state
              country
            }
            assignableActions
            assignableMerchants
            status
            creationDate
            createdBy
          }
        }
      }
    }
  `;
};
