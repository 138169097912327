import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Form, Search, Button, Message } from 'semantic-ui-react';
import APIService2 from '../../services/APIService2';
import { CREATE_OR_UPDATE_MERCHANT_MUTATION, ADD_MERCHANT_MUTATION } from './MerchantsMutations';
import { GRAPHQL } from '../../containers/App/constants';
import { getMerchantsGraphqlQuery } from './getMerchantsQuery';

const uri = GRAPHQL;
const apiService = new APIService2();

export default class ManageMerchantForm extends Component {
  constructor(props) {
    super(props);
    const { merchantData: { merchantId, acquirer, region, processingTimezone, merchantAccount, conduit } = {} } = this.props;
    this.state = {
      merchantId: merchantId || '',
      acquirer: acquirer || '',
      region: region || '',
      processingTimezone: processingTimezone || '',
      merchantAccount: merchantAccount || '',
      conduit: conduit || '',
      errorMessage: '',
      disabledInputs: false,
      preFulfilled: false,
      submittingMerchant: false,
      midSearchOpen: false,
      existingMidSelected: false,
    };
  }

  componentDidMount() {
    this.fetchAllMerchantIds();
  }

  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  fetchAllMerchantIds = async () => {
    const { history } = this.props;
    this.setState({ merchantIds: [] });
    const paging = { first: 1000 };
    const query = getMerchantsGraphqlQuery(paging);
    const accessToken = window.localStorage.getItem('accessToken');
    try {
      const result = await apiService.request({ payload: { query }, accessToken, history, window, uri });
      this.setState({ merchantIds: result.data.merchants.edges });
    } catch (err) {
      // FIXME: Should think about how to deal with this but don't have time atm.
    } finally {
      this.formatMerchantIdsToFitDropdown();
    }
  }

  formatMerchantIdsToFitDropdown = () => {
    const { merchantIds } = this.state; // FIXME: I think this might be dangerous because the caller has just gotten merchantIds and is calling setState just before calling this function. We should prob pass merchantIds as args or set this function as setState() callback in the caller.
    const result = [];
    merchantIds.map(i => (
      result.push({ key: i.cursor, title: i.node.merchantId, data: i.node })
    ));
    this.setState({ merchantIdsFormatted: result });
  }

  handleSearchChange = (_e, { value }) => {
    this.setState({
      merchantId: value,
      merchantAccount: '',
      conduit: '',
      region: '',
      acquirer: '',
      processingTimezone: '',
      disabledInputs: false,
      preFulfilled: false,
      existingMidSelected: false,
      midSearchOpen: true,
    });

    const { merchantIdsFormatted } = this.state;
    const re = new RegExp(_.escapeRegExp(value));
    const isMatch = result => re.test(result.title);
    this.setState({ results: _.filter(merchantIdsFormatted, isMatch) });
  }

  handleMerchantIdSelect = (_e, { result }) => {
    this.setState({
      merchantId: result.data.merchantId,
      merchantAccount: result.data.merchantAccount,
      conduit: result.data.conduit,
      region: result.data.region,
      acquirer: result.data.acquirer,
      processingTimezone: result.data.processingTimezone,
      disabledInputs: true,
      preFulfilled: true,
      midSearchOpen: false,
      existingMidSelected: true,
    });
  }

  submitMerchant = async () => {
    this.setState({ submittingMerchant: true });
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const { history, afterSubmitingNewMerchant, existingMerchant, domainData: { organisationName } } = this.props;
      const { merchantAccount, merchantId, conduit, region, acquirer, processingTimezone, preFulfilled } = this.state;
      const merchantData = {
        merchantId,
        organisationName,
        merchantAccount,
        conduit,
        region,
        acquirer,
        processingTimezone,
      };
      let mutation;
      if (preFulfilled || existingMerchant) {
        mutation = CREATE_OR_UPDATE_MERCHANT_MUTATION;
      } else {
        mutation = ADD_MERCHANT_MUTATION;
      }
      const params = { merchantData };
      await apiService.request({ payload: { mutation, params }, accessToken, history, window, uri });
      afterSubmitingNewMerchant(merchantData);
    } catch (err) {
      this.setState({ errorMessage: err.message, submittingMerchant: false });
    }
  }

  buildForm = () => {
    const { existingMerchant, onCancel, errorMessage: errorMessageProp } = this.props;
    const { results, merchantId, acquirer, region, conduit, merchantAccount, processingTimezone, errorMessage, disabledInputs, submittingMerchant, midSearchOpen, existingMidSelected } = this.state;
    return (
      <>
        {errorMessage && <Message content={errorMessage} error />}
        {errorMessageProp && <Message content={errorMessageProp} error />}
        <Form>
          {existingMerchant ? (
            <Form.Input
              className="input-disabled"
              disabled={disabledInputs}
              name="MerchantId"
              label="Merchant ID"
              placeholder="Merchant ID"
              value={merchantId}
            />
          ) : (
            <>
              <label><strong>Merchant ID</strong></label>
              <Search
                open={midSearchOpen}
                onFocus={() => !existingMidSelected && this.setState({ midSearchOpen: true })}
                onBlur={() => _.debounce(() => this.setState({ midSearchOpen: false })) /* Need to delay closing until onResultSelect has executed. */}
                disabled={existingMerchant}
                className="search-component"
                placeholder="Add a new or search an existing merchantId"
                minCharacters={1}
                showNoResults={false}
                // noResultsMessage="This MerchantId doesn't exist at the moment. We are going to create a new one."
                fluid
                onResultSelect={this.handleMerchantIdSelect}
                onSearchChange={this.handleSearchChange}
                results={results}
                defaultValue={merchantId}
              />
            </>
          )}
          <Form.Input className={disabledInputs ? 'input-disabled' : null} disabled={disabledInputs} name="merchantAccount" onChange={e => this.inputChange(e)} label="Merchant Name" placeholder="Merchant Name" value={merchantAccount} />
          <Form.Input className={disabledInputs ? 'input-disabled' : null} disabled={disabledInputs} name="conduit" onChange={e => this.inputChange(e)} label="Conduit" placeholder="Conduit" value={conduit} />
          <Form.Input className={disabledInputs ? 'input-disabled' : null} disabled={disabledInputs} name="region" onChange={e => this.inputChange(e)} label="Region" placeholder="Region" value={region} />
          <Form.Input className={disabledInputs ? 'input-disabled' : null} disabled={disabledInputs} name="acquirer" onChange={e => this.inputChange(e)} label="Acquirer" placeholder="Acquirer" value={acquirer} />
          <Form.Input className={disabledInputs ? 'input-disabled' : null} disabled={disabledInputs} name="processingTimezone" onChange={e => this.inputChange(e)} label="Processing Timezone" placeholder="Processing Timezone" value={processingTimezone} />
          {existingMerchant ? (
            <>
              <Button
                style={{ margin: '20px 0 20px 5px', background: '#f9581f', color: 'white' }}
                floated="right"
                content="Update"
                loading={submittingMerchant}
                onClick={() => this.submitMerchant()}
              />
              <Button
                floated="right"
                onClick={e => onCancel(e)}
                style={{ margin: '20px 0 20px 5px' }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                disabled={!merchantId}
                loading={submittingMerchant}
                style={{ margin: '20px 0 20px 5px', background: '#f9581f', color: 'white' }}
                floated="right"
                content="Add"
                onClick={() => this.submitMerchant()}
              />
              <Button
                style={{ margin: '20px 0 20px 5px' }}
                floated="right"
                onClick={e => onCancel(e)}
              >
                Cancel
              </Button>
            </>
          )}
        </Form>
      </>
    );
  }

  render() {
    return (
      <>
        {this.buildForm()}
      </>
    );
  }
}

ManageMerchantForm.propTypes = {
  existingMerchant: PropTypes.bool,
  domainData: PropTypes.object,
  merchantData: PropTypes.object,
  onCancel: PropTypes.func,
  afterSubmitingNewMerchant: PropTypes.func,
  errorMessage: PropTypes.string,
};
