import jsonwebtoken from 'jsonwebtoken';
export const EXPIRATION_MARGIN_MS = 5000;

export default class ValidateTokenService {
  refreshTokenIsValid() {
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (!refreshToken) { return false; }
    const decodedToken = jsonwebtoken.decode(refreshToken);
    const expirationDateSecondsFromEpoch = decodedToken.exp;
    const expirationDateMillisecondsFromEpoch = expirationDateSecondsFromEpoch * 1000;
    const expirationDateMSEpochWithMargin = expirationDateMillisecondsFromEpoch - EXPIRATION_MARGIN_MS;
    const expirationDate = new Date(expirationDateMSEpochWithMargin);
    const refreshTokenNotExpired = new Date() < expirationDate;
    return refreshTokenNotExpired;
  }
}
