import styled from 'styled-components';

const DashboardTitle = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  height: 42px;
  h1 {
    font-size: 22px;
    color: black;
    margin-top:0;
    margin-right:auto;
  }
  > div {
    margin-right: auto;
    margin-left: -145px;
  }
`;

export default DashboardTitle;
