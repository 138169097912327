import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { createApolloFetch } from 'apollo-fetch';
import { Wrapper } from './styles';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import { BASE_API_URL } from '../App/constants';

const SUCCESS = 'success';
const ERROR = 'error';

const request = new createApolloFetch({ //eslint-disable-line
  uri: `${BASE_API_URL}/request-reset-password-email`,
});
request.useAfter(async ({ response }, next) => {
  if (!response.parsed) return next();
  if (response.status < 300 && response.status >= 200) {
    response.parsed.status = SUCCESS;
  } else {
    response.parsed.status = ERROR;
  }
  return next();
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      successfulPasswordReset: false,
    };
  }

  submitHandler = data => { //eslint-disable-line
    const { username } = data;
    this.setState({
      isLoading: true,
    });
    request({
      userIdentification: `${username}`,
    }).then((res) => {
      this.setState({
        message: res.message,
        successfulPasswordReset: res.status === SUCCESS,
        isLoading: false,
      });
    }).catch((err) => {
      console.error('err', err);
      this.setState({ isLoading: false, message: 'Failed to reset password', successfulPasswordReset: false });
    });
  }

  render() {
    const { message, isLoading, successfulPasswordReset } = this.state;
    return (
      <Wrapper>
        <Grid textAlign="center" style={{ width: 350 }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 350 }}>
            <img className="logo" src="/assets/till-logo-black.png" alt="logo" />
            <ResetPasswordForm onSubmit={this.submitHandler} isLoading={isLoading} error={message} status={successfulPasswordReset} />
          </Grid.Column>
        </Grid>
      </Wrapper>
    );
  }
}

const mapStateFromProps = state => ({
  isLoading: state.user.isLoading,
});

export default withRouter(connect(mapStateFromProps)(ForgotPassword));
