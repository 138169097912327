import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tab } from './styles';

class Tabs extends Component {
  state = {};

  componentDidMount() {
    const { items } = this.props;
    const currentItem = _.find(items, i => i.isDefault);
    this.setState({ currentItem });
  }

  onItemChange = (itemData) => {
    const { onChange } = this.props;
    this.setState({ currentItem: itemData });
    onChange(itemData);
  };

  buildTabItem = (itemData) => {
    const { currentItem = {} } = this.state;
    const { itemDef = {} } = this.props;
    const className = currentItem.key === itemData.key ? 'active' : '';
    const itemValue = itemData.value || itemData.key;
    if (itemDef.isButton) {
      return (
        <li key={itemData.key} onClick={this.onItemChange.bind(this, itemData)}> { /* eslint-disable-line */ } 
          <span className={className}>{itemValue}</span>
        </li>
      );
    }

    return (
      <li key={itemData.key}>
        { /* eslint-disable-next-line */ }
        <NavLink onClick={this.onItemChange.bind(this, itemData)} id={itemData._id} className={className} to='#/'>
          {itemValue}
        </NavLink>
      </li>
    );
  };

  render() {
    const { items } = this.props;
    if (!items || !items.length) return null;

    return (
      <Tab className="tabs">
        {_.map(items, i => this.buildTabItem(i))}
      </Tab>
    );
  }
}

Tabs.propTypes = {
  items: PropTypes.any,
  onChange: PropTypes.func,
  itemDef: PropTypes.object,
};

export default Tabs;
