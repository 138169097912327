import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Tab } from 'semantic-ui-react';

class UpdateDomainModalDomainTab extends Component {
  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
    this.submitUpdateDomain = this.submitUpdateDomain.bind(this);
    const { domainData: { domain, organisationName, status, address: { address1, locality, state, country, postcode } } } = this.props;
    this.state = {
      domainName: domain,
      organisationName,
      status,
      street: address1,
      city: locality,
      postcode,
      state,
      country,
    };
  }

  inputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  submitUpdateDomain = () => {
    const { onSubmit } = this.props;
    onSubmit(this.state);
  }

  render() {
    const { domainName, organisationName, street, city, postcode, state, country } = this.state;
    const { onCancel, loading } = this.props;
    return (
      <Tab.Pane attached={false} style={{ float: 'left', width: '100%', marginBottom: '25px' }}>
        <Form style={{ float: 'left', width: '100%' }}>
          <Form.Field>
            <Form.Input label="Domain Name" name="domainName" className="input-disabled" placeholder="Domain Name" value={domainName} />
          </Form.Field>
          <Form.Group>
            <Form.Input name="organisationName" onChange={e => this.inputChange(e)} width={16} label="Organisation Name" placeholder="Organisation Name" defaultValue={organisationName} />
            {/* <Form.Select name="status" onChange={e => this.inputChange(e)} width={8} label="Status" placeholder="Status" options={[{ text: 'Active', value: 'active' }, { text: 'Inactive', value: 'inactive' }]} defaultValue={status} /> */}
          </Form.Group>
          <Form.Group>
            <Form.Input name="street" onChange={e => this.inputChange(e)} width={8} label="Street" placeholder="Street" defaultValue={street} />
            <Form.Input name="city" onChange={e => this.inputChange(e)} width={4} label="City" placeholder="City" defaultValue={city} />
            <Form.Input name="postcode" onChange={e => this.inputChange(e)} width={4} label="Postcode" placeholder="Postcode" defaultValue={postcode} />
          </Form.Group>
          <Form.Group>
            <Form.Input name="state" fluid onChange={e => this.inputChange(e)} width={6} label="State" placeholder="State" defaultValue={state} />
            <Form.Input name="country" fluid onChange={e => this.inputChange(e)} width={10} label="Country" placeholder="Country" defaultValue={country} />
          </Form.Group>
          <Button floated="right" loading={loading} style={{ background: '#f9581f', color: 'white' }} onClick={() => this.submitUpdateDomain()}>Update</Button>
          <Button floated="right" onClick={() => onCancel()}>Cancel</Button>
        </Form>
      </Tab.Pane>
    );
  }
}

UpdateDomainModalDomainTab.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  domainData: PropTypes.object,
  loading: PropTypes.bool,
};

export default UpdateDomainModalDomainTab;
