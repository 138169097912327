import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Popup, Icon, Menu } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import ValidateTokenService from '../../services/ValidateTokenService';
import { clearUser } from '../../actions/index';
import { HeaderWrapper, Logo, IconsWrapper } from './styles';

import {
  PERMISSION_VIEW_DOMAINS,
  PERMISSION_VIEW_MERCHANT_IDS,
  PERMISSION_VIEW_TERMINALS,
  USERGUIDE_FILE_URL,
} from '../../containers/App/constants';

const ADMIN_PANEL_ACCESS_PERMISSIONS = [
  PERMISSION_VIEW_DOMAINS,
  PERMISSION_VIEW_MERCHANT_IDS,
  PERMISSION_VIEW_TERMINALS,
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Inbox',
      navigationOpen: false,
    };
    this.logout = this.logout.bind(this);
  }

  logout = () => {
    const { history, clearUser } = this.props; // eslint-disable-line no-shadow
    this.setState({ navigationOpen: false });
    clearUser();
    history.push('/');
  }

  handleNavigation(path) {
    const { history } = this.props;
    this.setState({ navigationOpen: false });
    history.push(path);
  }

  render() {
    const { activeItem, navigationOpen } = this.state;
    const username = window.localStorage.getItem('username');
    const domain = window.localStorage.getItem('domain');
    const validateTokenService = new ValidateTokenService();
    const localStoragePermissions = window.localStorage.getItem('permissions');
    const permissions = localStoragePermissions && localStoragePermissions.split(',');
    const showAdminAccess = _.intersection(ADMIN_PANEL_ACCESS_PERMISSIONS, permissions).length > 0;
    if (!validateTokenService.refreshTokenIsValid()) return <span></span>;
    return (
      <HeaderWrapper>
        <Link to="/dashboard">
          <Logo />
        </Link>
        <IconsWrapper className="icon-wrapper">
          <p>
            Logged in as&nbsp;
            <span className="info">
              {username}
              @
              {domain}
            </span>
          </p>
          <Popup
            open={navigationOpen}
            onOpen={() => this.setState({ navigationOpen: true })}
            onClose={() => this.setState({ navigationOpen: false })}
            trigger={<Button circular icon="user" />}
            content={(
              <Menu vertical>
                {/* <Menu.Item
                  name="Profile"
                  active={activeItem === 'profile'}
                  onClick={() => history.push('/profile')}
                >
                  <Icon name="at" />
                  Profile
                </Menu.Item> */}
                {showAdminAccess
                  ? (
                    <Menu.Item
                      name="Admin"
                      active={activeItem === 'admin'}
                      onClick={() => this.handleNavigation('/admin')}
                    >
                      <Icon name="user secret" />
                      Admin
                    </Menu.Item>
                  )
                  : null}

                <Menu.Item
                  name="Transactions"
                  active={activeItem === 'transactions'}
                  onClick={() => this.handleNavigation('/')}
                >
                  <Icon name="dollar" />
                  Transactions
                </Menu.Item>
                {/* <Dropdown
                  className="link item"
                  icon="file alternate"
                  text="Exports"
                  pointing="right"
                  position="left"
                >
                  <Dropdown.Menu style={{ right: '100%' }}>
                    <Dropdown.Item>
                      <NotifyItem>
                        <span className="notification-message">Summary from <strong>29/08/2019</strong> to <strong>30/08/2019</strong></span>
                        <Button size="mini" animated color="green" className="download" onClick={() => console.log('clicked')}>
                          <Button.Content visible>Download</Button.Content>
                          <Button.Content hidden>
                            <Icon name="download" />
                          </Button.Content>
                        </Button>
                      </NotifyItem>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <NotifyItem>
                        <span className="notification-message">Summary from <strong>12/08/2019</strong> to <strong>34/08/2019</strong></span>
                        <Button size="mini" disabled animated className="download" onClick={() => console.log('clicked 2')}>
                          <Button.Content visible>Expired</Button.Content>
                          <Button.Content hidden>
                            <Icon name="download" />
                          </Button.Content>
                        </Button>
                      </NotifyItem>
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown> */}
                {/* dropdown for exports file, future feature */}
                <Menu.Item
                  name="Documentation"
                  active={activeItem === 'documentation'}
                  onClick={() => { this.setState({ navigationOpen: false }); window.open(USERGUIDE_FILE_URL, '_blank'); }}
                >
                  <Icon name="file alternate outline" />
                  Documentation
                </Menu.Item>
                <Menu.Item
                  name="Customer Support"
                  active={activeItem === 'customer_support'}
                  onClick={() => { this.setState({ navigationOpen: false }); window.open('https://support.tillpayments.com/hc/en-us/requests/new?ticket_form_id=8226167647759&tf_7248384414223=till_reporting_portal', '_blank'); }}
                >
                  <Icon name="question circle" />
                  Customer Support
                </Menu.Item>
                <Menu.Item
                  name="Logout"
                  active={activeItem === 'logout'}
                  onClick={() => this.logout()}
                >
                  <Icon name="log out" />
                  Logout
                </Menu.Item>
              </Menu>
            )}
            on="click"
            position="bottom left"
          />
        </IconsWrapper>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  history: PropTypes.any,
  clearUser: PropTypes.func,
};

const mapStateFromProps = state => ({
  isLoading: state.user.isLoading,
  username: state.user.username,
  domain: state.user.domain,
});

export default withRouter(connect(mapStateFromProps, { clearUser })(Header));
