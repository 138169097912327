export default function graphqlToUiStatus(statusMap, transactionGraphQlStatus, statusFilterValueAtRefresh) {
  const representationsOfInputStatus = Object.values(statusMap).find(status => status.graphQlEnum === transactionGraphQlStatus);

  if (statusFilterValueAtRefresh !== statusMap.settledEu.value) {
    const status = representationsOfInputStatus.columnText ? representationsOfInputStatus.columnText : representationsOfInputStatus.text.toUpperCase();
    return status;
  }
  if (transactionGraphQlStatus !== statusMap.settledEu.graphQlEnum) {
    return statusMap.unknown.text.toUpperCase(); // NOTE: If this happens something is wrong. A query for cleared has returned something that is not cleared.
  }
  return statusMap.settledEu.columnText;
}
