// Import external modules
import _ from 'lodash';
import React from 'react';

// Define module wide constants
const GRAPHQL_STATUS_FIELD = 'status';
const GRAPHQL_STATUS_REFUND = 'REFUND';

const REFUND_LABEL_MAPPING = {
  transactionId: 'Refund Transaction Id',
  transactionDate: 'Refund Transaction Date',
  transactionSequenceNumber: 'Refund Transaction Sequence Number',
  clearingDate: 'Refund Clearing Date',
  settlementDate: 'Refund Settlement Date',
  refundTxSeqNo: 'Original Transaction Sequence Number',
  refundTxNo: 'Original Transaction Id',
};

const GENERAL_MAPPING = {
  refundTxSeqNo: 'Refund Transaction Sequence Number',
  refundTxNo: 'Refund Transaction Id',
};

function getUiFieldName(data, key) {
  const isRefundTranscation = data[GRAPHQL_STATUS_FIELD] === GRAPHQL_STATUS_REFUND;
  const specialFieldNameIfRefund = REFUND_LABEL_MAPPING[key];
  const specialFieldName = GENERAL_MAPPING[key];
  if (isRefundTranscation && specialFieldNameIfRefund) {
    return specialFieldNameIfRefund;
  }

  return specialFieldName || (key).replace(/([A-Z])/g, ' $1').trim();
}

// Main
export default function generateModalContent(data, uiStatus) {
  return _.compact(_.map(_.keys(data), key => data[key] ? (
    <div key={key} className="item">
      {getUiFieldName(data, key)}
      <span>
        {key === GRAPHQL_STATUS_FIELD
          ? uiStatus
          : key === 'amount'
            ? (data[key] / 100).toFixed(2)
            : data[key]}
      </span>
    </div>
  ) : null));
}
