import styled from 'styled-components';

const Modal = styled.div`
  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog {
      background-color: white;
      border-radius: 5px;
      min-width: 500px;
      max-height: 85%;
      overflow-y: auto;
      > button {
        float: right;
        margin: 10px;
      }
      .dialog-title {
        box-sizing: border-box;
        width: 100%;
        height: 80px;
        padding: 0 20px;
        border-bottom: 0.5px solid #c3c3c3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight:bolder;
        font-size:1.3em;
        .dialog-close {
          font-size: 32px;
          color: #c3c3c3;
          cursor: pointer;
          transform: rotate(45deg);
          user-select: none;
          &:hover {
            color: red;
          }
        }
      }
      .dialog-content {
        min-width: 300px;
        /* min-height: 300px; confirm nothing is breaking */
        padding:20px;
        padding-top:0;
        float:left;
        width: 100%;
        .item {
          height: 50px;
          border-bottom:1px solid lightgray;
          display:flex;
          justify-content:space-between;
          align-items:center;
          text-transform:capitalize;
        }
        form.ui.form {
          .inline.field {
            display:flex;
            align-items:center;
            justify-content:space-between;
          }
          .actions {
            margin: 20px;
            margin-right:0;
          }
        }
      }
    }
  } 
`;

export default Modal;
